import React from "react";
import { backendURL } from "../constants/urls";
import GoogleButton from "../components/GoogleButton";
import GoogleButtonWDialoguePreview from "../components/GoogleButtonWDialoguePreview";
import useUser from "../utils/useUser";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';

export default function LoginPage() {
  const { user } = useUser()
  const [searchParams, _] = useSearchParams();
  const narrativeId = searchParams.get('narrativeId');
  const dialogueId = searchParams.get('dialogueId');
  const navigate = useNavigate();
  React.useEffect(() => {  // If user is already logged in, redirect to home
    if (user.email) {
      if (narrativeId && dialogueId) {
        navigate(`/narratives/${narrativeId}/dialogues/${dialogueId}/`);
      } else {
        navigate("/");
      }
    }
  }, []);

  const googleLoginURL = `${backendURL}/google/login/`;
  return (
    <div className="min-h-full bg-gradient-to-r from-red-200 to-orange-200 h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div className="flex justify-center items-center h-screen">
          <div className="card w-96 shadow-xl bg-white text-black">
            <div className="card-body">
              <img src={process.env.PUBLIC_URL + "/logo.svg"} alt="logo" />
                {narrativeId && dialogueId ? (
                  <GoogleButtonWDialoguePreview
                    googleLoginURL={googleLoginURL}
                    narrativeId={narrativeId}
                    dialogueId={dialogueId}
                  />
                ) : (
                  <GoogleButton googleLoginURL={googleLoginURL} />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
