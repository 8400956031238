import React, { useState, useEffect } from "react";
import { ReactMic } from "react-mic";
import { backendURL } from "../constants/urls";

const MAX_RECORDING_SECONDS = 10;
const MAX_RECORDING_SIZE = 200000;

export default function VoiceRecorder({ callback, disabled, style }) {
  const [recording, setRecording] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [openRecorder, setOpenRecorder] = useState(true);
  const resetRecorder = () => {
    setOpenRecorder(false)
    setOpenRecorder(true)
}
  useEffect(() => {
    let timer = null;
    if (recording) {
      timer = setInterval(() => {
        if (seconds + 1 >= MAX_RECORDING_SECONDS) {
            clearInterval(timer);
            setSeconds(0);
            setRecording(false);
            resetRecorder();
        }
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  });

  const startOrStopRecording = () => {
    setRecording(!recording);
  };

  const onData = (recordedBlob) => {
  };

  const onStop = (recordedBlob) => {
    if (recordedBlob.blob.size > MAX_RECORDING_SIZE) {
        // This is a safety check
        // Recordings are limited to 10 seconds and should never be this large
        console.log("Did not upload recording, size too large");
        return;
    }
    var data = new FormData();
    data.append("file", recordedBlob.blob);
    fetch(`${backendURL}/audio`, {
      method: "POST",
      credentials: "include",
      body: data,
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.text);
        }
        return response.json()
  }).then(data => callback(data.transcription, data.id)).catch(err => console.log(err));
  };

  return (
    <div {...style}>
      <button
      disabled={disabled}
        className={recording ? "btn bg-red-400 hover:bg-red-100 rounded-none rounded-r-lg btn-square px-2" : "btn btn-square btn-outline  rounded-none rounded-r-lg border-transparent px-2"}
        onClick={startOrStopRecording}
      >
        {recording ? (
          <i className={"bi bi-mic text-center text-3xl text-white animate-pulse"}></i>
        ) : (
          <i className={"bi bi-mic text-center text-3xl " + (!disabled ? " text-red-400" : "text-base-100")}></i>
        )}
      </button>
      {openRecorder ?
      <ReactMic
        record={recording}
        className="hide"
        onStop={onStop}
        onData={onData}
        strokeColor="#ffffff"
        backgroundColor="#000000"
      /> : <></>}
    </div>
  );
}
