import { useState } from "react";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";
const encodingsAtom = atomWithStorage("stoozi-encodings", {});

function ProcessEncoding({
  learning_language_content, //most common case is to use with objects with learning/native language
  native_language_content,
  encoding,
  encoded_learning_language_content,
  encoded_native_language_content,
  learning = true,
  content, //provide the content and encoded content directly if necessary
  content_encoded,
  as_function = false,
}) {

  const [usedEncodings, setUsedEncodings] = useAtom(encodingsAtom);
  let main = content;
  let main_encoded = content_encoded;
  if (main == null) {
    if (learning) {
      main = learning_language_content;
      main_encoded = encoded_learning_language_content;
    } else {
      main = native_language_content;
      main_encoded = encoded_native_language_content;
    }
  }

  if (!learning) {
    main = native_language_content;
    main_encoded = encoded_native_language_content;
  }
  if (typeof main_encoded === "string") {
    try {
      main_encoded = JSON.parse(main_encoded);
    } catch {
      main_encoded = null;
    }
  }

  main = main || "";
  if (encoding === "Japanese::gana" && main_encoded != null) {
    let [type, hover] = usedEncodings[encoding] || ["romaji", false];
    if (!(encoding in usedEncodings)) {
      setUsedEncodings((c) => {
        const temp = { ...usedEncodings };
        temp[encoding] = ["romaji", false];
        return temp;
      });
    }
    try {
      const inner =
        type !== "orig"
          ? main_encoded.map((o, idx) => {
              const show = o.orig.split("|").join("");
              const tip = o[type].split("|").join("");
              if (show.trim()) {
                if(show===tip){
                  return <span>{show}</span>
                }
                const ret =
                  hover === true ? (
                    <div className="tooltip mr-[10px]" data-tip={tip} key={idx}>
                      {show}
                    </div>
                  ) : (
                    <ruby className="mr-[10px]" key={idx}>
                      {show}
                      <rt>{tip}</rt>
                    </ruby>
                  );

                if (as_function) {
                  return [show, ret];
                }
                return ret;
              }
            })
          : main;
      if (as_function) {
        return inner.filter((e) => e != null);
      }
      return <span>{inner}</span>;
    } catch {
      if (as_function) {
        return main.split("|");
      }
      return <span>{main.split("|").join("")}</span>;
    }
  }
  if (as_function) {
    return main.split("|");
  }
  return <span>{main.split("|").join("")}</span>;
}

const EncodingOptions = ({ encoding, choice, onChange }) => {
  if (encoding === "Japanese::gana") {
    return (
      <>
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text">Pick Furigana Type</span>
          </div>
          <select
            className="select select-bordered"
            onChange={(e) => onChange([e.target.value, choice[1]])}
          >
            <option disabled selected>
              {
                {
                  romaji: "Romaji",
                  hira: "Hiragana",
                  kata: "Katakana",
                  orig: "None",
                }[choice[0]]
              }
            </option>
            <option value="orig">None</option>
            <option value="hira">Hirigana</option>
            <option value="kata">Katakana</option>
            <option value="romaji">Romaji</option>
          </select>
        </label>
        <div className="form-control">
          <label className="cursor-pointer label">
            <span className="label-text">Furigana Hover</span>
            <input
              type="checkbox"
              checked={choice[1]}
              className="checkbox checkbox-info"
              onChange={(e) => onChange([choice[0], !choice[1]])}
            />
          </label>
        </div>
      </>
    );
  }
};

export { ProcessEncoding, encodingsAtom, EncodingOptions };