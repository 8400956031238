import { backendURL } from "../constants/urls";
import useSWR from "swr";

const fetcher = (...args) =>
  fetch(`${backendURL}/settings`, { credentials: "include" }).then((response) =>
    response.json()
  );

export default function useSettings(id) {
  const { data, error, isLoading, mutate } = useSWR(`${backendURL}/settings`, fetcher);
    
  return {
    settings: data,
    isLoading,
    isError: error,
    mutate
  };
}

