import React, { useState } from "react";

const calc_tot_speakers = (defined, interacted, amount, max_speakers) => {
  let curr = amount;
  if (interacted) {
    if (curr < defined) {
      curr = defined;
    }
  } else {
    curr = defined;
  }
  return Math.min(max_speakers, Math.max(2, curr));
};

export default function CreateNarrativePart2({
  values,
  setValues,
  errors,
  touched,
  numSpeakers,
}) {
  const [incrementedSpeakers, setIncrementedSpeakers] = useState(false);
  const max_speakers = Math.max.apply(null, numSpeakers);
  return (
    <>
      <p className="text-2xl text-center py-6">
        How many speakers are in involved in this narrative?
      </p>
      <div className="flex flex-col w-full justify-center items-center p-4">
        <div className="divider">Total Speakers</div>
        <p className="pb-4 text-center text-xs">
          This is the total number of speakers including you that will
          participate.
        </p>
        <div className="flex justify-center items-center">
          <button
            className="btn btn-outline h-full"
            disabled={numSpeakers.length<=1}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIncrementedSpeakers(true);
              setValues({
                ...values,
                total_speakers: calc_tot_speakers(
                  values.speakers.length,
                  true,
                  values.total_speakers - 1,
                  max_speakers
                ),
              });
            }}
          >
            <i className="bi bi-dash-lg text-3xl"></i>
          </button>
          <p className="h-full px-6 text-xl text-center my-auto">
            {calc_tot_speakers(
              values.speakers.length,
              incrementedSpeakers,
              values.total_speakers,
              max_speakers
            )}
          </p>
          <button
            className="btn btn-outline h-full"
            disabled={numSpeakers.length<=1}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIncrementedSpeakers(true);
              setValues({
                ...values,
                total_speakers: calc_tot_speakers(
                  values.speakers.length,
                  true,
                  values.total_speakers + 1,
                  max_speakers
                ),
              });
            }}
          >
            <i className="bi bi-plus-lg text-3xl"></i>
          </button>
        </div>
        {numSpeakers.length<=1&&<p className="py-4 text-center text-xs">Keep Levelling to Unlock More!</p>}
        <p className="py-4 text-center text-xs">
          {calc_tot_speakers(
            values.speakers.length,
            incrementedSpeakers,
            values.total_speakers,
            max_speakers
          ) - values.speakers.length}{" "}
          will be automatically generated.
        </p>
      </div>
    </>
  );
}
