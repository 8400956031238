import { useState, useEffect } from "react";
import { backendURL } from "../constants/urls";
import useUser from "../utils/useUser";
import useLevel from "../utils/useLevel";
import useSubscription from "../utils/useSubscription";
export default function UserCard({ name, title, registeredDate }) {
  const { user, isLoading: userLoading, isError: userError } = useUser();
  // const { level: levelProgress } = useLevel();
  const [trial, setTrial] = useState();
  const { subscription: subscriptionStatus } = useSubscription();
  useEffect(() => {
    fetch(`${backendURL}/trial/`, { credentials: "include" })
      .then((response) => response.json())
      .then((data) => setTrial(data))
      .catch((err) =>
        setTrial({ free_trial_active: false, free_trial_days_left: 0 })
      );
  }, []);
  if (!trial) {
    return <></>;
  }
  return (
    <div className="max-w-2xl mx-4 sm:max-w-sm md:max-w-sm lg:max-w-sm xl:max-w-sm sm:mx-auto md:mx-auto lg:mx-auto xl:mx-auto mt-16 shadow-xl rounded-lg">
      <div className="rounded-t-lg h-32 overflow-hidden"></div>
      <div className="mx-auto w-32 h-32 relative -mt-16 border-4 border-white rounded-full overflow-hidden">
        {user != null ? (
          <img
            className="object-cover object-center h-32"
            src={`${backendURL}/image`}
            alt="Users profile image"
          />
        ) : (
          <span className="loading loading-spinner loading-lg"></span>
        )}
      </div>
      <div className="text-center m-2">
        <h2 className="font-semibold">{name}</h2>
        <p>Playing: {title}</p>
      </div>

      <div className="flex flex-col items-center justify-around py-4">
        <p className="btn bg-gradient-to-r from-pink-400 to-blue-500">Member since {registeredDate}</p>
      </div>
      {subscriptionStatus && !subscriptionStatus.active ? (
          <div className="p-4 mt-1">
            <a href="/subscribe">
              <button
                className={
                  "btn btn-sm btn-primary block mx-auto hover:btn-secondary"
                }
              >
                Subscribe to Basic
              </button>
            </a>
          </div>
      ) : <></>}
      {trial.free_trial_active ? (
        <div className="py-4">
          <a href="/subscribe">
            <button className="py-2 px-4 bg-accent text-accent-content shadow-md no-underline rounded-full text-sm block mx-auto">{`Free Trial: ${trial.free_trial_days_left} Days Left`}</button>
          </a>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
