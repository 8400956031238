import React from "react";
import { Formik, Form, Field } from "formik";
import { backendURL } from "../constants/urls";

export default function SignupPage() {
  const basicLoginURL = `${backendURL}/basic/login/`;
  const userCreateURL = `${backendURL}/basic/user/`;
  const handleCreateUser = async ({ email, password }) => {
    const signupData = new FormData();
    signupData.append("email", email);
    signupData.append("password", password);
    await fetch(userCreateURL, {
      method: "POST",
      body: signupData,
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(response.json().message);
        }
        const loginData = new FormData();
        loginData.append("username", email);
        loginData.append("password", password);
        await fetch(basicLoginURL, {
          method: "POST",
          body: loginData,
          credentials: "include",
        }).catch((error) => {
          console.log(
            "Ran into an error while attempting to login using basic auth"
          );
        });
      })
      .catch(() => {
        console.log("Ran into an error while attempting to create user");
      });
  };
  return (
    <div className="min-h-full bg-gradient-to-r from-red-200 to-orange-200 h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div className="flex justify-center items-center h-screen">
          <div className="card w-96 bg-white shadow-xl">
            <div className="card-body">
              <img src={process.env.PUBLIC_URL + "/logo.svg"} alt="logo" />
              <Formik
                className="mt-8 space-y-6"
                initialValues={{ email: "", password: "" }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = "Required";
                  }
                  if (!values.password) {
                    errors.password = "Required";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    handleCreateUser(values);
                    setSubmitting(false);
                  }, 400);
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">Email</span>
                      </label>
                      <Field
                        type="text"
                        name="email"
                        className="input input-bordered text-white"
                      />
                    </div>
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">Password</span>
                      </label>
                      <Field
                        type="password"
                        name="password"
                        className="input input-bordered text-white"
                      />
                    </div>
                    <div className="form-control mt-6">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary"
                      >
                        Sign Up
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="divider">OR</div>
              <form action={"/login"}>
                <div className="form-control">
                  <button type="submit" className="btn btn-outline btn-accent">
                    Login with Username & Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
