import React, { useEffect } from "react";
import LoggedInHeader from "../components/LoggedInHeader";
import { Player } from "@lottiefiles/react-lottie-player";
import checkoutCheckmarkAnimation from "../lottie/checkoutCheckmarkAnimation";
import checkoutConfettiAnimation from "../lottie/checkoutConfettiAnimation";

export default function FeedbackSubmissionSuccess() {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = "/";
    }, 2500);
  }, []);
  return (
    <>
      <LoggedInHeader />

      <div className="flex justify-center items-center">
        <div className="relative z-0">
          <div>
            <h1 className="bold text-6xl">Feedback Submitted!</h1>
          </div>
          <Player
            src={checkoutConfettiAnimation}
            style={{ height: "500px" }}
            autoplay
            loop={false}
          />
          <div className="absolute inset-0 flex justify-center items-center z-10">
            <Player
              src={checkoutCheckmarkAnimation}
              style={{ height: "150px" }}
              autoplay
              loop={false}
            />
          </div>
        </div>
      </div>
    </>
  );
}
