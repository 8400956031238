import React, { useState } from "react";
import { Field } from "formik";

export default function CreateNarrativePart4({
  values,
  setValues,
  errors,
  touched,
  difficulties,
}) {
  const [incrementedSpeakers, setIncrementedSpeakers] = useState(false);
  return (
    <>
      <p className="text-2xl text-center py-6">
        How proficient are you in {values.learning_language}?
      </p>

      <div className="flex">
        <div className="mx-auto">
          <Field
            as="select"
            required={true}
            name="difficulty"
            className="p-2 mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="Novice">Novice</option>
            <option
              disabled={!difficulties.includes("Beginner")}
              value="Beginner"
            >
              Beginner
            </option>
            <option
              disabled={!difficulties.includes("Intermediate")}
              value="Intermediate"
            >
              Intermediate
            </option>
            <option
              disabled={!difficulties.includes("Advanced")}
              value="Advanced"
            >
              Advanced
            </option>
            <option disabled={!difficulties.includes("Expert")} value="Expert">
              Expert
            </option>
            <option disabled={!difficulties.includes("Master")} value="Master">
              Master
            </option>
          </Field>
        </div>
      </div>
      {!difficulties.includes("Master") && (
        <p className="text-xs text-center py-6">
          Keep growing your level to unlock higher levels!
        </p>
      )}
    </>
  );
}
