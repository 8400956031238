import React from "react";
import { Field, FieldArray } from "formik";
const MAX_TOPICS = 10;
export default function CreateNarrativePart1({ values, errors, touched }) {
  return (
    <>
      <p className="text-2xl text-center py-6">
        What would you like this narrative to be about?
      </p>
      <p className="text-center p-4">
        Add one or more short descriptions of vocab, phrases, or topics you'd
        like this narrative to include.
      </p>
      <FieldArray
        name="vocab_phrases_and_topics"
        render={(arrayHelpers) => (
          <div className="justify-center items-center flex flex-col">
            {values.vocab_phrases_and_topics.map(
              (vocab_phrase_or_topic, index) => (
                <div key={index} className="mb-4 w-full" id="speakers-radio-group">
                  <div role="group" aria-labelledby="speakers-radio-group" className="flex w-full">
                    <Field
                      name={`vocab_phrases_and_topics.${index}.value`}
                      type="text"
                      required={true}
                      placeholder="Vocab, Phrase, or Topic"
                      className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    />
                    <button
                      type="button"
                      
                      onClick={() => arrayHelpers.remove(index)}
                      className="btn btn-error btn-square text-lg btn-sm m-2 "
                    >
                      ✕
                    </button>
                  </div>
                </div>
              )
            )}
            <span className="text-xs m-4">{values.vocab_phrases_and_topics.length}/{MAX_TOPICS}</span>
            {values.vocab_phrases_and_topics.length < MAX_TOPICS && (<>
              <button
                type="button"
                onClick={() => {
                  arrayHelpers.push({
                    value: "",
                  });
                }}
                className="btn btn-success btn-square  btn-md text-3xl mb-4"
              >
                +
              </button></>
            )}
          </div>
        )}
      />
    </>
  );
}
