import React, { useEffect, useState } from "react";
import LoggedInHeader from "../components/LoggedInHeader";
import { Player } from "@lottiefiles/react-lottie-player";
import checkoutCheckmarkAnimation from "../lottie/checkoutCheckmarkAnimation";
import checkoutConfettiAnimation from "../lottie/checkoutConfettiAnimation";
import { backendURL } from "../constants/urls";
import { useParams } from "react-router-dom";

export default function CheckoutSuccessPage() {
  const [showInfo, setShowInfo] = useState(false);
  const [checkoutEventID, setCheckoutEventID] = useState("");
  const { stripeCheckoutID } = useParams();
  useEffect(() => {
    fetch(`${backendURL}/purchase/success/${stripeCheckoutID}`, { credentials: "include", method: "POST" })
      .then((response) => response.json())
      .then((data) => {
        setCheckoutEventID(data.checkout_event_id);
      })
      .catch(err => console.log(err));
    setTimeout(() => {
      setShowInfo(true);
    }, 2500);
  }, []);
  return (
    <>
      <LoggedInHeader />
      {showInfo ? (
        <div className="flex justify-center items-center">
          <div className="relative z-0">
            <div className="px-6">
              <h1 className="bold text-3xl">
                <i className="bi bi-check text-success text-3xl"></i>
                {`Payment confirmation: `}<span className="badge badge-3xl badge-success text- align-middle">{checkoutEventID}</span>
              </h1>
            </div>
            <p className="p-5">
              <a href="/">
              <button className="btn btn-primary btn-lg">
                Home -
                <span className="text-2xl">Go to your Stoozi dashboard</span>
              </button>
              </a>
            </p>
            <p className="p-5">
            <a href="/narratives">
              <button className="btn btn-primary btn-lg">
                Narratives -{" "}
                <span className="text-2xl">
                  View your narratives or create a new one
                </span>
              </button>
              </a>
            </p>
            <p className="p-5">
              <a href="/dialogues">
              <button className="btn btn-primary btn-lg">
                Dialogues -<span className="text-2xl">View your dialogues</span>
              </button>
              </a>
            </p>
            <p className="p-5">
              <a href="/flashcards">
              <button className="btn btn-primary btn-lg">
                Flashcards
                <span className="text-2xl">
                  Study flashcards created from your existing narratives and
                  dialogues
                </span>
              </button>
              </a>
            </p>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <div className="relative z-0">
            <div>
              <h1 className="bold text-6xl">Checkout successful!</h1>
            </div>
            <Player
              src={checkoutConfettiAnimation}
              style={{ height: "500px" }}
              autoplay
              loop={false}
            />
            <div className="absolute inset-0 flex justify-center items-center z-10">
            <Player
              src={checkoutCheckmarkAnimation}
              style={{ height: "150px" }}
              autoplay
              loop={false}
            />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
