import { useState, useEffect, useRef } from "react";
import { SPEAKER_BG_COLORS, SPEAKER_TEXT_COLORS } from "./Speakers";
import Vocab from "./Vocab";
import {
  getUTCDate,
  DateTimeConverter,
  getTimeDifference,
} from "../utils/datetimeHelpers";
import { ProcessEncoding } from "../utils/learningLanguageHelpers";
import { backendURL } from "../constants/urls";
import shuffleArray from "../utils/shuffle";
import { useLocalStorage } from "react-use";
import { LogoBubbles } from "./Logo";
const shuffleWithoutDuplicates = (array, seed) =>
  shuffleArray(array, seed, true);

const stoozi_at = /@stoozi/i;

const MessageInfo = ({ msg, playing, audioControls, isRecommendation }) => {
  let words = [];
  if (msg.vocab != null && msg.vocab.length > 0) {
    words = msg.vocab.filter((v) => v.type === "word");
  }
  return (
    <div className="card w-10/12 mx-auto my-4 bg-base-100 shadow-xl border">
      <div className="card-body">
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-row font-semibold items-center">
            {!isRecommendation && (
              <button
                className="text-center my-auto m-2"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  playing === "translation_audio_id"
                    ? audioControls.pause()
                    : audioControls.playTranslation();
                }}
              >
                {playing === "translation_audio_id" ? (
                  <i className="bi bi-pause-fill text-3xl my-auto"></i>
                ) : (
                  <i className="bi bi-play-fill text-3xl my-auto"></i>
                )}
              </button>
            )}
            <ProcessEncoding {...msg.content} learning={false} />

            {msg.content.explanation && (
              <div
                className="tooltip tooltip-left"
                data-tip={msg.content.explanation}
              >
                <i className="bi bi-info-circle-fill text-xl mx-2"></i>
              </div>
            )}
          </div>
          {msg.student_content && (
            <div className="flex flex-row items-center">
              You Said:
              <p className="font-light text-sm px-1">{msg.student_content}</p>
              <button
                className="text-center my-auto m-2"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  playing === "user_audio_id"
                    ? audioControls.pause()
                    : audioControls.playUser();
                }}
              >
                {playing === "user_audio_id" ? (
                  <i className="bi bi-pause-fill text-3xl my-auto"></i>
                ) : (
                  <i className="bi bi-play-fill text-3xl my-auto"></i>
                )}
              </button>
            </div>
          )}
        </div>
        {words.length > 0 && (
          <div className="flex gap-1 items-center justify-center flex-wrap">
            <span className="text-lg font-medium mr-4 ">Words:</span>
            {words.map((v) => {
              return (
                <div
                  key={`${v.learning_language_content}`}
                  className="flex flex-col items-center border border-transparent hover:border-info p-1 rounded-lg"
                >
                  <ProcessEncoding {...v}/>
                  <span className="text-xs">{v.native_language_content}</span>
                </div>
              );
            })}
          </div>
        )}
        {msg.vocab != null && msg.vocab.length > 0 && (
          <>
            <div className="collapse collapse-plus">
              <input type="checkbox" />
              <div className="collapse-title text-lg font-medium">Phrases and Grammar</div>
              <div className="collapse-content">
                {msg.vocab.filter(v=>['phrase', 'grammar'].includes(v.type)).map((v) => {
                  return (
                    <Vocab key={`${v.learning_language_content}`} vocab={v} />
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default function ChatMessage({
  index,
  msg,
  narrative,
  getSpeaker,
  getAvatar,
  getColor,
  isStudent,
  msgOnClick,
  audioControls,
  playing,
  lastMessageRef,
  messageBuilder,
  showMessageText,
}) {
  const [open_, setOpen] = useState(false);
  const [avatarPlayed, setAvatarPlayed] = useLocalStorage(
    "stoozi-message-avatar-played",
    false
  );
  const [speakTooltip, setSpeakTooltip] = useState(!avatarPlayed && index == 1);

  // if the message is in progress then typing is true
  let typing = false;
  let message = "";
  // value normalizes over whether we are on message chunks or a whole message
  let msgRepresentative = null;

  let stoozi_question = "";
  // Interpret the message to fill in the above defaults with their true values
  if (msg instanceof Map) {
    // Handle message chunks
    // first chunk will serve to represent the entired map
    // we will get the necessary information to render the message in progress
    msgRepresentative = msg.values().next().value;
    if (
      msgRepresentative.message_kind.includes("stoozi") &&
      msgRepresentative.content
    ) {
      stoozi_question = msgRepresentative.content.learning_language_content;
      message = [...msg.values()]
        .slice(1)
        .map((chunk) => chunk.content)
        .join("");
    } else {
      message = [...msg.values()].map((chunk) => chunk.content).join("");
    }
    typing = true;
  } else {
    message = msg.content && msg.content.learning_language_content;
    msgRepresentative = msg;
  }

  let speaker_name = msgRepresentative.speaker_name;

  // if the user sent the message this will be their original content in case of audit
  let studentContent = msgRepresentative.student_content;
  stoozi_question = stoozi_question || studentContent;

  const recommendation =
    msgRepresentative.message_kind === "student_recommendation";
  // own messages are message end otherwise message start (right vs left)
  let isOwnMessage = false;

  if (msgRepresentative.message_kind === "event") {
    speaker_name = "event";
  }

  if (
    recommendation ||
    msgRepresentative.message_kind === "student_audit" ||
    isStudent(speaker_name)
  ) {
    isOwnMessage = true;
  }
  typing =
    typing ||
    (msgRepresentative.direct_student_message === true &&
      msgRepresentative.student_chose_recommendation === false);
  const genId = msgRepresentative.gen_id;

  if (msgRepresentative.message_kind === "plan") {
    return null;
  }

  const speaker = getSpeaker(speaker_name);
  const speaker_name_translation = speaker.name.native_language_content;
  // what color the bubble will be
  const color = getColor(speaker_name);

  // time of the message's creation
  const time = msgRepresentative.created_at;

  const message_is_stoozi = msgRepresentative.message_kind.includes("stoozi");

  const msg_has_info =
    message_is_stoozi ||
    (!typing &&
      msgRepresentative.content &&
      (msgRepresentative.content.native_language_content != null ||
        msgRepresentative.content.explanation != null ||
        msgRepresentative.student_content != null ||
        (Array.isArray(msgRepresentative.vocab) &&
          msgRepresentative.vocab.length > 0)));

  const open = message_is_stoozi ? !open_ : open_;

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (msg_has_info) {
      setOpen((o) => !o);
    }
  };

  const style = msg_has_info ? { cursor: "pointer" } : {};

  const InfoButton = msg_has_info ? (
    <button
      className="btn bg-transparent border-transparent w-[3rem] md:w-[5rem] h-full drop-shadow-none"
      onClick={onClick}
    >
      {open ? (
        <i className="bi bi-caret-up-fill text-2xl"></i>
      ) : (
        <i className="bi bi-caret-down-fill text-2xl"></i>
      )}
    </button>
  ) : null;

  let speaker_color = color;
  if (recommendation) {
    speaker_color = "studentOption";
  } else if (isOwnMessage) {
    speaker_color = "student";
  } else if (color in SPEAKER_BG_COLORS) {
    speaker_color = color;
  } else {
    speaker_color = "oops";
  }

  let clean_message = message;
  if (recommendation) {
    clean_message = message.split("|").join("").trim();
    if (messageBuilder) {
      message = shuffleWithoutDuplicates(
        (message + " ").split("|"),
        message.length || 1
      ).map((text, idx) => {
        if (text.trim()) {
          return (
            <button
              className="btn btn-outline btn-warning btn-xs md:btn-sm m-[2px]"
              key={`${text}${idx}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                msgOnClick(text, true);
              }}
            >
              {text.trim()}
            </button>
          );
        }
      });
    } else {
      message = clean_message;
    }
  }

  let processed = message;

  if (!typing && typeof message === "string") {
    processed = (
      <ProcessEncoding
        {...msgRepresentative.content}
        learning_language_content={message}
      />
    );
  }

  const avatar = getAvatar(recommendation ? clean_message : speaker_name);
  const messageBGColor = SPEAKER_BG_COLORS[speaker_color];
  const messageTextColor = SPEAKER_TEXT_COLORS[speaker_color];

  const playAudio = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (playing === "audio_id") {
      audioControls.pause();
    } else {
      audioControls.playAudio();
      if (speakTooltip) {
        setAvatarPlayed(true);
        setSpeakTooltip(false);
      }
    }
  };

  if (speaker_name === "event") {
    return (
      <>
        <div
          onClick={onClick}
          style={style}
          role="alert"
          className="text-center flex flex-row justify-center items-center alert shadow-lg mb-8 mt-2"
          id={genId}
          ref={lastMessageRef}
        >
          <button onClick={playAudio} className="btn">
            {playing === "audio_id" ? (
              <i className="bi bi-pause-fill text-5xl"></i>
            ) : (
              <i className="bi bi-play-fill text-5xl"></i>
            )}
          </button>
          {showMessageText ? (
            processed
          ) : (
            <span className="italic">Click the Play icon to listen</span>
          )}
          {InfoButton ||
            (typing && (
              <span className="loading loading-dots loading-xs"></span>
            ))}
        </div>
        {open && (
          <MessageInfo
            msg={msgRepresentative}
            audioControls={audioControls}
            playing={playing}
            narrative={narrative}
          />
        )}
      </>
    );
  }

  if (
    stoozi_at.test(processed) ||
    msgRepresentative.message_kind.includes("stoozi")
  ) {
    return (
      <>
        <div
          role="alert"
          className="text-center flex flex-row justify-center items-center alert shadow-lg mb-8 mt-2 bg-info text-accent-content"
          id={genId}
          ref={lastMessageRef}
          style={style}
          onClick={onClick}
        >
          <i className="bi bi-at text-4xl"></i>
          {stoozi_question}
          {InfoButton}
        </div>
        {open && (
          <div className="card w-10/12 mx-auto my-4 bg-base-100 shadow-xl border">
            <div className="card-body flex flex-row">
              {processed}
              {typing && (
                <span className="loading loading-dots loading-xs"></span>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
  return (
    <>
      <div
        className={`relative mb-6 chat ${
          isOwnMessage ? "chat-end" : "chat-start"
        }`}
        id={genId}
        ref={lastMessageRef}
      >
        {" "}
        {!typing && speakTooltip && (
          <div className="absolute w-14 md:w-20 bg-info mt-16 md:mt-24 text-xs rounded-xl text-center">
            Click Me
          </div>
        )}
        <div
          className={
            "relative chat-image avatar w-14 md:w-20 rounded-full overflow-hidden " +
            (!recommendation && playing ? "animate-bounce" : "")
          }
        >
          {avatar}

          {!recommendation && (
            <>
              <button
                onClick={playAudio}
                className="absolute top-0 left-0 -translate-y-1/3 -translate-x-1/3 md:-translate-y-[32%] md:-translate-x-1/3 flex justify-center items-center w-[20rem] h-[20rem] rounded opacity-0 bg-opacity-80 bg-neutral hover:opacity-100 transition-opacity duration-300"
              >
                {playing === "audio_id" ? (
                  <i className="bi bi-pause-fill text-5xl -translate-y-1/3 -translate-x-1/2 md:-translate-y-1/4 md:-translate-x-1/4 text-white"></i>
                ) : (
                  <i className="bi bi-play-fill text-5xl -translate-y-1/3 -translate-x-1/2 md:-translate-y-1/4 md:-translate-x-1/4 text-white"></i>
                )}
              </button>
            </>
          )}
        </div>
        <div className="chat-header">
          {recommendation ? null : (
            <div className="tooltip" data-tip={speaker_name_translation}>
              <span>{speaker_name + (isOwnMessage ? " (You)" : "")}</span>
            </div>
          )}
        </div>
        <div
          className={`flex flex-row w-full h-full ${
            isOwnMessage ? "justify-end" : "justify-start"
          }`}
        >
          {isOwnMessage && InfoButton}

          <div
            onClick={(e) => {
              if (!messageBuilder && msgOnClick != null) {
                msgOnClick(message, false);
              } else {
                onClick(e);
              }
            }}
            style={style}
            className={`chat-bubble h-full flex flex-row items-center ${messageBGColor} ${messageTextColor}`}
          >
            {!showMessageText ? (
              <span className="italic">Click the avatar to listen</span>
            ) : (
              <span className="px-2">
                {studentContent && studentContent.trim() !== message.trim() ? (
                  <>
                    <s>{studentContent}</s>
                    <br />
                  </>
                ) : (
                  <></>
                )}
                {processed}
              </span>
            )}
            {recommendation && (
              <>
                <button
                  onClick={playAudio}
                  className="flex justify-center items-center"
                >
                  {playing === "audio_id" ? (
                    <i className="bi bi-pause-fill text-5xl text-white"></i>
                  ) : (
                    <i className="bi bi-play-fill text-5xl text-white"></i>
                  )}
                </button>
              </>
            )}
          </div>
          {!isOwnMessage && InfoButton}
        </div>
        <div className="chat-footer">
          {typing && <span className="loading loading-dots loading-xs"></span>}
          {time != null && (
            <DateTimeConverter utcDateTime={time}></DateTimeConverter>
          )}
        </div>
      </div>
      {open && (
        <MessageInfo
          msg={msgRepresentative}
          audioControls={audioControls}
          playing={playing}
          narrative={narrative}
          isRecommendation={recommendation}
        />
      )}
    </>
  );
}
