import React from "react";

export const FormProgressBar = ({ step }) => {
  switch (step) {
    case 1:
      return (
        <progress
          className="progress progress-primary w-56"
          value={0}
          max="100"
        ></progress>
      );
    case 2:
      return (
        <progress
          className="progress progress-primary w-56"
          value={20}
          max="100"
        ></progress>
      );
    case 3:
      return (
        <progress
          className="progress progress-primary w-56"
          value={40}
          max="100"
        ></progress>
      );
    case 4:
      return (
        <progress
          className="progress progress-primary w-56"
          value={60}
          max="100"
        ></progress>
      );
    case 5:
      return (
        <progress
          className="progress progress-primary w-56"
          value={90}
          max="100"
        ></progress>
      );
    default:
      return (
        <progress
          className="progress progress-primary w-56"
          value={0}
          max="100"
        ></progress>
      );
  }
};
