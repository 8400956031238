import React from "react";
import LoggedInHeader from "../components/LoggedInHeader";
import CreateNarrativeMultistepForm from "../components/CreateNarrativeMultistepForm";

export default function CreateNarrativePage() {
  return (
    <>
      <LoggedInHeader />
      <CreateNarrativeMultistepForm />
    </>
  );
}
