import React, { useState } from "react";
import { Field } from "formik";

export default function CreateNarrativePart4({
  values,
  setValues,
  errors,
  touched,
  lengths
}) {
  const [incrementedSpeakers, setIncrementedSpeakers] = useState(false);
  return (
    <>
      {" "}
      <p className="text-2xl text-center py-6">
        How long should this narrative be?
      </p>
      <div className="mb-6 flex">
        <div className="mx-auto">
          <div className="flex">
            <Field
              as="select"
              required={true}
              name="length"
              className="p-2 mt-1 mx-auto rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="short">Short</option>
              <option disabled={!lengths.includes('medium')} value="medium">Medium</option>
              <option disabled={!lengths.includes('long')} value="long">Long</option>
            </Field>
          </div>
          <p className="pt-[.5rem] text-xs">
            You will write around{" "}
            {values.length === "short"
              ? "3-4"
              : values.length === "medium"
              ? "5-10"
              : "10-20"}{" "}
            messages.
          </p>
        </div>
      </div>{!lengths.includes("long") && ( <p className="text-xs text-center py-6">
        Keep growing your level to unlock longer narratives!
      </p>
    )}
    </>
  );
}
