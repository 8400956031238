import React, { useState, useEffect } from "react";
import RotatableCard from "./rotatable_card";

const overlayStyles = {
  position: "absolute",
  inset: "0",
  backgroundColor: "transparent",
  pointerEvents: "none",
};

const OffsetCard = ({
  disable,
  content,
  height,
  width,
  offsetX,
  offsetY,
  zIndex,
  scalePower,
}) => {
  const scale = Math.pow(0.99, scalePower);
  const cardStyle = {
    // height: `${height * scale}rem`,
    // width: `${width * scale*.5}rem`,
    transform: `scale(${scale})`,
    position: "relative",
    top: `${offsetY * height}rem`,
    left: `${offsetX * width}rem`,
    zIndex: zIndex,
  };

  return (
    <div style={disable ? overlayStyles : {}}>
      <div style={cardStyle}>{content}</div>
    </div>
  );
};

export const Deck = (props) => {
  const offsetStepX = props.offsetStepX || 0.01; // Horizontal offset increment between cards
  const offsetStepY = props.offsetStepY || -0.01; // Vertical offset increment between cards
  const height = props.height || 30;
  const width = props.width || 45;
  return (
    <div
      className="relative"
      style={{
        transform: `translateX(${
          -props.cards.length * offsetStepX * width
        }rem) translateY(${-props.cards.length * offsetStepY * height}rem)`,
      }}
    >
      {Array.from({ length: props.cards.length }, (_, index) => (
        <OffsetCard
          disable={index < props.cards.length - 1}
          content={props.cards.at(index)}
          height={height}
          width={width}
          key={index}
          offsetX={index * offsetStepX}
          offsetY={index * offsetStepY}
          scalePower={props.cards.length - index}
          zIndex={3 * index}
        />
      ))}
    </div>
  );
};
