const loginFields = [
  {
    labelText: "Email address",
    labelFor: "email-address",
    id: "email-address",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Password",
  },
];

const signupFields = [
  {
    labelText: "Email address",
    labelFor: "email-address",
    id: "email-address",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Password",
  },
  {
    labelText: "Confirm Password",
    labelFor: "confirm-password",
    id: "confirm-password",
    name: "confirm-password",
    type: "password",
    autoComplete: "confirm-password",
    isRequired: true,
    placeholder: "Confirm Password",
  },
];

const LANGUAGE = {
  Spanish:
    "The Spanish language, spoken widely across Spain, Latin America and other global regions.",
  French:
    "The French language, spoken widely in France, Canada and other regions across the world.",
  German:
    "The German language, spoken widely in Germany, Austria, Switzerland and other countries.",
  Japanese:
    "The Japanese language, spoken widely in Japan and by Japanese emigrants worldwide.",
  English:
    "The English language, spoken widely in many countries and serves as a global lingua franca.",
  Italian:
    "The Italian language, spoken widely in Italy and by Italian communities worldwide.",
  Portuguese:
    "The Portuguese language, spoken widely in Portugal, Brazil, and other countries.",
  Greek:
    "The Greek language, spoken widely in Greece and Cyprus, and by Greek communities worldwide.",
  Dutch:
    "The Dutch language, spoken widely in the Netherlands, Belgium, Suriname and other regions.",
  Korean:
    "The Korean language, spoken widely in South Korea, North Korea and by Korean communities worldwide.",
  Chinese:
    "The Chinese language, spoken widely in China, Taiwan, Hong Kong, Macau and by Chinese communities worldwide.",
  Russian:
    "The Russian language, spoken widely in Russia, Belarus, Kazakhstan and other countries.",
};

const DIFFICULTY = {
  Novice: `Can understand and use familiar everyday expressions and very basic phrases aimed at the satisfaction of needs of a concrete type.
  Can introduce themselves and others and can ask and answer questions about personal details such as where they live, people they know and things they have.
  Can interact in a simple way provided the other person talks slowly and clearly and is prepared to help.`,
  Beginner: `Can understand sentences and frequently used expressions related to areas of most immediate relevance (e.g. very basic personal and family information, shopping, local geography, employment).
  Can communicate in simple and routine tasks requiring a simple and direct exchange of information on familiar and routine matters.
  Can describe in simple terms aspects of their background, immediate environment and matters in areas of immediate need.`,
  Intermediate: `Can understand the main points of clear standard input on familiar matters regularly encountered in work, school, leisure, etc.
  Can deal with most situations likely to arise while travelling in an area where the language is spoken.
  Can produce simple connected text on topics that are familiar or of personal interest.
  Can describe experiences and events, dreams, hopes and ambitions and briefly give reasons and explanations for opinions and plans.`,
  Advanced: `Can understand the main ideas of complex text on both concrete and abstract topics, including technical discussions in their field of specialisation.
  Can interact with a degree of fluency and spontaneity that makes regular interaction with native speakers quite possible without strain for either party.
  Can produce clear, detailed text on a wide range of subjects and explain a viewpoint on a topical issue giving the advantages and disadvantages of various options.`,
  Expert: `Can understand a wide range of demanding, longer clauses and recognise implicit meaning.
  Can express ideas fluently and spontaneously without much obvious searching for expressions.
  Can use language flexibly and effectively for social, academic and professional purposes.
  Can produce clear, well-structured, detailed text on complex subjects, showing controlled use of organisational patterns, connectors and cohesive devices.`,
  Master: `Can understand with ease virtually everything heard or read.
  Can summarise information from different spoken and written sources, reconstructing arguments and accounts in a coherent presentation.
  Can express themselves spontaneously, very fluently and precisely, differentiating finer shades of meaning even in the most complex situations.`,
};

const languageNames = Object.keys(LANGUAGE);
const difficultyNames = Object.keys(DIFFICULTY);
const uiThemeNames = [
  "light",
  "dark",
  "cupcake",
  "bumblebee",
  "emerald",
  "corporate",
  "synthwave",
  "retro",
  "cyberpunk",
  "valentine",
  "halloween",
  "garden",
  "forest",
  "aqua",
  "lofi",
  "pastel",
  "fantasy",
  "wireframe",
  "black",
  "luxury",
  "dracula",
  "cmyk",
  "autumn",
  "business",
  "acid",
  "lemonade",
  "night",
  "coffee",
  "winter",
  "dim",
  "nord",
  "sunset",
];

export {
  loginFields,
  signupFields,
  languageNames,
  difficultyNames,
  uiThemeNames,
  LANGUAGE,
};
