import React from "react";
import LoggedInHeader from "../components/LoggedInHeader";
import { useState, useEffect } from "react";
import { backendURL } from "../constants/urls";
import useSWR from "swr";
import { TopDialogues } from "../components/TopDialogues";
import Logo from "../components/Logo";
import { LANGUAGE } from "../constants/formFields";

export default function LibrariesPage() {
  const [allData, setAllData] = useState([]);
  const [page, setPage] = useState(0);
  const [exhausted, setExhausted] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const fetchURL =
    !exhausted && (shouldFetch || page === 0)
      ? `${backendURL}/dialogues/library/${page}?language_filter=${selectedLanguage}`
      : null;

  const { data, isLoading } = useSWR(fetchURL, (url) =>
    fetch(url, {
      credentials: "include",
    }).then((response) => response.json())
  );

  useEffect(() => {
    if (data == null) {
      return;
    }
    if (data.length < 10) {
      setExhausted(true);
    } else {
      setPage((p) => p + 1);
    }
    setAllData((d) => [...d, ...data]);
    setShouldFetch(false);
  }, [data]);

  const handleSelectLanguage = (event) => {
    setSelectedLanguage(event.target.value);
    setPage(0);
    setAllData([]);
    setExhausted(false);
    setShouldFetch(true);
  };

  function loadMore() {
    setShouldFetch(true);
  }

  return (
    <>
      <LoggedInHeader />
      <h1 className="text-center text-4xl m-8">
        The{" "}
        <div className="inline-block align-middle w-40 p-3 ">
          <Logo />
        </div>{" "}
        Library
      </h1>
      <div className="text-center pb-6 mb-4">
        <select
          id="language-select"
          value={selectedLanguage}
          onChange={handleSelectLanguage}
          className="w-60 p-2.5 bg-neutral text-neutral-content rounded-full"
        >
          <option value="">Filter by Language</option>
          {Object.entries(LANGUAGE).map(([key, value]) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </select>
      </div>
      <TopDialogues
        data={allData}
        loading={isLoading}
        loadMore={loadMore}
        exhausted={exhausted}
      />
    </>
  );
}
