import React from "react";
import { Field, FieldArray } from "formik";

export default function CreateNarrativePart3({
  values,
  setValues,
  errors,
  touched,
}) {
  return (
    <>
      <p className="text-2xl text-center py-6">
        Any details you would like to add about these {values.total_speakers}{" "}
        speakers?
      </p>
      <p className="pb-4 text-center">
        Add and edit specific speakers, select which you'd like to play, or let
        Stoozi design those you don't!
      </p>

      <FieldArray
        name="speakers"
        render={(arrayHelpers) => (
          <div className="text-center">
            {values.speakers.map((speaker, index) => (
              <div
                key={index}
                className="mb-4 flex w-full justify-center items-center"
                id="speakers-radio-group"
              >
                <div
                  role="group"
                  aria-labelledby="speakers-radio-group"
                  className="w-full"
                >
                  <div className="flex">
                    <Field
                      name={`speakers.${index}.name`}
                      type="text"
                      required={true}
                      placeholder="Name"
                      className="p-2 mt-1 block w-1/2 rounded-md border-gray-300 shadow-sm"
                    />{" "}
                    {values.speakers[index].name&&<div className="form-control mx-auto ">
                      <label className="cursor-pointer label ">
                        <input
                          type="checkbox"
                          checked={(values.me==null?0:values.me) == index}
                          className="checkbox checkbox-accent"
                          onChange={() =>
                            setValues((v) => {
                              return { ...v, me: index };
                            })
                          }
                        />
                        <span className="label-text text-xs ml-2">I am {values.speakers[index].name}</span>
                      </label>
                    </div>}
                  </div>

                  <Field
                    name={`speakers.${index}.bio`}
                    type="text"
                    required={true}
                    placeholder="Bio"
                    className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  />
                </div>{" "}
                <div className="flex flex-col h-full">
                  <button
                    type="button"
                    onClick={() => {
                      setValues((v) => {
                        return { ...v, me: index>0?index-1:null };
                      });arrayHelpers.remove(index)
                    }}
                    className="btn btn-error h-full text-lg  m-2 "
                  >
                    ✕
                  </button>
                </div>
              </div>
            ))}
            {values.speakers.length < values.total_speakers && (
              <button
                type="button"
                onClick={() => {
                  if (values.speakers.length < 4) {
                    arrayHelpers.push({
                      name: "",
                      bio: "",
                      is_student: false,
                    });
                  }
                }}
                className="btn btn-success btn-square btn-md text-3xl mb-4"
              >
                +
              </button>
            )}
          </div>
        )}
      />
    </>
  );
}
