import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { backendURL } from "../constants/urls";
import { useParams, useNavigate } from "react-router-dom";
import { SpeakersList } from "./Speakers";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import Vocab from "./Vocab";
import { ProcessEncoding } from "../utils/learningLanguageHelpers";
import useLevel from "../utils/useLevel";
import { getAvatarFromSpeakers } from "../utils/useNarrativeData";
import { Player } from "@lottiefiles/react-lottie-player";
import spinningEarth from "../lottie/spinningEarth";

function DialogueLibraryCard({
  dialogue_id,
  narrative_id,
  title,
  translated_title,
  opening_scene,
  description,
  nativeLanguage,
  learningLanguage,
  difficulty,
  length,
  speakers,
  vocab,
  creationDate,
  showView,
  likes,
  creator_name,
  creator_id,
}) {
  const created = new Date(Date.parse(creationDate));
  const viewURL = `/narratives/${narrative_id}/dialogues/${dialogue_id}`;

  const [speakersOpen, setSpeakersOpen] = useState(false);
  const [vocabOpen, setVocabOpen] = useState(false);

  const ViewButton = (
    <a href={viewURL} className="btn btn-success m-4">
      View Dialogue
    </a>
  );

  return (
    <>
      <div className="relative card bg-base-200 shadow-xl w-full min-w-5xl p-4 ,-4">
        <div className="absolute top-0 left-[50%] bg-neutral text-neutral-content rounded-full  -translate-y-[50%] -translate-x-[50%] text-center p-2 flex justify-center items-center gap-2">
          <img
            src={`${backendURL}/image/?id=${creator_id}`}
            alt={creator_name}
            className="object-cover object-center h-8 rounded-full"
          />

          <div className="flex text-xs flex-nowrap text-nowrap mr-4">
            {creator_name}
          </div>

          <i class="bi bi-suit-heart-fill text-error text-2xl"></i>
          <div className="flex text-xs flex-nowrap text-nowrap">
            {likes}
            {likes != 1 ? " likes" : " like"}
          </div>
        </div>
        <div className="card-body w-full flex flex-row flex-wrap">
          <div className="flex flex-col justify-start items-center  w-full mb-auto">
            <h2 className="card-title">{title}</h2>
            <h3 className="text-md my-2">{translated_title}</h3>
            <p className="text-xs mb-2">
              {`Created ${created.toLocaleString([], {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}`}
            </p>
            <div className="flex flex-col justify-center items-center">
              <div className="badge badge-primary">
                Difficulty: {difficulty}
              </div>
              <div>
                <div className="badge badge-secondary">
                  Language: {learningLanguage}
                </div>
                <div className="badge badge-info">Length: {length}</div>
              </div>
            </div>
            <div></div>
          </div>

          <div className="flex flex-col justify-center w-full">
            <div className="collapse border border-base-300 bg-base-300 mb-2">
              <div
                className="flex justify-between h-16 items-center"
                onClick={() => {
                  setSpeakersOpen((s) => !s);
                }}
              >
                <p className="text font-bold m-4">{speakers.length} Speakers</p>{" "}
                <ChevronDownIcon
                  className={
                    "h-6 w-6 m-4" + (speakersOpen ? " rotate-180" : "")
                  }
                />
              </div>
              <div className={"p-2 " + (speakersOpen ? "" : "hidden")}>
                <SpeakersList
                  speakers={speakers}
                  getAvatar={(name) => getAvatarFromSpeakers(name, speakers)}
                />
              </div>
            </div>

            <div className="collapse  border border-base-300 bg-base-300 mb-2">
              <div
                className="flex justify-between h-16 items-center"
                onClick={() => {
                  setVocabOpen((s) => !s);
                }}
              >
                <p className="text font-bold m-4">
                  {vocab.length} Vocab, Phrases and Topics
                </p>{" "}
                <ChevronDownIcon
                  className={"h-6 w-6 m-4" + (vocabOpen ? " rotate-180" : "")}
                />
              </div>
              <div className={"p-2 " + (vocabOpen ? "" : "hidden")}>
                {vocab.map((v) => (
                  <Vocab vocab={v} key={v.learning_language_content} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <h2 className="text-lg font-bold text-center">Opening Scene</h2>
        <div
          role="alert"
          tabIndex={0}
          className="bg-base-300 collapse collapse-plus alert shadow-lg"
        >
          <div className="collapse-title text-sm font-medium flex flex-row justify-around items-center">
            {opening_scene}
          </div>
          <div className="collapse-content text-sm">
            <p>{description}</p>
          </div>
        </div>
        <div className="flex flex-row mx-auto">{ViewButton}</div>
      </div>
    </>
  );
}

const DialogueLibraryCardSkeleton = () => (
  <div className="flex flex-row gap-4 w-full max-w-5xl">
    <div className="flex flex-col gap-4 w-[33%]">
      <div className="skeleton h-4 w-full"></div>
      <div className="skeleton h-2 w-full"></div>
      <div className="skeleton h-2 w-full"></div>
      <div className="skeleton h-2 w-[33%]"></div>
      <div className="skeleton h-2 w-[33%]"></div>
      <div className="skeleton h-2 w-[33%]"></div>
    </div>
    <div className="flex flex-col gap-4 w-[66%]">
      <div className="skeleton h-12 w-full"></div>
      <div className="skeleton h-12 w-full"></div>

      <div className="flex flex-row ml-auto">
        <div className="skeleton h-12 w-12 mx-12"></div>
        <div className="skeleton h-12 w-24"></div>
      </div>
    </div>
  </div>
);

function TopDialogues({ data, loadMore, loading, exhausted }) {
  if (data.length < 1) {
    return (
      <div className="flex justify-center items-center">
        <div className="relative z-0">
          <div>
            <div className="bg-secondary text-secondary-content shadow-md rounded-lg p-6 max-w-3xl mx-auto my-4">
              <h2 className="text-xl font-semibold mb-4">
                We couldn't find any public dialogues in that language
              </h2>
              <p className="mb-3">
                If this is the language you're learning, be the first to create,
                complete, and publish a dialogue in this language!
              </p>
              <p className="mb-4">
                Remember that you can change your default learning language by
                going to the settings page. To create a dialogue for a specific
                language, you must first start a narrative in that language.
              </p>
              <div>
                <a
                  href="/settings"
                  className="btn btn-accent mb-4 transition duration-150 ease-in-out"
                >
                  Go to Settings →
                </a>
              </div>
              <div>
                <a
                  href="/narratives"
                  className="btn btn-accent transition duration-150 ease-in-out"
                >
                  Go to Narratives →
                </a>
              </div>
            </div>
          </div>
          <Player
            src={spinningEarth}
            style={{ height: "500px" }}
            speed={0.5}
            autoplay
            loop={true}
          />
        </div>
      </div>
    );
  }
  return (
    // <div className="flex flex-wrap fap-4 w-full max-w-[30rem] mx-auto">
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
      {data &&
        data.map((n) => {
          return (
            <DialogueLibraryCard
              key={n.title.learning_language_content}
              dialogue_id={n.dialogue_id}
              narrative_id={n.narrative_id}
              title={ProcessEncoding(n.title)}
              translated_title={ProcessEncoding({
                ...n.title,
                learning: false,
              })}
              creator_name={n.first_name}
              creator_id={n.user_id}
              likes={n.likes_cnt}
              opening_scene={ProcessEncoding(n.opening_scene)}
              description={ProcessEncoding({
                ...n.opening_scene,
                learning: false,
              })}
              learningLanguage={n.learning_language}
              difficulty={n.difficulty}
              length={n.length}
              speakers={n.speakers.filter(
                (s) => s.name.learning_language_content !== "event"
              )}
              vocab={n.vocab_phrases_and_topics}
              creationDate={n.created_at}
            />
          );
        })}
      {loading &&
        Array(10)
          .fill(null)
          .map((_, idx) => <DialogueLibraryCardSkeleton key={idx} />)}
      {!exhausted && (
        <button
          className="btn mx-auto m-20"
          onClick={loadMore}
          disabled={loading || !data}
        >
          {loading ? (
            <span className="loading loading-spinner loading-sm"></span>
          ) : (
            "Load More"
          )}
        </button>
      )}
    </div>
  );
}

export { DialogueLibraryCard, TopDialogues };
