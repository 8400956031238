import React, { useState, useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../App.css";
import { Formik, Form, Field } from "formik";
import LoggedInHeader from "../components/LoggedInHeader";
import UserCard from "../components/UserCard";
import { backendURL } from "../constants/urls";
import { handleLogout } from "../utils/logout";
import useSettings from "../utils/useSettings";
import {languageNames, uiThemeNames} from "../constants/formFields";
import CancelSubscriptionButton from "../components/CancelSubscriptionButton";
import SubscriptionDetails from "../components/SubscriptionDetails";

export default function SettingsPage() {
  const {settings: userSettings, mutate: updateUserSettings} = useSettings();
  const [updateMessage, setUpdateMessage] = useState(false);

  const handleSubmit = async (data) => {
    await fetch(`${backendURL}/settings/`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          setUpdateMessage({
            message: "Settings could not be saved",
            type: "error",
          });
        } else {
          updateUserSettings({...userSettings, ...data})
          setUpdateMessage({
            message: "Settings have been successfully saved",
            type: "success",
          });
        }
      })
      .catch((error) => {
        setUpdateMessage({
          message: "Settings could not be saved",
          type: "error",
        });
      });
  };

  if (!userSettings) {
    return <span className="loading loading-spinner loading-lg"></span>;
  }

  const initialValues = {
    first_name: userSettings.first_name,
    last_name: userSettings.last_name,
    bio: userSettings.bio,
    theme: userSettings.theme,
    native_language: userSettings.native_language,
    default_learning_language: userSettings.default_learning_language,
    // fluencies: getFluencies(userSettings), // Adjusted to fit Formik structure
  };

  return (
    <>
      <LoggedInHeader />
      <div className="container mx-auto p-4">
        <UserCard
          name={userSettings.email}
          registeredDate={userSettings.created_at}
          title={userSettings.bio}
        />
	<SubscriptionDetails />
        <div className="w-full flex justify-center">
            <a
              className="btn btn-primary mt-4 hover:btn-accent mx-auto"
              href="/feedback"
            >
              Leave Us Feedback
            </a>
        </div>
        <div className="w-full flex justify-center">
          <form action={`${backendURL}/logout/`}>
            <button
              type="button"
              onClick={handleLogout}
              className="btn btn-error text-error-content mt-4 mx-auto"
            >
              Logout
            </button>
          </form>
        </div>
        <div className="divider py-6">Settings</div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ errors, isSubmitting, values}) => (
            <Form key='form'>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">First Name</span>
                </label>
                <Field
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  className={"input input-bordered "+ (errors.first_name ? "input-error" : "")}
                  validate={(v) =>
                    v ? v.length > 20 ? "First Name can be up to 20 characters." : null : null
                  }
                />
                                                <div className="label">
                  <span
                    className={
                      "label-text-alt " + (errors.first_name ? "text-error" : "")
                    }
                  >{`${values.first_name.length}/20 characters`}</span>
                </div>
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">Last Name</span>
                </label>
                <Field
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  className={"input input-bordered "+ (errors.last_name ? "input-error" : "")}
                  validate={(v) =>
                    v ? v.length > 20 ? "Last Name can be up to 20 characters." : null : null
                  }
                />
                                <div className="label">
                  <span
                    className={
                      "label-text-alt " + (errors.last_name ? "text-error" : "")
                    }
                  >{`${values.last_name.length}/20 characters`}</span>
                </div>
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">Bio</span>
                </label>
                <Field
                  type="text"
                  name="bio"
                  placeholder="Bio"
                  className={"input input-bordered "+ (errors.bio ? "input-error" : "")}
                  validate={(v) =>
                    v ? v.length > 150 ? "Bio can be up to 150 characters." : null : null
                  }
                />
                <div className="label">
                  <span
                    className={
                      "label-text-alt " + (errors.bio ? "text-error" : "")
                    }
                  >{values.bio ? `${values.bio.length}/150 characters` : "0/150 characters"}</span>
                </div>
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">UI Theme</span>
                </label>
                <Field
                  as="select"
                  name="theme"
                  className="input input-bordered"
                >
                  {uiThemeNames.map((t) => (
                    <option>{t}</option>
                  ))}
                </Field>
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">Native Language</span>
                </label>
                <Field
                  as="select"
                  name="native_language"
                  className="input input-bordered"
                >
                  {languageNames.map((l) => (
                    <option>{l}</option>
                  ))}
                </Field>
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">Default Learning Language</span>
                </label>
                <Field
                  as="select"
                  name="default_learning_language"
                  className="input input-bordered"
                >
                  {languageNames.map((l) => (
                    <option>{l}</option>
                  ))}
                </Field>
              </div>
              {/*
              <div className="overflow-x-auto py-8">
                <h1 className="text-xl font-bold mb-4">Fluencies</h1>
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>Language</th>
                      <th>Fluency</th>
                    </tr>
                  </thead>
                  <tbody>
                    {initialValues.fluencies.map((_, index) => (
                      <FluencyRow key={index} name={`fluencies[${index}]`} />
                    ))}
                  </tbody>
                </table>
              </div> */}
              {updateMessage && (
                <div
                  className={`mt-4 alert ${
                    updateMessage.type === "success"
                      ? "alert-success"
                      : "alert-error"
                  } shadow-lg`}
                >
                  <div>
                    <i className="bi bi-check-circle-fill"></i>
                    <span>{updateMessage.message}</span>
                  </div>
                  <div className="flex-none">
                    <button
                      className="btn btn-sm btn-ghost"
                      onClick={() => setUpdateMessage(false)}
                    >
                      Dismiss
                    </button>
                  </div>
                </div>
              )}
              <button
                type="submit"
                disabled={isSubmitting}
                className={`btn btn-primary mt-4 hover:btn-secondary ${
                  isSubmitting ? "loading" : ""
                }`}
              >
                Save Changes
              </button>
            </Form>
          )}
        </Formik>
        <div className="py-8">
        </div>
      </div>
    </>
  );
}
