import { useState } from "react";
import useUser from "../utils/useUser";
import { backendURL } from "../constants/urls";
import { DateTimeConverter } from "../utils/datetimeHelpers";

export default function SingleComment({
  name,
  date,
  deleted_at,
  comment,
  userId,
  dialogueId,
  commentId,
  numReplies,
  disableReplyButton,
}) {
  const [flagged, setFlagged] = useState(false);

  const handleFlag = (e) => {
    setFlagged(true);
    handleSubmit(e, false, false, true);
    setTimeout(() => {
      setFlagged(false);
    }, 5000);
  };
  const [reply, setReply] = useState("");
  const [edit, setEdit] = useState("");
  const [showReplyBox, setShowReplyBox] = useState(false);
  const [showEditBox, setShowEditBox] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [replies, setReplies] = useState([]);
  const [currentNumReplies, setCurrentNumReplies] = useState(numReplies);
  const { user, isLoading, isError } = useUser();
  const showReplyButton = disableReplyButton ? false : true;
  const created = new Date(Date.parse(date));
  const displayDate = created.toLocaleString([], {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  const toggleShowReplyBox = () => setShowReplyBox((r) => !r);
  const toggleShowEditBox = () => setShowEditBox((e) => !e);

  const toggleShowReplies = () => {
    fetch(
      `${backendURL}/dialogues/${dialogueId}/comments/1?` +
        new URLSearchParams({
          parent_id: commentId,
        }),
      {
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => response.json())
      .then((data) => setReplies(data));
    setShowReplies(!showReplies);
  };

  const handleSubmit = async (event, edited, deletion, flag) => {
    event.stopPropagation();
    event.preventDefault();
    console.log(event.target.value, edited, deletion, flag);
    try {
      const response = await fetch(
        `${backendURL}/dialogues/${dialogueId}/comments/`,
        {
          credentials: "include",
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...(deletion || flag ? {} : { comment: edited ? edit : reply }),
            edit: edited,
            deletion,
            flag,
            ...(edited || deletion || flag
              ? { id: commentId }
              : { parent_id: commentId }),
          }),
        }
      );

      const data = await response.json(); // Handle the response data as needed
      
      if (!(edited || deletion || flag)) {
        const currentDate = new Date().toLocaleDateString();
        setReplies([
          {
            id: data.id,
            dialogue_id: dialogueId,
            user_id: user.id,
            parent_id: commentId,
            created_at: currentDate,
            comment: reply,
            deleted_at: null,
            first_name: user.first_name,
            last_name: user.last_name,
          },
          ...replies,
        ]);
      }
      if (deletion) {
        setEdit("[User deleted comment]");
      }
      setCurrentNumReplies(currentNumReplies + 1);
      setShowReplies(true);
      setReply("");
      setShowEditBox(false);
      setShowReplyBox(false);
      if(edited){
        setEdit((e) => "[Edited] " + edit);
      }
    } catch (error) {
      console.error("Failed to post comment:", error);
    }
  };

  const replyBox = (
    <form
      className="mb-2 py-6"
      onSubmit={(e) => handleSubmit(e, false, false, false)}
    >
      <div className="py-2 px-4 mb-4 rounded-lg rounded-t-lg border border-gray-200 ">
        <label for="comment" className="sr-only">
          Your reply
        </label>
        <textarea
          id="reply"
          rows="6"
          className="px-0 w-full border-0 focus:ring-0 focus:outline-none "
          placeholder="Reply to this comment..."
          value={reply}
          onChange={(e) => setReply(e.target.value)}
          required
        ></textarea>
      </div>
      <button
        type="submit"
        className="btn inline-flex items-center py-2.5 px-4 font-medium rounded-lg focus:ring-4 focus:ring-primary-200 hover:btn-primary"
      >
        Post reply
      </button>
    </form>
  );
  const editBox = (
    <form
      className="mb-2 py-6"
      onSubmit={(e) => handleSubmit(e, true, false, false)}
    >
      <div className="py-2 px-4 mb-4 rounded-lg rounded-t-lg border border-gray-200 ">
        <label for="comment" className="sr-only">
          Edit Your Comment
        </label>
        <textarea
          id="edit_comment"
          rows="6"
          className="px-0 w-full border-0 focus:ring-0 focus:outline-none "
          placeholder="Write your edit..."
          value={edit}
          onChange={(e) => setEdit(e.target.value)}
          required
        ></textarea>
      </div>
      <button
        type="submit"
        className="btn inline-flex items-center py-2.5 px-4 font-medium rounded-lg focus:ring-4 focus:ring-primary-200 hover:btn-primary"
      >
        Submit Edit
      </button>
    </form>
  );
  const repliesDisplay = replies.map((c) => (
    <SingleComment
      key={c.id}
      name={`${c.first_name} ${c.last_name}`}
      date={c.created_at}
      deleted_at={c.deleted_at}
      comment={c.comment}
      commentId={c.id}
      userId={c.user_id}
      dialogueId={dialogueId}
      numReplies={c.num_replies}
      disableReplyButton={true}
    />
  ));
  return (
    <article className="p-6 rounded-lg">
      <footer className="flex justify-between items-center mb-2">
        <div className="flex items-center">
          <p className="inline-flex items-center mr-3 font-semibold">
            <img
              className="mr-2 w-6 h-6 rounded-full"
              src={`${backendURL}/user/image/${userId}`}
              alt={name}
            />
            {name}
          </p>
          
          <p className=" text-xs">
          <DateTimeConverter utcDateTime={displayDate}></DateTimeConverter>

          </p>
        </div>
        {deleted_at == null && (
          <div className="dropdown dropdown-left dropdown-end">
            <div
              tabIndex={commentId}
              role="button"
              className="btn-sm m-1 btn-ghost rounded-lg"
            >
              <i className="bi bi-three-dots text-lg"></i>
            </div>
            <ul
              tabIndex={commentId}
              className="dropdown-content z-[151] min-w-20 menu p-2 shadow bg-base-100 rounded-box text-center gap-2"
            >
              <li>
                <a className="btn btn-sm btn-warning" onClick={handleFlag}>
                  <i className="bi bi-flag-fill text-md  mx-auto"></i>
                </a>
              </li>
              {user.id === userId && (
                <>
                  <li>
                    <a className="btn btn-sm" onClick={toggleShowEditBox}>
                      Edit
                    </a>
                  </li>
                  <li>
                    <a
                      className="btn btn-sm btn-error"
                      onClick={(e) => handleSubmit(e, false, true, false)}
                    >
                      Delete
                    </a>
                  </li>
                </>
              )}
            </ul>
          </div>
        )}
      </footer>
      <p className="">{edit || comment}</p>
      {showReplyButton ? (
        <div className="flex items-center mt-4 space-x-4">
          <button
            type="button"
            className="flex items-center hover:underline"
            onClick={toggleShowReplyBox}
          >
            <svg
              className="mr-1.5 w-3.5 h-3.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 18"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 5h5M5 8h2m6-3h2m-5 3h6m2-7H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"
              />
            </svg>
            Reply
          </button>
          {currentNumReplies ? (
            <button
              onClick={toggleShowReplies}
              className="btn btn-sm btn-outline font-bold btn-secondary"
            >{`${currentNumReplies} ${
              currentNumReplies == 1 ? "Reply" : "Replies"
            }`}</button>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {showReplyBox ? replyBox : <></>}
      {showEditBox ? editBox : <></>}
      {flagged && (
        <div className="toast toast-end z-[150]">
          <div className="alert alert-warning ">
            <span>Comment Flagged For Moderation Review.</span>
          </div>
        </div>
      )}
      {showReplies ? (
        <div className="bg-base-200 rounded-lg my-4 mx-4">{repliesDisplay}</div>
      ) : (
        <></>
      )}
    </article>
  );
}
