export default function Logo({primary="oklch(var(--bc))", secondary="oklch(var(--s))"}) {
    return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="100%" viewBox="0 0 1313 378" enableBackground="new 0 0 1313 378" >
        <path opacity="0" stroke="none"
            d="
M1022.000000,379.000000
	C681.333374,379.000000 341.166748,379.000000 1.000091,379.000000
	C1.000061,253.000061 1.000061,127.000114 1.000030,1.000125
	C438.666534,1.000083 876.333069,1.000083 1313.999756,1.000042
	C1313.999756,126.999878 1313.999756,252.999756 1313.999878,378.999817
	C1216.833374,379.000000 1119.666626,379.000000 1022.000000,379.000000
M319.913818,127.584412
	C310.665466,114.869896 295.794189,112.596611 282.373077,108.269051
	C273.527863,105.416969 263.796661,105.364746 254.508453,103.786583
	C252.834930,103.502235 250.988129,102.209763 249.905014,100.841499
	C245.928802,95.818542 242.483551,90.365349 238.389893,85.447479
	C231.466583,77.130234 223.102219,70.982941 212.499252,67.621674
	C193.035019,61.451271 173.867401,54.761456 152.805359,57.196995
	C139.643036,58.719044 126.191208,57.953297 113.100029,59.826599
	C90.566811,63.051037 69.326813,70.788887 49.500370,82.028572
	C36.665501,89.304695 25.797924,99.009018 19.980904,112.786858
	C12.007770,131.671539 7.773622,151.750702 5.257419,171.974808
	C2.201190,196.539398 3.760858,221.169601 8.222584,245.522018
	C10.104626,255.794327 12.389876,265.873199 20.110758,274.044647
	C26.890079,281.219604 34.605415,284.694824 44.334057,284.554962
	C51.240261,284.455627 57.070419,281.591949 62.021255,277.374756
	C65.887405,274.081543 68.850861,269.702057 72.086189,265.700745
	C77.487724,259.020325 81.920433,251.239136 88.339920,245.777039
	C96.971970,238.432373 106.911034,232.529129 116.684486,226.666336
	C120.702835,224.255875 125.724121,222.635117 130.342453,222.501938
	C132.458435,222.440903 134.526886,227.036804 136.919876,229.182892
	C147.245651,238.443253 159.426788,244.752502 172.685654,247.961075
	C192.325043,252.713684 212.377029,255.742142 232.078857,260.264862
	C240.539902,262.207184 249.120621,265.055664 256.665314,269.255798
	C270.979645,277.224609 281.250305,289.793365 291.285126,302.565399
	C295.904938,308.445374 301.324799,314.569611 307.763489,317.956360
	C319.283325,324.015625 328.477997,321.243561 332.643799,307.651367
	C335.347626,298.829102 338.040405,290.000671 340.956482,281.247742
	C342.025116,278.040039 344.430145,275.168579 345.010468,271.926239
	C347.464874,258.212769 349.435974,244.413330 351.632751,230.653122
	C354.240509,214.318466 355.474304,197.990067 352.157318,181.580872
	C347.801849,160.034363 336.188782,142.834976 319.913818,127.584412
M1086.850098,201.265640
	C1098.467041,168.814789 1099.405396,135.643402 1091.826782,102.405815
	C1085.657715,75.349930 1073.898071,50.897766 1052.072632,32.525871
	C1025.273438,9.967423 992.194519,11.876785 968.002014,37.250877
	C957.709961,48.045605 950.742615,60.915253 945.971191,74.845551
	C935.282410,106.051491 934.660217,137.912277 941.804993,169.879272
	C947.555420,195.607574 957.808960,219.398590 977.327209,237.857071
	C1002.023071,261.211975 1035.051025,264.776398 1061.670166,240.677719
	C1073.420044,230.040314 1080.710571,216.460068 1086.850098,201.265640
M816.213379,256.286926
	C827.898865,259.162628 838.781067,256.007843 848.981750,250.905380
	C869.763428,240.510269 881.166565,222.116898 888.985107,201.225693
	C900.451904,170.586090 901.370911,139.018066 895.148499,107.274506
	C889.835632,80.171135 878.994141,55.423565 858.420166,36.222904
	C840.500061,19.498886 819.675354,11.492761 795.266479,20.332870
	C779.135803,26.174917 767.705750,37.823101 758.888794,52.336048
	C745.164917,74.925850 740.242554,99.696739 739.710571,125.742905
	C739.013306,159.879944 746.495361,191.821579 765.204102,220.606583
	C777.128174,238.952911 792.892639,252.437592 816.213379,256.286926
M486.302063,241.138718
	C476.449493,246.002991 468.779877,242.941223 464.988922,232.616211
	C463.959015,229.811127 463.088806,226.941711 461.942413,224.186249
	C459.285980,217.801315 453.586029,214.383118 447.891388,215.609100
	C440.689667,217.159546 436.180328,222.630569 436.727325,229.910599
	C437.036194,234.021362 437.838715,238.182861 439.060364,242.122589
	C445.622162,263.284302 468.775208,276.019409 489.132355,269.912964
	C510.575867,263.480652 528.066162,241.775360 520.599854,211.848221
	C516.670837,196.099747 507.998108,182.927002 498.589142,170.269882
	C487.472687,155.315826 475.373474,141.096436 463.979492,126.342934
	C457.690765,118.199928 454.024261,108.822296 452.385742,98.579445
	C448.554504,74.629745 464.405457,49.584198 487.439056,43.540627
	C502.476349,39.595123 515.511169,48.498989 517.137939,63.885693
	C517.505310,67.360260 517.486938,70.878235 517.913086,74.343391
	C518.719666,80.900261 523.961182,85.748573 530.236877,85.978691
	C536.304443,86.201172 542.645569,81.589165 543.656799,75.422287
	C545.696777,62.981873 544.855042,50.858124 538.134399,39.703560
	C522.423767,13.627905 484.523102,6.823342 458.758667,25.908731
	C413.675842,59.304478 417.414978,111.127617 442.335327,143.660385
	C453.774323,158.593597 465.846100,173.040634 477.398529,187.888947
	C484.471863,196.980316 490.472778,206.768906 493.383057,218.091980
	C495.627533,226.824524 494.072479,234.591751 486.302063,241.138718
M1164.501343,37.172581
	C1172.665161,37.116096 1180.829956,37.115990 1188.992676,36.989830
	C1201.013428,36.804043 1201.418579,37.744946 1198.411011,49.435928
	C1185.909424,98.032593 1172.964478,146.517471 1159.833740,194.948914
	C1154.620605,214.176910 1148.066040,233.043442 1142.945068,252.293457
	C1139.403809,265.605957 1147.841675,276.246307 1161.776245,276.722900
	C1175.090942,277.178253 1188.438354,277.002136 1201.763062,276.718414
	C1209.631958,276.550873 1215.030273,271.651306 1215.744507,264.992554
	C1216.522095,257.741760 1212.568359,251.854355 1204.525635,250.410522
	C1196.706421,249.006775 1188.665405,248.728790 1180.703979,248.256775
	C1173.244141,247.814514 1173.042603,247.670670 1175.118652,240.398239
	C1183.965820,209.406387 1193.246094,178.528641 1201.493774,147.377716
	C1211.232666,110.594162 1220.311279,73.629700 1229.176270,36.623913
	C1232.592651,22.362736 1226.184692,13.407052 1211.613281,10.863008
	C1190.582397,7.191198 1169.394043,8.768132 1148.297729,10.211946
	C1139.680786,10.801681 1134.078003,17.769258 1134.562988,25.214020
	C1135.008789,32.059589 1141.079590,36.726864 1150.004517,36.989849
	C1154.501465,37.122353 1159.002441,37.116032 1164.501343,37.172581
M630.922729,189.499939
	C630.894714,207.994171 630.820801,226.488586 630.887634,244.982468
	C630.897705,247.777206 631.202942,250.677216 631.998840,253.341110
	C633.777771,259.294647 639.913391,263.430054 645.636292,262.963989
	C651.440063,262.491364 656.133606,258.031433 657.148987,251.623535
	C657.667603,248.350403 657.846252,244.993423 657.852417,241.673737
	C657.908997,211.182541 657.834229,180.691025 657.915588,150.199921
	C658.013367,113.549210 658.232056,76.898827 658.381470,40.248234
	C658.397705,36.272400 660.428406,33.893368 664.226624,33.437958
	C669.827698,32.766384 675.493225,32.630550 681.093018,31.951164
	C685.343994,31.435429 689.726746,30.956562 693.707520,29.513910
	C699.449585,27.432974 702.324768,22.376410 701.805115,17.084185
	C701.183594,10.754163 697.300842,6.130396 691.010315,5.062764
	C686.780701,4.344906 682.401978,3.923610 678.129517,4.142331
	C661.165100,5.010799 644.209106,6.083836 627.263977,7.278088
	C616.147217,8.061567 605.000061,8.786442 593.970337,10.296328
	C586.948792,11.257530 583.294250,16.657215 583.611511,23.359598
	C583.940002,30.298483 588.753174,35.801052 595.838013,36.123486
	C603.789185,36.485344 611.803711,36.357510 619.745850,35.801998
	C631.361572,34.989536 631.392212,34.746517 631.360352,46.564831
	C631.232910,93.876572 631.073364,141.188232 630.922729,189.499939
M1268.827515,64.499947
	C1268.667603,80.811096 1268.069092,97.132042 1268.432739,113.431511
	C1269.395264,156.561401 1270.727661,199.683456 1272.073486,242.803604
	C1272.185303,246.386688 1273.123169,250.070999 1274.348877,253.465714
	C1276.838379,260.359894 1281.044922,262.747772 1288.218140,262.025116
	C1294.287964,261.413635 1297.858276,257.909821 1299.032715,250.884781
	C1299.604370,247.466049 1299.915161,243.939484 1299.799316,240.479950
	C1298.618042,205.190186 1296.714355,169.912476 1296.233521,134.614761
	C1295.750488,99.149017 1296.754028,63.664604 1296.824951,28.187828
	C1296.834351,23.516636 1295.884033,18.552643 1294.111572,14.245444
	C1292.083374,9.316398 1287.534302,6.861281 1281.956787,7.317026
	C1276.041382,7.800382 1271.903198,10.634729 1270.640259,16.726599
	C1269.968750,19.965914 1269.112305,23.244486 1269.050537,26.518759
	C1268.817871,38.842831 1268.877319,51.172417 1268.827515,64.499947
M947.936584,361.342499
	C946.908569,358.392548 945.563232,355.509827 944.921143,352.478149
	C943.725952,346.835419 940.268372,346.664551 935.578857,348.000305
	C934.912354,356.354858 934.257568,364.563141 933.574524,373.125305
	C935.676575,372.975922 937.255798,372.863708 938.757385,372.756989
	C939.314087,367.533203 939.824341,362.745148 940.334595,357.957092
	C942.974609,361.819000 944.283325,365.818939 945.889832,369.695465
	C946.418335,370.970612 947.717651,371.926331 948.664429,373.028107
	C949.592712,371.884644 950.876953,370.879395 951.384338,369.572479
	C953.096619,365.162018 954.532959,360.644501 956.074829,356.167908
	C956.321899,356.271240 956.568970,356.374603 956.816101,356.477936
	C957.334290,361.897400 957.852478,367.316864 958.401001,373.053925
	C960.199341,372.954834 961.754150,372.869171 963.541565,372.770691
	C962.970398,365.258270 962.557434,358.145782 961.776367,351.074005
	C961.632812,349.773865 960.121582,347.771057 959.022217,347.612274
	C956.894653,347.304962 954.102722,346.262604 953.032410,350.030334
	C951.871033,354.118591 949.988647,358.002075 947.936584,361.342499
M759.590637,360.496765
	C759.590637,356.240601 759.590637,351.984467 759.590637,347.319519
	C757.441101,347.522217 756.050659,347.653320 754.090820,347.838165
	C754.090820,353.182678 754.090820,358.164368 754.090820,363.146057
	C750.571289,359.322479 747.698669,355.468994 745.511902,351.259186
	C743.504211,347.394226 740.904602,346.603729 737.226807,347.880798
	C737.226807,356.396545 737.226807,364.667908 737.226807,372.867523
	C738.974365,372.867523 740.452087,372.867523 742.373779,372.867523
	C742.373779,367.468079 742.373779,362.236145 742.373779,357.004211
	C745.925476,360.811401 748.674500,364.856049 751.065308,369.102295
	C753.015198,372.565399 755.372375,374.228485 759.590576,372.380066
	C759.590576,368.778259 759.590576,365.129150 759.590637,360.496765
M793.680359,359.999695
	C789.867371,359.610138 786.054443,359.220612 781.860657,358.792145
	C782.054199,360.919189 782.166077,362.148529 782.275818,363.354187
	C784.451660,363.559357 786.223877,363.726471 788.080017,363.901489
	C786.098877,368.413055 782.028564,369.869568 778.023987,367.711121
	C774.615356,365.873932 773.015442,359.999176 774.902710,356.249939
	C777.003235,352.077026 782.008972,350.430725 785.823425,353.254852
	C788.458618,355.205841 790.233582,355.730988 792.231506,352.895782
	C787.818054,346.788757 779.889832,345.387299 773.843994,349.540131
	C768.505859,353.206879 767.327026,361.952759 770.910095,367.889557
	C774.080444,373.142517 783.179077,375.130981 788.343506,371.177399
	C790.173645,371.946259 791.744080,372.605957 793.864319,373.496643
	C793.864319,368.528595 793.864319,364.717804 793.680359,359.999695
M867.756714,349.045288
	C861.388550,353.995544 859.847412,361.719604 863.996460,367.891815
	C867.877441,373.665436 875.206116,374.854218 881.218445,371.224548
	C883.204651,371.996399 884.788208,372.611786 886.565796,373.302551
	C886.565796,368.025208 886.565796,363.775116 886.565796,359.325806
	C882.649475,359.325806 879.053223,359.325806 875.023254,359.325806
	C875.123596,360.808167 875.212891,362.126862 875.296631,363.363525
	C877.567078,363.572113 879.330872,363.734161 881.214355,363.907227
	C878.922791,368.486908 874.858704,369.873993 870.947388,367.662506
	C867.330688,365.617554 866.193237,359.203400 868.736389,355.194397
	C870.924133,351.745514 875.857544,350.565216 879.089111,353.381989
	C881.622498,355.590271 883.236450,355.262329 885.242371,353.217224
	C881.611389,347.258942 876.581421,345.951813 867.756714,349.045288
M613.109070,357.111694
	C612.421509,362.883026 613.400452,368.187531 618.719421,371.395081
	C623.248718,374.126373 628.263794,373.840393 632.858154,371.404419
	C637.653320,368.861969 639.691528,362.815186 638.287354,356.754974
	C637.028809,351.322937 632.486633,347.630219 626.504822,347.175934
	C620.602722,346.727692 615.926697,349.993378 613.109070,357.111694
M470.901886,348.271851
	C469.305267,348.009064 467.708649,347.746246 465.770996,347.427277
	C465.770996,351.370819 465.770996,354.505981 465.770996,357.720551
	C462.093140,357.720551 458.681000,357.720551 454.859070,357.720551
	C454.859070,354.213135 454.859070,350.961578 454.859070,347.460999
	C453.005829,347.548767 451.582703,347.616150 450.021240,347.690063
	C450.021240,356.239624 450.021240,364.483887 450.021240,373.048645
	C451.796692,372.946320 453.338867,372.857422 455.301575,372.744293
	C455.301575,369.113464 455.301575,365.886017 455.301575,362.857666
	C459.034210,362.857666 462.308197,362.857666 465.812317,362.857666
	C465.812317,366.323975 465.812317,369.472168 465.812317,372.776917
	C467.613861,372.776917 469.174713,372.776917 471.007568,372.776917
	C471.007568,364.740417 471.007568,356.937866 470.901886,348.271851
M1093.741577,366.704285
	C1092.652832,365.065002 1091.564209,363.425751 1090.319946,361.552246
	C1094.629028,360.025879 1095.942383,357.138885 1095.139771,353.188416
	C1094.340576,349.254028 1091.434448,347.874298 1087.888672,347.642883
	C1084.790405,347.440704 1081.668335,347.600708 1078.369751,347.600708
	C1078.369751,356.289276 1078.369751,364.526550 1078.369751,373.091217
	C1080.208252,372.972076 1081.769165,372.870911 1083.778564,372.740692
	C1083.778564,368.997253 1083.778564,365.619507 1083.778564,361.963898
	C1089.288330,364.701599 1087.888550,375.161072 1097.434448,372.575623
	C1096.237427,370.667725 1095.195679,369.007385 1093.741577,366.704285
M564.451233,364.848938
	C563.856506,363.870331 563.261841,362.891754 562.667175,361.913147
	C563.123108,362.037628 563.579102,362.162140 564.035095,362.286621
	C565.158142,359.549774 567.143372,356.804718 567.117310,354.078857
	C567.098206,352.085144 564.768860,348.910736 562.880615,348.387604
	C558.922424,347.291046 554.576172,347.595703 550.322266,347.320679
	C550.322266,356.415924 550.322266,364.518524 550.322266,372.781921
	C552.094055,372.781921 553.656006,372.781921 555.881592,372.781921
	C555.881592,369.068390 555.881592,365.599518 555.881592,361.826477
	C561.417175,364.834167 560.043091,374.886810 569.623352,372.666840
	C567.921570,370.090485 566.394653,367.778931 564.451233,364.848938
M818.885864,347.456573
	C818.395142,348.532959 817.535278,349.591217 817.481323,350.689087
	C817.302246,354.334290 817.373718,357.994293 817.424316,361.647583
	C817.471619,365.059937 817.041565,368.335144 812.710571,368.319977
	C808.200500,368.304199 808.295959,364.667328 808.244568,361.491913
	C808.170166,356.893646 808.225586,352.293304 808.225586,347.644745
	C806.256042,347.644745 804.823914,347.644745 803.007080,347.644745
	C803.007080,353.471466 802.783081,359.091309 803.069031,364.685089
	C803.364502,370.465424 806.785095,373.091248 813.154907,373.040283
	C819.345093,372.990753 822.607788,370.164398 822.845215,364.281769
	C823.032593,359.638794 822.750427,354.976837 822.937805,350.333862
	C823.041443,347.765869 821.948792,347.106445 818.885864,347.456573
M836.226318,371.943604
	C836.870911,369.665741 837.441162,367.138702 840.674622,367.914917
	C843.626526,368.623535 847.608887,365.631592 849.515015,370.705322
	C850.032349,372.082214 852.758789,372.629150 854.471375,373.557007
	C852.506348,364.788025 849.895935,357.332031 846.915649,350.026825
	C846.424072,348.821838 844.363525,347.730103 842.932312,347.594238
	C841.683716,347.475769 839.517883,348.465393 839.101257,349.504364
	C836.051147,357.110657 833.339722,364.852722 830.309326,373.131561
	C832.595764,372.955872 834.212708,372.831604 836.226318,371.943604
M975.737488,359.075256
	C974.073303,363.550903 972.409119,368.026550 970.709900,372.596405
	C973.987793,373.511658 976.147766,373.401886 977.468140,369.661469
	C977.872131,368.516876 981.291199,368.035217 983.345093,367.992126
	C985.235535,367.952484 988.560120,368.543030 988.806458,369.491364
	C989.925110,373.798523 992.577576,373.324493 995.717102,372.663269
	C992.888611,365.159058 990.202271,357.711273 987.206299,350.390198
	C986.689941,349.128296 984.850403,347.619904 983.636047,347.632050
	C982.136108,347.646942 980.042786,348.774750 979.288269,350.058136
	C977.801758,352.586273 977.061462,355.553192 975.737488,359.075256
M524.728088,352.119507
	C522.264771,358.895538 519.801392,365.671600 517.351135,372.411469
	C522.386353,374.656006 523.365540,371.152496 524.305786,367.875336
	C527.114868,367.875336 529.622009,367.684875 532.078918,367.953766
	C533.354492,368.093323 535.466492,368.871704 535.613831,369.658569
	C536.408386,373.902893 539.046448,373.266052 542.166016,372.639618
	C539.513672,365.500214 536.877258,358.578339 534.389099,351.603607
	C533.600708,349.393829 533.357910,347.360474 530.043640,347.293213
	C526.771484,347.226898 525.677673,348.793274 524.728088,352.119507
M1019.100403,360.358124
	C1016.812744,359.402527 1014.457581,358.577820 1012.260742,357.446045
	C1010.761292,356.673553 1009.452576,355.506134 1008.186401,354.372467
	C1008.116150,354.309509 1009.224792,352.288940 1009.770142,352.296051
	C1011.613159,352.320160 1013.467773,352.771240 1015.283447,353.203949
	C1017.125061,353.642792 1018.925598,354.253510 1021.772766,355.091858
	C1018.736755,348.215881 1013.584778,345.628021 1008.278381,348.011322
	C1006.009705,349.030273 1003.963806,351.779144 1003.020264,354.209259
	C1001.774536,357.417847 1004.491150,359.331970 1007.134827,360.583496
	C1009.373230,361.643219 1011.857849,362.211243 1014.021606,363.386810
	C1014.933899,363.882446 1016.054565,365.777740 1015.758423,366.337830
	C1015.169128,367.452179 1013.665161,368.746277 1012.537781,368.747528
	C1011.073792,368.749176 1009.017334,367.956482 1008.256653,366.813232
	C1006.139160,363.630920 1004.185913,365.203827 1001.416260,366.473022
	C1002.840881,368.272461 1003.831421,370.023254 1005.272034,371.242126
	C1008.548706,374.014496 1016.299438,373.842590 1019.214111,371.003967
	C1022.471130,367.831909 1022.588379,364.377472 1019.100403,360.358124
M708.309265,360.692841
	C706.911682,364.685028 705.514038,368.677185 704.154907,372.559448
	C709.184326,374.834686 710.073730,370.760590 711.443420,368.022339
	C721.623718,367.631348 721.623718,367.631348 723.613586,372.676544
	C725.325989,372.676544 727.004333,372.676544 728.989197,372.676544
	C725.756653,363.974274 722.678650,355.687988 719.764221,347.842010
	C714.778015,346.399536 712.367249,348.159241 711.351807,352.536377
	C710.756653,355.101898 709.631104,357.544342 708.309265,360.692841
M902.049683,366.383850
	C902.168030,365.154236 902.286438,363.924591 902.424622,362.488953
	C905.092346,362.488953 907.380310,362.488953 909.938171,362.488953
	C909.938171,360.854095 909.938171,359.469360 909.938171,357.780304
	C907.217224,357.780304 904.784790,357.780304 902.025574,357.780304
	C902.114807,355.763184 902.189819,354.067596 902.279358,352.043518
	C905.483398,352.043518 908.249084,352.043518 911.338196,352.043518
	C911.152161,350.256989 911.006592,348.858948 910.894409,347.781219
	C905.865967,347.781219 901.291199,347.781219 896.744934,347.781219
	C896.744934,356.382812 896.744934,364.603973 896.744934,372.833069
	C901.683899,372.833069 906.281250,372.833069 910.963867,372.833069
	C910.963867,371.197754 910.963867,369.776550 910.963867,368.561584
	C907.819092,368.116333 904.962097,367.711823 902.049683,366.383850
M1067.445679,352.170197
	C1067.815674,350.707550 1068.185547,349.244934 1068.593750,347.631348
	C1063.171631,347.631348 1058.727905,347.631348 1054.116577,347.631348
	C1054.116577,356.169250 1054.116577,364.504181 1054.116577,372.769867
	C1059.042969,372.769867 1063.635620,372.769867 1068.608154,372.769867
	C1068.441895,371.084503 1068.304932,369.696960 1068.141602,368.041382
	C1064.975830,368.041382 1062.205078,368.041382 1059.401123,368.041382
	C1059.401123,366.049957 1059.401123,364.377197 1059.401123,362.476685
	C1062.059448,362.476685 1064.351074,362.476685 1066.920166,362.476685
	C1066.920166,360.859314 1066.920166,359.477722 1066.920166,357.797852
	C1064.208984,357.797852 1061.778687,357.797852 1059.346924,357.797852
	C1059.346924,355.778229 1059.346924,354.078949 1059.346924,352.211090
	C1061.981445,352.211090 1064.302612,352.211090 1067.445679,352.170197
M482.704254,372.962921
	C487.165222,372.962921 491.626221,372.962921 496.487122,372.962921
	C496.408325,371.242950 496.345093,369.862823 496.263184,368.075104
	C493.105103,368.075104 490.340271,368.075104 487.373627,368.075104
	C487.309448,366.872833 487.212585,366.078430 487.240448,365.288391
	C487.267426,364.524139 487.422791,363.764374 487.508545,363.104492
	C495.433472,361.917664 495.433472,361.917664 494.995789,357.837616
	C492.476105,357.837616 489.898865,357.837616 487.118896,357.837616
	C487.278229,355.737640 487.399261,354.142548 487.529633,352.424408
	C490.710388,352.277069 493.479309,352.148834 496.216949,352.022003
	C496.452637,347.522614 496.453125,347.487091 492.731049,347.434845
	C489.271240,347.386261 485.810150,347.424469 482.032776,347.424469
	C482.032776,356.024506 482.032776,364.146027 482.704254,372.962921
M647.968506,370.256348
	C647.815247,375.073944 650.999268,372.466370 653.319824,372.911530
	C653.319824,369.399597 653.319824,366.284454 653.319824,363.735870
	C655.581177,360.832550 662.475342,365.600677 661.300842,357.827606
	C658.388000,357.827606 655.925659,357.827606 653.185852,357.827606
	C653.289612,355.903534 653.374939,354.321716 653.475769,352.452209
	C656.697021,352.299622 659.616882,352.161316 662.858276,352.007751
	C662.760803,350.412720 662.676208,349.028198 662.593323,347.671387
	C657.584167,347.671387 652.970886,347.671387 647.968323,347.671387
	C647.968323,355.037567 647.968323,362.168182 647.968506,370.256348
M1107.506226,355.132385
	C1112.590454,360.160797 1110.607666,366.878723 1111.217041,372.882935
	C1113.212524,372.882935 1114.679077,372.882935 1116.931885,372.882935
	C1114.334717,362.886383 1119.531006,355.381348 1124.856201,347.890869
	C1117.433716,345.368958 1117.071167,352.290436 1113.957642,355.495026
	C1110.579468,352.623596 1110.584473,345.236877 1103.219727,347.972534
	C1104.595825,350.281342 1105.854980,352.393707 1107.506226,355.132385
M425.300690,347.533142
	C424.808472,348.909821 424.316284,350.286499 423.721680,351.949524
	C426.229492,352.194183 428.002960,352.367218 430.250671,352.586517
	C430.250671,359.429626 430.250671,366.156128 430.250671,372.893158
	C432.211273,372.893158 433.679413,372.893158 435.687378,372.893158
	C435.687378,366.023987 435.687378,359.299103 435.687378,352.584961
	C437.956177,352.317261 439.692505,352.112366 441.268433,351.926392
	C442.396912,348.122711 440.913605,347.131897 437.617065,347.320862
	C433.810333,347.539062 429.982208,347.383728 425.300690,347.533142
M1039.630005,360.491425
	C1039.630005,357.874298 1039.630005,355.257141 1039.630005,352.564758
	C1041.849121,352.330109 1043.599121,352.145081 1045.251099,351.970398
	C1046.333130,348.425201 1045.221558,347.098785 1041.760010,347.323761
	C1038.457886,347.538361 1035.129150,347.306763 1031.814941,347.386597
	C1027.776855,347.483856 1027.750000,347.580414 1028.843506,352.084290
	C1030.471924,352.226074 1032.100220,352.367859 1034.188232,352.549683
	C1034.188232,359.408569 1034.188232,366.129120 1034.188232,373.149078
	C1036.247925,372.998779 1037.809937,372.884766 1039.629883,372.751953
	C1039.629883,368.736847 1039.629883,365.106415 1039.630005,360.491425
M575.378845,349.504456
	C574.966370,354.339600 579.743591,350.897156 581.477295,353.836823
	C581.477295,359.615692 581.477295,366.201050 581.477295,373.107971
	C583.521057,372.992798 585.087830,372.904510 586.992310,372.797211
	C586.992310,365.830261 586.992310,359.242126 586.992310,352.506683
	C589.097961,352.322083 590.857361,352.167847 592.920410,351.987000
	C592.805176,350.351044 592.707886,348.969910 592.595154,347.369904
	C589.408081,347.369904 586.581848,347.196716 583.786926,347.418518
	C580.985291,347.640869 578.215027,348.257355 575.378845,349.504456
M690.621582,365.395386
	C690.621582,359.495331 690.621582,353.595276 690.621582,347.251007
	C688.304260,347.486816 686.762024,347.643768 685.307495,347.791779
	C685.307495,356.410919 685.307495,364.488251 685.307495,372.808655
	C689.737915,372.808655 693.955261,372.808655 698.562439,372.808655
	C698.464783,371.195831 698.381165,369.815674 698.339539,369.127716
	C695.368347,368.061005 693.002869,367.211761 690.621582,365.395386
z"/>
        <path fill={secondary} opacity="1.000000" stroke="none"
            d="
M320.164001,127.834763
	C336.188782,142.834976 347.801849,160.034363 352.157318,181.580872
	C355.474304,197.990067 354.240509,214.318466 351.632751,230.653122
	C349.435974,244.413330 347.464874,258.212769 345.010468,271.926239
	C344.430145,275.168579 342.025116,278.040039 340.956482,281.247742
	C338.040405,290.000671 335.347626,298.829102 332.643799,307.651367
	C328.477997,321.243561 319.283325,324.015625 307.763489,317.956360
	C301.324799,314.569611 295.904938,308.445374 291.285126,302.565399
	C281.250305,289.793365 270.979645,277.224609 256.665314,269.255798
	C249.120621,265.055664 240.539902,262.207184 232.078857,260.264862
	C212.377029,255.742142 192.325043,252.713684 172.685654,247.961075
	C159.426788,244.752502 147.245651,238.443253 136.919876,229.182892
	C134.526886,227.036804 132.458435,222.440903 130.342453,222.501938
	C125.724121,222.635117 120.702835,224.255875 116.684486,226.666336
	C106.911034,232.529129 96.971970,238.432373 88.339920,245.777039
	C81.920433,251.239136 77.487724,259.020325 72.086189,265.700745
	C68.850861,269.702057 65.887405,274.081543 62.021255,277.374756
	C57.070419,281.591949 51.240261,284.455627 44.334057,284.554962
	C34.605415,284.694824 26.890079,281.219604 20.110758,274.044647
	C12.389876,265.873199 10.104626,255.794327 8.222584,245.522018
	C3.760858,221.169601 2.201190,196.539398 5.257419,171.974808
	C7.773622,151.750702 12.007770,131.671539 19.980904,112.786858
	C25.797924,99.009018 36.665501,89.304695 49.500370,82.028572
	C69.326813,70.788887 90.566811,63.051037 113.100029,59.826599
	C126.191208,57.953297 139.643036,58.719044 152.805359,57.196995
	C173.867401,54.761456 193.035019,61.451271 212.499252,67.621674
	C223.102219,70.982941 231.466583,77.130234 238.389893,85.447479
	C242.483551,90.365349 245.928802,95.818542 249.905014,100.841499
	C250.988129,102.209763 252.834930,103.502235 254.508453,103.786583
	C263.796661,105.364746 273.527863,105.416969 282.373077,108.269051
	C295.794189,112.596611 310.665466,114.869896 320.164001,127.834763
M256.963379,120.471809
	C256.852234,120.768791 256.741058,121.065773 256.592743,122.224014
	C256.949707,128.335373 258.591797,134.702591 257.435028,140.512573
	C253.066635,162.452698 239.672104,178.549561 221.407913,190.469406
	C206.016968,200.514053 188.724335,206.650269 171.221130,212.071701
	C165.236404,213.925400 159.320328,216.000641 152.066071,218.408401
	C162.973938,224.555954 172.973953,229.568298 184.094788,231.890717
	C191.877457,233.515976 199.761795,234.647751 207.575363,236.133347
	C219.134201,238.331039 230.658997,240.707764 242.218796,242.900162
	C265.788116,247.370224 283.561584,261.062622 298.974854,278.491791
	C303.460510,283.564117 307.737549,288.820862 313.335968,295.442932
	C319.070770,275.854828 326.334076,257.944550 329.173706,239.358353
	C331.879517,221.648331 330.578247,203.209747 329.880127,185.136215
	C329.400970,172.732117 325.169525,160.655579 317.849518,150.740906
	C302.978210,130.598312 282.261414,120.936394 256.963379,120.471809
M52.471157,101.910088
	C51.078934,103.554619 49.563335,105.111023 48.315182,106.858459
	C41.856899,115.900200 37.550076,125.744270 35.709805,136.856903
	C33.572491,149.763275 29.816208,162.424713 28.081680,175.369232
	C25.369579,195.609238 26.980804,215.932037 30.188576,236.007339
	C31.532637,244.418900 34.109482,252.808472 37.390068,260.681244
	C40.104145,267.194489 44.342705,267.179626 48.908703,261.545868
	C54.749111,254.339676 60.303421,246.902252 66.090492,239.651855
	C68.470810,236.669647 70.807343,233.512680 73.727821,231.125153
	C87.825699,219.600037 101.777527,207.759842 119.698769,202.932678
	C118.716393,198.538818 117.138504,194.397858 116.974205,190.201523
	C116.474937,177.449753 119.971519,165.759949 126.812805,154.858261
	C139.530106,134.593079 158.373260,122.124634 180.106979,114.314590
	C194.963196,108.975990 210.769196,106.280457 226.340332,102.355446
	C221.702240,97.220673 218.665863,88.923882 210.843018,85.751350
	C200.466537,81.543213 189.839111,76.991249 178.902893,75.330360
	C157.438904,72.070618 135.715744,74.125648 114.226982,76.453903
	C91.575676,78.908119 71.843895,89.207794 52.471157,101.910088
M205.678864,175.297287
	C226.803787,162.219299 238.180679,139.395569 232.311661,121.791298
	C231.172150,121.791298 230.012497,121.722191 228.863113,121.801620
	C209.867401,123.114357 191.838120,128.309235 174.798752,136.507141
	C155.997025,145.552948 144.422089,160.908798 139.507477,181.059967
	C138.072144,186.945206 137.795166,193.210068 141.345886,198.859711
	C163.506546,193.235107 185.152405,186.862976 205.678864,175.297287
z"/>
        <path fill={primary} opacity="1.000000" stroke="none"
            d="
M1086.702148,201.625687
	C1080.710571,216.460068 1073.420044,230.040314 1061.670166,240.677719
	C1035.051025,264.776398 1002.023071,261.211975 977.327209,237.857071
	C957.808960,219.398590 947.555420,195.607574 941.804993,169.879272
	C934.660217,137.912277 935.282410,106.051491 945.971191,74.845551
	C950.742615,60.915253 957.709961,48.045605 968.002014,37.250877
	C992.194519,11.876785 1025.273438,9.967423 1052.072632,32.525871
	C1073.898071,50.897766 1085.657715,75.349930 1091.826782,102.405815
	C1099.405396,135.643402 1098.467041,168.814789 1086.702148,201.625687
M1021.247620,45.984131
	C1013.223389,42.998089 1005.581543,43.942890 998.244751,48.188663
	C985.261902,55.701794 977.953796,67.688347 973.088074,81.227074
	C961.386780,113.785500 961.988281,146.501236 973.088501,179.088791
	C978.179382,194.034409 985.151001,208.000092 997.205627,218.697067
	C1014.306885,233.872314 1031.478149,233.267441 1046.450928,216.109482
	C1052.016113,209.732239 1056.879883,202.014114 1059.671387,194.067184
	C1074.581055,151.621902 1071.338135,110.260292 1049.378662,70.885834
	C1043.232666,59.865765 1033.983398,51.273098 1021.247620,45.984131
z"/>
        <path fill={primary} opacity="1.000000" stroke="none"
            d="
M815.799072,256.208801
	C792.892639,252.437592 777.128174,238.952911 765.204102,220.606583
	C746.495361,191.821579 739.013306,159.879944 739.710571,125.742905
	C740.242554,99.696739 745.164917,74.925850 758.888794,52.336048
	C767.705750,37.823101 779.135803,26.174917 795.266479,20.332870
	C819.675354,11.492761 840.500061,19.498886 858.420166,36.222904
	C878.994141,55.423565 889.835632,80.171135 895.148499,107.274506
	C901.370911,139.018066 900.451904,170.586090 888.985107,201.225693
	C881.166565,222.116898 869.763428,240.510269 848.981750,250.905380
	C838.781067,256.007843 827.898865,259.162628 815.799072,256.208801
M834.439331,227.177872
	C838.257019,224.566437 842.397949,222.319122 845.829895,219.273102
	C854.924377,211.201324 860.350098,200.637909 863.905029,189.277618
	C875.311462,152.827103 872.664246,117.020782 857.811707,82.041664
	C852.786499,70.206818 845.119080,60.199856 834.897583,52.287228
	C820.527588,41.163166 806.536621,41.602776 792.914246,53.663456
	C784.878723,60.777668 779.603210,69.831924 775.914490,79.736771
	C763.631531,112.718948 764.117615,145.890976 775.304565,179.008545
	C780.133606,193.304474 786.630859,206.900055 797.964600,217.243668
	C808.002441,226.404633 819.322998,232.832581 834.439331,227.177872
z"/>
        <path fill={primary} opacity="1.000000" stroke="none"
            d="
M486.621155,240.924866
	C494.072479,234.591751 495.627533,226.824524 493.383057,218.091980
	C490.472778,206.768906 484.471863,196.980316 477.398529,187.888947
	C465.846100,173.040634 453.774323,158.593597 442.335327,143.660385
	C417.414978,111.127617 413.675842,59.304478 458.758667,25.908731
	C484.523102,6.823342 522.423767,13.627905 538.134399,39.703560
	C544.855042,50.858124 545.696777,62.981873 543.656799,75.422287
	C542.645569,81.589165 536.304443,86.201172 530.236877,85.978691
	C523.961182,85.748573 518.719666,80.900261 517.913086,74.343391
	C517.486938,70.878235 517.505310,67.360260 517.137939,63.885693
	C515.511169,48.498989 502.476349,39.595123 487.439056,43.540627
	C464.405457,49.584198 448.554504,74.629745 452.385742,98.579445
	C454.024261,108.822296 457.690765,118.199928 463.979492,126.342934
	C475.373474,141.096436 487.472687,155.315826 498.589142,170.269882
	C507.998108,182.927002 516.670837,196.099747 520.599854,211.848221
	C528.066162,241.775360 510.575867,263.480652 489.132355,269.912964
	C468.775208,276.019409 445.622162,263.284302 439.060364,242.122589
	C437.838715,238.182861 437.036194,234.021362 436.727325,229.910599
	C436.180328,222.630569 440.689667,217.159546 447.891388,215.609100
	C453.586029,214.383118 459.285980,217.801315 461.942413,224.186249
	C463.088806,226.941711 463.959015,229.811127 464.988922,232.616211
	C468.779877,242.941223 476.449493,246.002991 486.621155,240.924866
z"/>
        <path fill={primary} opacity="1.000000" stroke="none"
            d="
M1164.001465,37.172535
	C1159.002441,37.116032 1154.501465,37.122353 1150.004517,36.989849
	C1141.079590,36.726864 1135.008789,32.059589 1134.562988,25.214020
	C1134.078003,17.769258 1139.680786,10.801681 1148.297729,10.211946
	C1169.394043,8.768132 1190.582397,7.191198 1211.613281,10.863008
	C1226.184692,13.407052 1232.592651,22.362736 1229.176270,36.623913
	C1220.311279,73.629700 1211.232666,110.594162 1201.493774,147.377716
	C1193.246094,178.528641 1183.965820,209.406387 1175.118652,240.398239
	C1173.042603,247.670670 1173.244141,247.814514 1180.703979,248.256775
	C1188.665405,248.728790 1196.706421,249.006775 1204.525635,250.410522
	C1212.568359,251.854355 1216.522095,257.741760 1215.744507,264.992554
	C1215.030273,271.651306 1209.631958,276.550873 1201.763062,276.718414
	C1188.438354,277.002136 1175.090942,277.178253 1161.776245,276.722900
	C1147.841675,276.246307 1139.403809,265.605957 1142.945068,252.293457
	C1148.066040,233.043442 1154.620605,214.176910 1159.833740,194.948914
	C1172.964478,146.517471 1185.909424,98.032593 1198.411011,49.435928
	C1201.418579,37.744946 1201.013428,36.804043 1188.992676,36.989830
	C1180.829956,37.115990 1172.665161,37.116096 1164.001465,37.172535
z"/>
        <path fill={primary} opacity="1.000000" stroke="none"
            d="
M630.924194,188.999939
	C631.073364,141.188232 631.232910,93.876572 631.360352,46.564831
	C631.392212,34.746517 631.361572,34.989536 619.745850,35.801998
	C611.803711,36.357510 603.789185,36.485344 595.838013,36.123486
	C588.753174,35.801052 583.940002,30.298483 583.611511,23.359598
	C583.294250,16.657215 586.948792,11.257530 593.970337,10.296328
	C605.000061,8.786442 616.147217,8.061567 627.263977,7.278088
	C644.209106,6.083836 661.165100,5.010799 678.129517,4.142331
	C682.401978,3.923610 686.780701,4.344906 691.010315,5.062764
	C697.300842,6.130396 701.183594,10.754163 701.805115,17.084185
	C702.324768,22.376410 699.449585,27.432974 693.707520,29.513910
	C689.726746,30.956562 685.343994,31.435429 681.093018,31.951164
	C675.493225,32.630550 669.827698,32.766384 664.226624,33.437958
	C660.428406,33.893368 658.397705,36.272400 658.381470,40.248234
	C658.232056,76.898827 658.013367,113.549210 657.915588,150.199921
	C657.834229,180.691025 657.908997,211.182541 657.852417,241.673737
	C657.846252,244.993423 657.667603,248.350403 657.148987,251.623535
	C656.133606,258.031433 651.440063,262.491364 645.636292,262.963989
	C639.913391,263.430054 633.777771,259.294647 631.998840,253.341110
	C631.202942,250.677216 630.897705,247.777206 630.887634,244.982468
	C630.820801,226.488586 630.894714,207.994171 630.924194,188.999939
z"/>
        <path fill={primary} opacity="1.000000" stroke="none"
            d="
M1268.827515,63.999943
	C1268.877319,51.172417 1268.817871,38.842831 1269.050537,26.518759
	C1269.112305,23.244486 1269.968750,19.965914 1270.640259,16.726599
	C1271.903198,10.634729 1276.041382,7.800382 1281.956787,7.317026
	C1287.534302,6.861281 1292.083374,9.316398 1294.111572,14.245444
	C1295.884033,18.552643 1296.834351,23.516636 1296.824951,28.187828
	C1296.754028,63.664604 1295.750488,99.149017 1296.233521,134.614761
	C1296.714355,169.912476 1298.618042,205.190186 1299.799316,240.479950
	C1299.915161,243.939484 1299.604370,247.466049 1299.032715,250.884781
	C1297.858276,257.909821 1294.287964,261.413635 1288.218140,262.025116
	C1281.044922,262.747772 1276.838379,260.359894 1274.348877,253.465714
	C1273.123169,250.070999 1272.185303,246.386688 1272.073486,242.803604
	C1270.727661,199.683456 1269.395264,156.561401 1268.432739,113.431511
	C1268.069092,97.132042 1268.667603,80.811096 1268.827515,63.999943
z"/>




    </svg>

}

export function LogoBubbles({primary="oklch(var(--bc))", secondary="oklch(var(--s))"}) {
    return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="100%" viewBox="0 0 378 378" enableBackground="new 0 0 378 378" >
        <path opacity="0" stroke="none"
            d="
M1022.000000,379.000000
	C681.333374,379.000000 341.166748,379.000000 1.000091,379.000000
	C1.000061,253.000061 1.000061,127.000114 1.000030,1.000125
	C438.666534,1.000083 876.333069,1.000083 1313.999756,1.000042
	C1313.999756,126.999878 1313.999756,252.999756 1313.999878,378.999817
	C1216.833374,379.000000 1119.666626,379.000000 1022.000000,379.000000
M319.913818,127.584412
	C310.665466,114.869896 295.794189,112.596611 282.373077,108.269051
	C273.527863,105.416969 263.796661,105.364746 254.508453,103.786583
	C252.834930,103.502235 250.988129,102.209763 249.905014,100.841499
	C245.928802,95.818542 242.483551,90.365349 238.389893,85.447479
	C231.466583,77.130234 223.102219,70.982941 212.499252,67.621674
	C193.035019,61.451271 173.867401,54.761456 152.805359,57.196995
	C139.643036,58.719044 126.191208,57.953297 113.100029,59.826599
	C90.566811,63.051037 69.326813,70.788887 49.500370,82.028572
	C36.665501,89.304695 25.797924,99.009018 19.980904,112.786858
	C12.007770,131.671539 7.773622,151.750702 5.257419,171.974808
	C2.201190,196.539398 3.760858,221.169601 8.222584,245.522018
	C10.104626,255.794327 12.389876,265.873199 20.110758,274.044647
	C26.890079,281.219604 34.605415,284.694824 44.334057,284.554962
	C51.240261,284.455627 57.070419,281.591949 62.021255,277.374756
	C65.887405,274.081543 68.850861,269.702057 72.086189,265.700745
	C77.487724,259.020325 81.920433,251.239136 88.339920,245.777039
	C96.971970,238.432373 106.911034,232.529129 116.684486,226.666336
	C120.702835,224.255875 125.724121,222.635117 130.342453,222.501938
	C132.458435,222.440903 134.526886,227.036804 136.919876,229.182892
	C147.245651,238.443253 159.426788,244.752502 172.685654,247.961075
	C192.325043,252.713684 212.377029,255.742142 232.078857,260.264862
	C240.539902,262.207184 249.120621,265.055664 256.665314,269.255798
	C270.979645,277.224609 281.250305,289.793365 291.285126,302.565399
	C295.904938,308.445374 301.324799,314.569611 307.763489,317.956360
	C319.283325,324.015625 328.477997,321.243561 332.643799,307.651367
	C335.347626,298.829102 338.040405,290.000671 340.956482,281.247742
	C342.025116,278.040039 344.430145,275.168579 345.010468,271.926239
	C347.464874,258.212769 349.435974,244.413330 351.632751,230.653122
	C354.240509,214.318466 355.474304,197.990067 352.157318,181.580872
	C347.801849,160.034363 336.188782,142.834976 319.913818,127.584412
M1086.850098,201.265640
	C1098.467041,168.814789 1099.405396,135.643402 1091.826782,102.405815
	C1085.657715,75.349930 1073.898071,50.897766 1052.072632,32.525871
	C1025.273438,9.967423 992.194519,11.876785 968.002014,37.250877
	C957.709961,48.045605 950.742615,60.915253 945.971191,74.845551
	C935.282410,106.051491 934.660217,137.912277 941.804993,169.879272
	C947.555420,195.607574 957.808960,219.398590 977.327209,237.857071
	C1002.023071,261.211975 1035.051025,264.776398 1061.670166,240.677719
	C1073.420044,230.040314 1080.710571,216.460068 1086.850098,201.265640
M816.213379,256.286926
	C827.898865,259.162628 838.781067,256.007843 848.981750,250.905380
	C869.763428,240.510269 881.166565,222.116898 888.985107,201.225693
	C900.451904,170.586090 901.370911,139.018066 895.148499,107.274506
	C889.835632,80.171135 878.994141,55.423565 858.420166,36.222904
	C840.500061,19.498886 819.675354,11.492761 795.266479,20.332870
	C779.135803,26.174917 767.705750,37.823101 758.888794,52.336048
	C745.164917,74.925850 740.242554,99.696739 739.710571,125.742905
	C739.013306,159.879944 746.495361,191.821579 765.204102,220.606583
	C777.128174,238.952911 792.892639,252.437592 816.213379,256.286926
M486.302063,241.138718
	C476.449493,246.002991 468.779877,242.941223 464.988922,232.616211
	C463.959015,229.811127 463.088806,226.941711 461.942413,224.186249
	C459.285980,217.801315 453.586029,214.383118 447.891388,215.609100
	C440.689667,217.159546 436.180328,222.630569 436.727325,229.910599
	C437.036194,234.021362 437.838715,238.182861 439.060364,242.122589
	C445.622162,263.284302 468.775208,276.019409 489.132355,269.912964
	C510.575867,263.480652 528.066162,241.775360 520.599854,211.848221
	C516.670837,196.099747 507.998108,182.927002 498.589142,170.269882
	C487.472687,155.315826 475.373474,141.096436 463.979492,126.342934
	C457.690765,118.199928 454.024261,108.822296 452.385742,98.579445
	C448.554504,74.629745 464.405457,49.584198 487.439056,43.540627
	C502.476349,39.595123 515.511169,48.498989 517.137939,63.885693
	C517.505310,67.360260 517.486938,70.878235 517.913086,74.343391
	C518.719666,80.900261 523.961182,85.748573 530.236877,85.978691
	C536.304443,86.201172 542.645569,81.589165 543.656799,75.422287
	C545.696777,62.981873 544.855042,50.858124 538.134399,39.703560
	C522.423767,13.627905 484.523102,6.823342 458.758667,25.908731
	C413.675842,59.304478 417.414978,111.127617 442.335327,143.660385
	C453.774323,158.593597 465.846100,173.040634 477.398529,187.888947
	C484.471863,196.980316 490.472778,206.768906 493.383057,218.091980
	C495.627533,226.824524 494.072479,234.591751 486.302063,241.138718
M1164.501343,37.172581
	C1172.665161,37.116096 1180.829956,37.115990 1188.992676,36.989830
	C1201.013428,36.804043 1201.418579,37.744946 1198.411011,49.435928
	C1185.909424,98.032593 1172.964478,146.517471 1159.833740,194.948914
	C1154.620605,214.176910 1148.066040,233.043442 1142.945068,252.293457
	C1139.403809,265.605957 1147.841675,276.246307 1161.776245,276.722900
	C1175.090942,277.178253 1188.438354,277.002136 1201.763062,276.718414
	C1209.631958,276.550873 1215.030273,271.651306 1215.744507,264.992554
	C1216.522095,257.741760 1212.568359,251.854355 1204.525635,250.410522
	C1196.706421,249.006775 1188.665405,248.728790 1180.703979,248.256775
	C1173.244141,247.814514 1173.042603,247.670670 1175.118652,240.398239
	C1183.965820,209.406387 1193.246094,178.528641 1201.493774,147.377716
	C1211.232666,110.594162 1220.311279,73.629700 1229.176270,36.623913
	C1232.592651,22.362736 1226.184692,13.407052 1211.613281,10.863008
	C1190.582397,7.191198 1169.394043,8.768132 1148.297729,10.211946
	C1139.680786,10.801681 1134.078003,17.769258 1134.562988,25.214020
	C1135.008789,32.059589 1141.079590,36.726864 1150.004517,36.989849
	C1154.501465,37.122353 1159.002441,37.116032 1164.501343,37.172581
M630.922729,189.499939
	C630.894714,207.994171 630.820801,226.488586 630.887634,244.982468
	C630.897705,247.777206 631.202942,250.677216 631.998840,253.341110
	C633.777771,259.294647 639.913391,263.430054 645.636292,262.963989
	C651.440063,262.491364 656.133606,258.031433 657.148987,251.623535
	C657.667603,248.350403 657.846252,244.993423 657.852417,241.673737
	C657.908997,211.182541 657.834229,180.691025 657.915588,150.199921
	C658.013367,113.549210 658.232056,76.898827 658.381470,40.248234
	C658.397705,36.272400 660.428406,33.893368 664.226624,33.437958
	C669.827698,32.766384 675.493225,32.630550 681.093018,31.951164
	C685.343994,31.435429 689.726746,30.956562 693.707520,29.513910
	C699.449585,27.432974 702.324768,22.376410 701.805115,17.084185
	C701.183594,10.754163 697.300842,6.130396 691.010315,5.062764
	C686.780701,4.344906 682.401978,3.923610 678.129517,4.142331
	C661.165100,5.010799 644.209106,6.083836 627.263977,7.278088
	C616.147217,8.061567 605.000061,8.786442 593.970337,10.296328
	C586.948792,11.257530 583.294250,16.657215 583.611511,23.359598
	C583.940002,30.298483 588.753174,35.801052 595.838013,36.123486
	C603.789185,36.485344 611.803711,36.357510 619.745850,35.801998
	C631.361572,34.989536 631.392212,34.746517 631.360352,46.564831
	C631.232910,93.876572 631.073364,141.188232 630.922729,189.499939
M1268.827515,64.499947
	C1268.667603,80.811096 1268.069092,97.132042 1268.432739,113.431511
	C1269.395264,156.561401 1270.727661,199.683456 1272.073486,242.803604
	C1272.185303,246.386688 1273.123169,250.070999 1274.348877,253.465714
	C1276.838379,260.359894 1281.044922,262.747772 1288.218140,262.025116
	C1294.287964,261.413635 1297.858276,257.909821 1299.032715,250.884781
	C1299.604370,247.466049 1299.915161,243.939484 1299.799316,240.479950
	C1298.618042,205.190186 1296.714355,169.912476 1296.233521,134.614761
	C1295.750488,99.149017 1296.754028,63.664604 1296.824951,28.187828
	C1296.834351,23.516636 1295.884033,18.552643 1294.111572,14.245444
	C1292.083374,9.316398 1287.534302,6.861281 1281.956787,7.317026
	C1276.041382,7.800382 1271.903198,10.634729 1270.640259,16.726599
	C1269.968750,19.965914 1269.112305,23.244486 1269.050537,26.518759
	C1268.817871,38.842831 1268.877319,51.172417 1268.827515,64.499947
M947.936584,361.342499
	C946.908569,358.392548 945.563232,355.509827 944.921143,352.478149
	C943.725952,346.835419 940.268372,346.664551 935.578857,348.000305
	C934.912354,356.354858 934.257568,364.563141 933.574524,373.125305
	C935.676575,372.975922 937.255798,372.863708 938.757385,372.756989
	C939.314087,367.533203 939.824341,362.745148 940.334595,357.957092
	C942.974609,361.819000 944.283325,365.818939 945.889832,369.695465
	C946.418335,370.970612 947.717651,371.926331 948.664429,373.028107
	C949.592712,371.884644 950.876953,370.879395 951.384338,369.572479
	C953.096619,365.162018 954.532959,360.644501 956.074829,356.167908
	C956.321899,356.271240 956.568970,356.374603 956.816101,356.477936
	C957.334290,361.897400 957.852478,367.316864 958.401001,373.053925
	C960.199341,372.954834 961.754150,372.869171 963.541565,372.770691
	C962.970398,365.258270 962.557434,358.145782 961.776367,351.074005
	C961.632812,349.773865 960.121582,347.771057 959.022217,347.612274
	C956.894653,347.304962 954.102722,346.262604 953.032410,350.030334
	C951.871033,354.118591 949.988647,358.002075 947.936584,361.342499
M759.590637,360.496765
	C759.590637,356.240601 759.590637,351.984467 759.590637,347.319519
	C757.441101,347.522217 756.050659,347.653320 754.090820,347.838165
	C754.090820,353.182678 754.090820,358.164368 754.090820,363.146057
	C750.571289,359.322479 747.698669,355.468994 745.511902,351.259186
	C743.504211,347.394226 740.904602,346.603729 737.226807,347.880798
	C737.226807,356.396545 737.226807,364.667908 737.226807,372.867523
	C738.974365,372.867523 740.452087,372.867523 742.373779,372.867523
	C742.373779,367.468079 742.373779,362.236145 742.373779,357.004211
	C745.925476,360.811401 748.674500,364.856049 751.065308,369.102295
	C753.015198,372.565399 755.372375,374.228485 759.590576,372.380066
	C759.590576,368.778259 759.590576,365.129150 759.590637,360.496765
M793.680359,359.999695
	C789.867371,359.610138 786.054443,359.220612 781.860657,358.792145
	C782.054199,360.919189 782.166077,362.148529 782.275818,363.354187
	C784.451660,363.559357 786.223877,363.726471 788.080017,363.901489
	C786.098877,368.413055 782.028564,369.869568 778.023987,367.711121
	C774.615356,365.873932 773.015442,359.999176 774.902710,356.249939
	C777.003235,352.077026 782.008972,350.430725 785.823425,353.254852
	C788.458618,355.205841 790.233582,355.730988 792.231506,352.895782
	C787.818054,346.788757 779.889832,345.387299 773.843994,349.540131
	C768.505859,353.206879 767.327026,361.952759 770.910095,367.889557
	C774.080444,373.142517 783.179077,375.130981 788.343506,371.177399
	C790.173645,371.946259 791.744080,372.605957 793.864319,373.496643
	C793.864319,368.528595 793.864319,364.717804 793.680359,359.999695
M867.756714,349.045288
	C861.388550,353.995544 859.847412,361.719604 863.996460,367.891815
	C867.877441,373.665436 875.206116,374.854218 881.218445,371.224548
	C883.204651,371.996399 884.788208,372.611786 886.565796,373.302551
	C886.565796,368.025208 886.565796,363.775116 886.565796,359.325806
	C882.649475,359.325806 879.053223,359.325806 875.023254,359.325806
	C875.123596,360.808167 875.212891,362.126862 875.296631,363.363525
	C877.567078,363.572113 879.330872,363.734161 881.214355,363.907227
	C878.922791,368.486908 874.858704,369.873993 870.947388,367.662506
	C867.330688,365.617554 866.193237,359.203400 868.736389,355.194397
	C870.924133,351.745514 875.857544,350.565216 879.089111,353.381989
	C881.622498,355.590271 883.236450,355.262329 885.242371,353.217224
	C881.611389,347.258942 876.581421,345.951813 867.756714,349.045288
M613.109070,357.111694
	C612.421509,362.883026 613.400452,368.187531 618.719421,371.395081
	C623.248718,374.126373 628.263794,373.840393 632.858154,371.404419
	C637.653320,368.861969 639.691528,362.815186 638.287354,356.754974
	C637.028809,351.322937 632.486633,347.630219 626.504822,347.175934
	C620.602722,346.727692 615.926697,349.993378 613.109070,357.111694
M470.901886,348.271851
	C469.305267,348.009064 467.708649,347.746246 465.770996,347.427277
	C465.770996,351.370819 465.770996,354.505981 465.770996,357.720551
	C462.093140,357.720551 458.681000,357.720551 454.859070,357.720551
	C454.859070,354.213135 454.859070,350.961578 454.859070,347.460999
	C453.005829,347.548767 451.582703,347.616150 450.021240,347.690063
	C450.021240,356.239624 450.021240,364.483887 450.021240,373.048645
	C451.796692,372.946320 453.338867,372.857422 455.301575,372.744293
	C455.301575,369.113464 455.301575,365.886017 455.301575,362.857666
	C459.034210,362.857666 462.308197,362.857666 465.812317,362.857666
	C465.812317,366.323975 465.812317,369.472168 465.812317,372.776917
	C467.613861,372.776917 469.174713,372.776917 471.007568,372.776917
	C471.007568,364.740417 471.007568,356.937866 470.901886,348.271851
M1093.741577,366.704285
	C1092.652832,365.065002 1091.564209,363.425751 1090.319946,361.552246
	C1094.629028,360.025879 1095.942383,357.138885 1095.139771,353.188416
	C1094.340576,349.254028 1091.434448,347.874298 1087.888672,347.642883
	C1084.790405,347.440704 1081.668335,347.600708 1078.369751,347.600708
	C1078.369751,356.289276 1078.369751,364.526550 1078.369751,373.091217
	C1080.208252,372.972076 1081.769165,372.870911 1083.778564,372.740692
	C1083.778564,368.997253 1083.778564,365.619507 1083.778564,361.963898
	C1089.288330,364.701599 1087.888550,375.161072 1097.434448,372.575623
	C1096.237427,370.667725 1095.195679,369.007385 1093.741577,366.704285
M564.451233,364.848938
	C563.856506,363.870331 563.261841,362.891754 562.667175,361.913147
	C563.123108,362.037628 563.579102,362.162140 564.035095,362.286621
	C565.158142,359.549774 567.143372,356.804718 567.117310,354.078857
	C567.098206,352.085144 564.768860,348.910736 562.880615,348.387604
	C558.922424,347.291046 554.576172,347.595703 550.322266,347.320679
	C550.322266,356.415924 550.322266,364.518524 550.322266,372.781921
	C552.094055,372.781921 553.656006,372.781921 555.881592,372.781921
	C555.881592,369.068390 555.881592,365.599518 555.881592,361.826477
	C561.417175,364.834167 560.043091,374.886810 569.623352,372.666840
	C567.921570,370.090485 566.394653,367.778931 564.451233,364.848938
M818.885864,347.456573
	C818.395142,348.532959 817.535278,349.591217 817.481323,350.689087
	C817.302246,354.334290 817.373718,357.994293 817.424316,361.647583
	C817.471619,365.059937 817.041565,368.335144 812.710571,368.319977
	C808.200500,368.304199 808.295959,364.667328 808.244568,361.491913
	C808.170166,356.893646 808.225586,352.293304 808.225586,347.644745
	C806.256042,347.644745 804.823914,347.644745 803.007080,347.644745
	C803.007080,353.471466 802.783081,359.091309 803.069031,364.685089
	C803.364502,370.465424 806.785095,373.091248 813.154907,373.040283
	C819.345093,372.990753 822.607788,370.164398 822.845215,364.281769
	C823.032593,359.638794 822.750427,354.976837 822.937805,350.333862
	C823.041443,347.765869 821.948792,347.106445 818.885864,347.456573
M836.226318,371.943604
	C836.870911,369.665741 837.441162,367.138702 840.674622,367.914917
	C843.626526,368.623535 847.608887,365.631592 849.515015,370.705322
	C850.032349,372.082214 852.758789,372.629150 854.471375,373.557007
	C852.506348,364.788025 849.895935,357.332031 846.915649,350.026825
	C846.424072,348.821838 844.363525,347.730103 842.932312,347.594238
	C841.683716,347.475769 839.517883,348.465393 839.101257,349.504364
	C836.051147,357.110657 833.339722,364.852722 830.309326,373.131561
	C832.595764,372.955872 834.212708,372.831604 836.226318,371.943604
M975.737488,359.075256
	C974.073303,363.550903 972.409119,368.026550 970.709900,372.596405
	C973.987793,373.511658 976.147766,373.401886 977.468140,369.661469
	C977.872131,368.516876 981.291199,368.035217 983.345093,367.992126
	C985.235535,367.952484 988.560120,368.543030 988.806458,369.491364
	C989.925110,373.798523 992.577576,373.324493 995.717102,372.663269
	C992.888611,365.159058 990.202271,357.711273 987.206299,350.390198
	C986.689941,349.128296 984.850403,347.619904 983.636047,347.632050
	C982.136108,347.646942 980.042786,348.774750 979.288269,350.058136
	C977.801758,352.586273 977.061462,355.553192 975.737488,359.075256
M524.728088,352.119507
	C522.264771,358.895538 519.801392,365.671600 517.351135,372.411469
	C522.386353,374.656006 523.365540,371.152496 524.305786,367.875336
	C527.114868,367.875336 529.622009,367.684875 532.078918,367.953766
	C533.354492,368.093323 535.466492,368.871704 535.613831,369.658569
	C536.408386,373.902893 539.046448,373.266052 542.166016,372.639618
	C539.513672,365.500214 536.877258,358.578339 534.389099,351.603607
	C533.600708,349.393829 533.357910,347.360474 530.043640,347.293213
	C526.771484,347.226898 525.677673,348.793274 524.728088,352.119507
M1019.100403,360.358124
	C1016.812744,359.402527 1014.457581,358.577820 1012.260742,357.446045
	C1010.761292,356.673553 1009.452576,355.506134 1008.186401,354.372467
	C1008.116150,354.309509 1009.224792,352.288940 1009.770142,352.296051
	C1011.613159,352.320160 1013.467773,352.771240 1015.283447,353.203949
	C1017.125061,353.642792 1018.925598,354.253510 1021.772766,355.091858
	C1018.736755,348.215881 1013.584778,345.628021 1008.278381,348.011322
	C1006.009705,349.030273 1003.963806,351.779144 1003.020264,354.209259
	C1001.774536,357.417847 1004.491150,359.331970 1007.134827,360.583496
	C1009.373230,361.643219 1011.857849,362.211243 1014.021606,363.386810
	C1014.933899,363.882446 1016.054565,365.777740 1015.758423,366.337830
	C1015.169128,367.452179 1013.665161,368.746277 1012.537781,368.747528
	C1011.073792,368.749176 1009.017334,367.956482 1008.256653,366.813232
	C1006.139160,363.630920 1004.185913,365.203827 1001.416260,366.473022
	C1002.840881,368.272461 1003.831421,370.023254 1005.272034,371.242126
	C1008.548706,374.014496 1016.299438,373.842590 1019.214111,371.003967
	C1022.471130,367.831909 1022.588379,364.377472 1019.100403,360.358124
M708.309265,360.692841
	C706.911682,364.685028 705.514038,368.677185 704.154907,372.559448
	C709.184326,374.834686 710.073730,370.760590 711.443420,368.022339
	C721.623718,367.631348 721.623718,367.631348 723.613586,372.676544
	C725.325989,372.676544 727.004333,372.676544 728.989197,372.676544
	C725.756653,363.974274 722.678650,355.687988 719.764221,347.842010
	C714.778015,346.399536 712.367249,348.159241 711.351807,352.536377
	C710.756653,355.101898 709.631104,357.544342 708.309265,360.692841
M902.049683,366.383850
	C902.168030,365.154236 902.286438,363.924591 902.424622,362.488953
	C905.092346,362.488953 907.380310,362.488953 909.938171,362.488953
	C909.938171,360.854095 909.938171,359.469360 909.938171,357.780304
	C907.217224,357.780304 904.784790,357.780304 902.025574,357.780304
	C902.114807,355.763184 902.189819,354.067596 902.279358,352.043518
	C905.483398,352.043518 908.249084,352.043518 911.338196,352.043518
	C911.152161,350.256989 911.006592,348.858948 910.894409,347.781219
	C905.865967,347.781219 901.291199,347.781219 896.744934,347.781219
	C896.744934,356.382812 896.744934,364.603973 896.744934,372.833069
	C901.683899,372.833069 906.281250,372.833069 910.963867,372.833069
	C910.963867,371.197754 910.963867,369.776550 910.963867,368.561584
	C907.819092,368.116333 904.962097,367.711823 902.049683,366.383850
M1067.445679,352.170197
	C1067.815674,350.707550 1068.185547,349.244934 1068.593750,347.631348
	C1063.171631,347.631348 1058.727905,347.631348 1054.116577,347.631348
	C1054.116577,356.169250 1054.116577,364.504181 1054.116577,372.769867
	C1059.042969,372.769867 1063.635620,372.769867 1068.608154,372.769867
	C1068.441895,371.084503 1068.304932,369.696960 1068.141602,368.041382
	C1064.975830,368.041382 1062.205078,368.041382 1059.401123,368.041382
	C1059.401123,366.049957 1059.401123,364.377197 1059.401123,362.476685
	C1062.059448,362.476685 1064.351074,362.476685 1066.920166,362.476685
	C1066.920166,360.859314 1066.920166,359.477722 1066.920166,357.797852
	C1064.208984,357.797852 1061.778687,357.797852 1059.346924,357.797852
	C1059.346924,355.778229 1059.346924,354.078949 1059.346924,352.211090
	C1061.981445,352.211090 1064.302612,352.211090 1067.445679,352.170197
M482.704254,372.962921
	C487.165222,372.962921 491.626221,372.962921 496.487122,372.962921
	C496.408325,371.242950 496.345093,369.862823 496.263184,368.075104
	C493.105103,368.075104 490.340271,368.075104 487.373627,368.075104
	C487.309448,366.872833 487.212585,366.078430 487.240448,365.288391
	C487.267426,364.524139 487.422791,363.764374 487.508545,363.104492
	C495.433472,361.917664 495.433472,361.917664 494.995789,357.837616
	C492.476105,357.837616 489.898865,357.837616 487.118896,357.837616
	C487.278229,355.737640 487.399261,354.142548 487.529633,352.424408
	C490.710388,352.277069 493.479309,352.148834 496.216949,352.022003
	C496.452637,347.522614 496.453125,347.487091 492.731049,347.434845
	C489.271240,347.386261 485.810150,347.424469 482.032776,347.424469
	C482.032776,356.024506 482.032776,364.146027 482.704254,372.962921
M647.968506,370.256348
	C647.815247,375.073944 650.999268,372.466370 653.319824,372.911530
	C653.319824,369.399597 653.319824,366.284454 653.319824,363.735870
	C655.581177,360.832550 662.475342,365.600677 661.300842,357.827606
	C658.388000,357.827606 655.925659,357.827606 653.185852,357.827606
	C653.289612,355.903534 653.374939,354.321716 653.475769,352.452209
	C656.697021,352.299622 659.616882,352.161316 662.858276,352.007751
	C662.760803,350.412720 662.676208,349.028198 662.593323,347.671387
	C657.584167,347.671387 652.970886,347.671387 647.968323,347.671387
	C647.968323,355.037567 647.968323,362.168182 647.968506,370.256348
M1107.506226,355.132385
	C1112.590454,360.160797 1110.607666,366.878723 1111.217041,372.882935
	C1113.212524,372.882935 1114.679077,372.882935 1116.931885,372.882935
	C1114.334717,362.886383 1119.531006,355.381348 1124.856201,347.890869
	C1117.433716,345.368958 1117.071167,352.290436 1113.957642,355.495026
	C1110.579468,352.623596 1110.584473,345.236877 1103.219727,347.972534
	C1104.595825,350.281342 1105.854980,352.393707 1107.506226,355.132385
M425.300690,347.533142
	C424.808472,348.909821 424.316284,350.286499 423.721680,351.949524
	C426.229492,352.194183 428.002960,352.367218 430.250671,352.586517
	C430.250671,359.429626 430.250671,366.156128 430.250671,372.893158
	C432.211273,372.893158 433.679413,372.893158 435.687378,372.893158
	C435.687378,366.023987 435.687378,359.299103 435.687378,352.584961
	C437.956177,352.317261 439.692505,352.112366 441.268433,351.926392
	C442.396912,348.122711 440.913605,347.131897 437.617065,347.320862
	C433.810333,347.539062 429.982208,347.383728 425.300690,347.533142
M1039.630005,360.491425
	C1039.630005,357.874298 1039.630005,355.257141 1039.630005,352.564758
	C1041.849121,352.330109 1043.599121,352.145081 1045.251099,351.970398
	C1046.333130,348.425201 1045.221558,347.098785 1041.760010,347.323761
	C1038.457886,347.538361 1035.129150,347.306763 1031.814941,347.386597
	C1027.776855,347.483856 1027.750000,347.580414 1028.843506,352.084290
	C1030.471924,352.226074 1032.100220,352.367859 1034.188232,352.549683
	C1034.188232,359.408569 1034.188232,366.129120 1034.188232,373.149078
	C1036.247925,372.998779 1037.809937,372.884766 1039.629883,372.751953
	C1039.629883,368.736847 1039.629883,365.106415 1039.630005,360.491425
M575.378845,349.504456
	C574.966370,354.339600 579.743591,350.897156 581.477295,353.836823
	C581.477295,359.615692 581.477295,366.201050 581.477295,373.107971
	C583.521057,372.992798 585.087830,372.904510 586.992310,372.797211
	C586.992310,365.830261 586.992310,359.242126 586.992310,352.506683
	C589.097961,352.322083 590.857361,352.167847 592.920410,351.987000
	C592.805176,350.351044 592.707886,348.969910 592.595154,347.369904
	C589.408081,347.369904 586.581848,347.196716 583.786926,347.418518
	C580.985291,347.640869 578.215027,348.257355 575.378845,349.504456
M690.621582,365.395386
	C690.621582,359.495331 690.621582,353.595276 690.621582,347.251007
	C688.304260,347.486816 686.762024,347.643768 685.307495,347.791779
	C685.307495,356.410919 685.307495,364.488251 685.307495,372.808655
	C689.737915,372.808655 693.955261,372.808655 698.562439,372.808655
	C698.464783,371.195831 698.381165,369.815674 698.339539,369.127716
	C695.368347,368.061005 693.002869,367.211761 690.621582,365.395386
z"/>
        <path fill={secondary} opacity="1.000000" stroke="none"
            d="
M320.164001,127.834763
	C336.188782,142.834976 347.801849,160.034363 352.157318,181.580872
	C355.474304,197.990067 354.240509,214.318466 351.632751,230.653122
	C349.435974,244.413330 347.464874,258.212769 345.010468,271.926239
	C344.430145,275.168579 342.025116,278.040039 340.956482,281.247742
	C338.040405,290.000671 335.347626,298.829102 332.643799,307.651367
	C328.477997,321.243561 319.283325,324.015625 307.763489,317.956360
	C301.324799,314.569611 295.904938,308.445374 291.285126,302.565399
	C281.250305,289.793365 270.979645,277.224609 256.665314,269.255798
	C249.120621,265.055664 240.539902,262.207184 232.078857,260.264862
	C212.377029,255.742142 192.325043,252.713684 172.685654,247.961075
	C159.426788,244.752502 147.245651,238.443253 136.919876,229.182892
	C134.526886,227.036804 132.458435,222.440903 130.342453,222.501938
	C125.724121,222.635117 120.702835,224.255875 116.684486,226.666336
	C106.911034,232.529129 96.971970,238.432373 88.339920,245.777039
	C81.920433,251.239136 77.487724,259.020325 72.086189,265.700745
	C68.850861,269.702057 65.887405,274.081543 62.021255,277.374756
	C57.070419,281.591949 51.240261,284.455627 44.334057,284.554962
	C34.605415,284.694824 26.890079,281.219604 20.110758,274.044647
	C12.389876,265.873199 10.104626,255.794327 8.222584,245.522018
	C3.760858,221.169601 2.201190,196.539398 5.257419,171.974808
	C7.773622,151.750702 12.007770,131.671539 19.980904,112.786858
	C25.797924,99.009018 36.665501,89.304695 49.500370,82.028572
	C69.326813,70.788887 90.566811,63.051037 113.100029,59.826599
	C126.191208,57.953297 139.643036,58.719044 152.805359,57.196995
	C173.867401,54.761456 193.035019,61.451271 212.499252,67.621674
	C223.102219,70.982941 231.466583,77.130234 238.389893,85.447479
	C242.483551,90.365349 245.928802,95.818542 249.905014,100.841499
	C250.988129,102.209763 252.834930,103.502235 254.508453,103.786583
	C263.796661,105.364746 273.527863,105.416969 282.373077,108.269051
	C295.794189,112.596611 310.665466,114.869896 320.164001,127.834763
M256.963379,120.471809
	C256.852234,120.768791 256.741058,121.065773 256.592743,122.224014
	C256.949707,128.335373 258.591797,134.702591 257.435028,140.512573
	C253.066635,162.452698 239.672104,178.549561 221.407913,190.469406
	C206.016968,200.514053 188.724335,206.650269 171.221130,212.071701
	C165.236404,213.925400 159.320328,216.000641 152.066071,218.408401
	C162.973938,224.555954 172.973953,229.568298 184.094788,231.890717
	C191.877457,233.515976 199.761795,234.647751 207.575363,236.133347
	C219.134201,238.331039 230.658997,240.707764 242.218796,242.900162
	C265.788116,247.370224 283.561584,261.062622 298.974854,278.491791
	C303.460510,283.564117 307.737549,288.820862 313.335968,295.442932
	C319.070770,275.854828 326.334076,257.944550 329.173706,239.358353
	C331.879517,221.648331 330.578247,203.209747 329.880127,185.136215
	C329.400970,172.732117 325.169525,160.655579 317.849518,150.740906
	C302.978210,130.598312 282.261414,120.936394 256.963379,120.471809
M52.471157,101.910088
	C51.078934,103.554619 49.563335,105.111023 48.315182,106.858459
	C41.856899,115.900200 37.550076,125.744270 35.709805,136.856903
	C33.572491,149.763275 29.816208,162.424713 28.081680,175.369232
	C25.369579,195.609238 26.980804,215.932037 30.188576,236.007339
	C31.532637,244.418900 34.109482,252.808472 37.390068,260.681244
	C40.104145,267.194489 44.342705,267.179626 48.908703,261.545868
	C54.749111,254.339676 60.303421,246.902252 66.090492,239.651855
	C68.470810,236.669647 70.807343,233.512680 73.727821,231.125153
	C87.825699,219.600037 101.777527,207.759842 119.698769,202.932678
	C118.716393,198.538818 117.138504,194.397858 116.974205,190.201523
	C116.474937,177.449753 119.971519,165.759949 126.812805,154.858261
	C139.530106,134.593079 158.373260,122.124634 180.106979,114.314590
	C194.963196,108.975990 210.769196,106.280457 226.340332,102.355446
	C221.702240,97.220673 218.665863,88.923882 210.843018,85.751350
	C200.466537,81.543213 189.839111,76.991249 178.902893,75.330360
	C157.438904,72.070618 135.715744,74.125648 114.226982,76.453903
	C91.575676,78.908119 71.843895,89.207794 52.471157,101.910088
M205.678864,175.297287
	C226.803787,162.219299 238.180679,139.395569 232.311661,121.791298
	C231.172150,121.791298 230.012497,121.722191 228.863113,121.801620
	C209.867401,123.114357 191.838120,128.309235 174.798752,136.507141
	C155.997025,145.552948 144.422089,160.908798 139.507477,181.059967
	C138.072144,186.945206 137.795166,193.210068 141.345886,198.859711
	C163.506546,193.235107 185.152405,186.862976 205.678864,175.297287
z"/>
      


    </svg>

}

