import React, { useEffect, useState } from "react";
import { backendURL } from "../constants/urls";

const SubscriptionDetails = () => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const response = await fetch(`${backendURL}/subscriptions`, {
        method: "GET",
        credentials: "include", // Ensuring credentials are included in the request
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setSubscriptionData(data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch data: " + error.message);
      setLoading(false);
    }
  };

  const handleCancel = async (subscriptionId) => {
    try {
      const response = await fetch(`${backendURL}/subscriptions/cancel`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ subscription_id: subscriptionId }),
      });
      if (!response.ok) {
        throw new Error("Failed to cancel subscription");
      }
      // Optionally re-fetch the subscription data or update state to reflect changes
      fetchSubscriptions();
    } catch (error) {
      alert("Error canceling subscription: " + error.message);
    }
  };

  const handleRestart = async (subscriptionId) => {
    try {
      const response = await fetch(`${backendURL}/subscriptions/restart`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ subscription_id: subscriptionId }),
      });
      if (!response.ok) {
        throw new Error("Failed to restart subscription");
      }
      fetchSubscriptions();
    } catch (error) {
      alert("Error restarting subscription: " + error.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!subscriptionData) return <div>No subscription data found.</div>;
  console.log(subscriptionData, "SUB");
  return (
    <div className="max-w-2xl mx-4 sm:max-w-sm md:max-w-sm lg:max-w-sm xl:max-w-sm sm:mx-auto md:mx-auto lg:mx-auto xl:mx-auto mt-16 shadow-xl rounded-lg">
      {subscriptionData.data.map((sub) => (
        <div key={sub.id} className="border p-4 rounded-lg mb-4">
          <h3 className="font-bold text-md mb-2">Current Subscription</h3>
          <p>
            Status:{" "}
            <span
              className={`font-semibold ${
                sub.status === "active" ? "text-green-500" : "text-red-500"
              }`}
            >
              {sub.status}
            </span>
          </p>
          <p>Plan: Monthly</p>
          <p>
            Amount: ${sub.plan.amount / 100} {sub.currency.toUpperCase()}
          </p>
          {sub.cancel_at && (
            <p className="text-red-600">
              Ends on {new Date(sub.cancel_at * 1000).toLocaleDateString()}
            </p>
          )}
          {sub.canceled_at && (
            <p>
              Canceled on:{" "}
              {new Date(sub.canceled_at * 1000).toLocaleDateString()}
            </p>
          )}
          {sub.trial_end && (
            <p className="flex items-center">
              Trial ends on:{" "}
              {new Date(sub.trial_end * 1000).toLocaleDateString()}
              <span className="ml-2 relative group">
                <svg
                  className="w-4 h-4 text-gray-500 cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 16h-1v-4h-1m1-4h.01M12 2a10 10 0 100 20 10 10 0 000-20z"
                  />
                </svg>
                <span className="absolute left-0 bottom-0 transform translate-y-full w-48 p-2 mt-2 bg-gray-800 text-white text-xs rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  You will be charged automatically when the trial ends
                </span>
              </span>
            </p>
          )}
          {sub.cancel_at_period_end && (
            <button
              className="mt-4 bg-black text-white py-2 px-4 rounded hover:bg-blue-600"
              onClick={() => handleRestart(sub.id)}
            >
              Restart Subscription
            </button>
          )}
          {!sub.canceled_at && (
            <button
              className="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
              onClick={() => handleCancel(sub.id)}
            >
              Cancel Subscription
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default SubscriptionDetails;
