import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ContentLoader from "react-content-loader";
import {
  skeletonBackgroundOpacity,
  skeletonForegroundOpacity,
} from "../constants/theme";
import { backendURL } from "../constants/urls";
import NarrativeCard, {
  NarrativeCardSkeleton,
} from "../components/NarrativeCard";

import useNarrativeData from "../utils/useNarrativeData";
import { ProcessEncoding } from "../utils/learningLanguageHelpers";

const NarrativeHeaderSkeleton = () => (
  <div className="card card-bordered bg-base-100 shadow-xl">
    <div className="card-body">
      <ContentLoader
        backgroundOpacity={skeletonBackgroundOpacity}
        foregroundOpacity={skeletonForegroundOpacity}
        viewBox="0 0 380 125"
      >
        <rect x="0" y="0" rx="5" ry="5" width="380" height="10" />
        <rect x="0" y="14" rx="5" ry="5" width="100" height="8" />
        <rect x="0" y="36" rx="5" ry="5" width="50" height="8" />
        <rect x="0" y="48" rx="5" ry="5" width="70" height="8" />
        <rect x="0" y="60" rx="5" ry="5" width="50" height="8" />
        <rect x="0" y="72" rx="5" ry="5" width="150" height="8" />
        <rect x="180" y="36" rx="5" ry="5" width="50" height="8" />
        <rect x="180" y="48" rx="5" ry="5" width="70" height="8" />
        <rect x="180" y="60" rx="5" ry="5" width="50" height="8" />
        <rect x="180" y="72" rx="5" ry="5" width="150" height="8" />
        <rect x="300" y="110" rx="1" ry="1" width="70" height="13" />
      </ContentLoader>
    </div>
  </div>
);

export default function NarrativeHeader({narrative: n, getAvatar}) {


  if (!n) {
    return <NarrativeCardSkeleton />;
  }
  return (
    <NarrativeCard
      id={`${n.id}`}
      title={ProcessEncoding(n.title)}
      translated_title={ProcessEncoding({...n.title, learning: false})}
      opening_scene={ProcessEncoding(n.opening_scene)}
      description={ProcessEncoding({...n.opening_scene, learning:false})}
      learningLanguage={n.learning_language}
      difficulty={n.difficulty}
      length={n.length}
      speakers={n.speakers}
      vocab={n.vocab_phrases_and_topics}
      creationDate={n.created_at}
      getAvatar={getAvatar}
    />
  );
}
