import React from "react";
import LoggedInHeader from "../components/LoggedInHeader";
import { useState, useEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import planeAndGlobe from "../lottie/planeAndGlobe.json";
import { backendURL } from "../constants/urls";
import {
  NarrativeCardSmall,
  NarrativeCardSmallSkeleton,
} from "../components/NarrativeCard";
import useLevel from "../utils/useLevel";
import useSWR from "swr";

export default function NarrativesPage() {
  const {
    data: narratives,
    error,
    isLoading,
  } = useSWR(`${backendURL}/narratives`, (...args) =>
    fetch(`${backendURL}/narratives/`, {
      credentials: "include",
    }).then((response) => response.json())
  );
  const { level: levelProgress } = useLevel();

  const disabledCreateNarrativeButton = (
    <div
      className="tooltip tooltip-bottom py-4"
      data-tip="Wow you've come so far! To create more narratives, get overtime."
    >
      <button className="btn gap-4" disabled>
        + New Narrative
      </button>
    </div>
  );
  const createNarrativeButton = levelProgress ? (
    Object.keys(levelProgress.narratives).length ? (
      <div className="py-4">
        <a href="/narratives/create">
          <button className="btn btn-primary gap-4">+ New Narrative</button>
        </a>
      </div>
    ) : (
      <div className="flex gap-2 justify-left items-center">
        {disabledCreateNarrativeButton}
        <a
          className="btn text-xs btn-accent"
          href="/subscribe"
        >
          Add Overtime
        </a>
        
      </div>
    )
  ) : (
    disabledCreateNarrativeButton
  );
  const skeletons = (
    <div className="container mx-auto p-4">
      {createNarrativeButton}
      <div className="flex gap-12 flex-col">
        {[1, 2, 3].map((i) => (
          <NarrativeCardSmallSkeleton key={i} />
        ))}
      </div>
    </div>
  );
  const noNarratives = (
    <div className="flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <h1 className="mt-6 text-center text-3xl font-extrabold">
          Get started and create your first narrative.
        </h1>
        <p className="mt-6 text-center text-xl font-extrabold">
          {createNarrativeButton}
        </p>
        <Player src={planeAndGlobe} style={{ height: "500px" }} autoplay loop />
      </div>
    </div>
  );
  return (
    <>
      <LoggedInHeader />

      {isLoading ? (
        skeletons
      ) : narratives ? (
        narratives.length ? (
          <div className="container mx-auto p-4">
            {createNarrativeButton}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {narratives.map((n) => (
                <NarrativeCardSmall
                  id={n.id}
                  title={n.title}
                  opening_scene={n.opening_scene.learning_language_content}
                  description={n.opening_scene.native_language_content}
                  learningLanguage={n.learning_language}
                  difficulty={n.difficulty}
                  length={n.length}
                  creationDate={n.created_at}
                />
              ))}
            </div>
          </div>
        ) : (
          noNarratives
        )
      ) : (
        skeletons
      )}
    </>
  );
}
