export default function shuffleArray(array, seed, unique) {
    // Validate seed and handle undefined cases
    if (typeof seed === "undefined") {
      seed = Math.random(); // Generate a random seed if none provided
    }
  
    // Remove duplicates from the array
    const uniqueArray = (unique===true || typeof unique === "undefined")?[...new Set(array)]:[...array];
  
    // Initialize indices based on seed
    const indices = [];
    for (let i = 0; i < uniqueArray.length; i++) {
      indices.push((i * seed) % 1); // Use seed to create unique pseudo-random numbers
    }
  
    // Fisher-Yates shuffle using indices
    let currentIndex = uniqueArray.length,
      randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(indices[currentIndex - 1] * currentIndex);
      currentIndex--;
      [uniqueArray[currentIndex], uniqueArray[randomIndex]] = [
        uniqueArray[randomIndex],
        uniqueArray[currentIndex],
      ];
    }
  
    return uniqueArray;
  }

  function randomShuffle(array) {
    return array.sort(() => Math.random() - 0.5);
  }

  export {randomShuffle}