import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { backendURL } from "../constants/urls";
import { useParams, useNavigate } from "react-router-dom";
import { SpeakersList } from "./Speakers";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import Vocab from "./Vocab";
import { ProcessEncoding } from "../utils/learningLanguageHelpers";
import useLevel from "../utils/useLevel";

export default function NarrativeCard({
  id,
  title,
  translated_title,
  opening_scene,
  description,
  nativeLanguage,
  learningLanguage,
  difficulty,
  length,
  speakers,
  vocab,
  creationDate,
  showView,
  getAvatar,
}) {
  const navigate = useNavigate();
  const created = new Date(Date.parse(creationDate));
  const createDialogueURL = `${backendURL}/narratives/${id}/dialogue`;

  const { level: levelProgress } = useLevel();
  const handleCreateDialogue = async () => {
    await fetch(createDialogueURL.toString(), {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        navigate(`/narratives/${id}/dialogues/${data.id}`);
      })
      .catch((error) => {
        console.log(`Ran into an error while creating dialogue: ${error}`);
      });
  };
  const [speakersOpen, setSpeakersOpen] = useState(false);
  const [vocabOpen, setVocabOpen] = useState(false);
  const disabledStartNewDialogueButton = (
    <div
      className="tooltip tooltip-bottom py-4"
      data-tip="Wow you've come so far! To do more dialogues, get overtime."
    >
      <button className="btn btn-success m-4" disabled>
        Start a New Dialogue
      </button>
    </div>
  );
  const startNewDialogueButton = levelProgress ? (
    levelProgress.dialogues ? (
      <button onClick={handleCreateDialogue} className="btn btn-success m-4">
        Start a New Dialogue
      </button>
    ) : (
      <div className="flex gap-2 justify-center items-center">
        <a
          className="btn text-xs btn-accent"
          href="/subscribe"
        >
          Add Overtime
        </a>
        {disabledStartNewDialogueButton}
      </div>
    )
  ) : (
    disabledStartNewDialogueButton
  );
  return (
    <>
      <div className="card bg-base-300 shadow-xl w-full min-w-5xl p-4">
        <div className="card-body w-full flex flex-row flex-wrap">
          <div className="flex flex-col justify-start md:w-[30%] w-full mb-auto">
            <h2 className="card-title">{title}</h2>
            <h3 className="text-md my-2">{translated_title}</h3>
            <p className="text-xs mb-2">
              {`Created ${created.toLocaleString([], {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}`}
            </p>
            <div className="flex flex-col justify-start">
              <div className="badge badge-primary">
                Difficulty: {difficulty}
              </div>
              <div>
                <div className="badge badge-secondary">
                  Language: {learningLanguage}
                </div>
                <div className="badge badge-info">Length: {length}</div>
              </div>
            </div>
            <div></div>
          </div>

          <div className="flex flex-col md:w-[67%] w-full">
            <div className="collapse border border-base-300 bg-base-100 mb-2">
              <div
                className="flex justify-between h-16 items-center"
                onClick={() => {
                  setSpeakersOpen((s) => !s);
                }}
              >
                <p className="text font-bold m-4">{speakers.length} Speakers</p>{" "}
                <ChevronDownIcon
                  className={
                    "h-6 w-6 m-4" + (speakersOpen ? " rotate-180" : "")
                  }
                />
              </div>
              <div className={"p-2 " + (speakersOpen ? "" : "hidden")}>
                <SpeakersList speakers={speakers} getAvatar={getAvatar} />
              </div>
            </div>

            <div className="collapse  border border-base-300 bg-base-100 mb-2">
              <div
                className="flex justify-between h-16 items-center"
                onClick={() => {
                  setVocabOpen((s) => !s);
                }}
              >
                <p className="text font-bold m-4">
                  {vocab.length} Vocab, Phrases and Topics
                </p>{" "}
                <ChevronDownIcon
                  className={"h-6 w-6 m-4" + (vocabOpen ? " rotate-180" : "")}
                />
              </div>
              <div className={"p-2 " + (vocabOpen ? "" : "hidden")}>
                {vocab.map((v) => (
                  <Vocab vocab={v} key={v.learning_language_content} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <h2 className="text-lg font-bold">Opening Scene</h2>
        <div
          role="alert"
          tabIndex={0}
          className="collapse collapse-plus alert shadow-lg"
        >
          <div className="collapse-title text-sm font-medium flex flex-row justify-around items-center">
            {opening_scene}
          </div>
          <div className="collapse-content text-sm">
            <p>{description}</p>
          </div>
        </div>
        <div className="flex flex-row ml-auto">{startNewDialogueButton}</div>
      </div>
    </>
  );
}

function NarrativeCardSmall({
  id,
  title,
  translated_title,
  opening_scene,
  description,
  learningLanguage,
  difficulty,
  length,
  creationDate,
}) {
  const created = new Date(Date.parse(creationDate));
  return (
    <a href={`/narratives/${id}/`}>
      <div className="card bg-base-200 shadow-xl w-full min-w-5xl">
        <div className="card-body w-full flex flex-row flex-wrap">
          <div className="flex flex-col justify-start  mb-auto">
            <h2 className="card-title">{ProcessEncoding(title)}</h2>
            <h3 className="text-md my-2">
              {ProcessEncoding({ ...title, learning: false })}
            </h3>
            <p className="text-xs mb-2">
              {`Created ${created.toLocaleString([], {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}`}
            </p>
            <div className="flex flex-col justify-start">
              <div className="badge badge-primary">
                Difficulty: {difficulty}
              </div>
              <div>
                <div className="badge badge-secondary">
                  Language: {learningLanguage}
                </div>
                <div className="badge badge-info">Length: {length}</div>
              </div>
            </div>
            <div></div>
          </div>
        </div>

        <button className="btn btn-error mx-4 m-8 w-[90]">
          View Dialogues
        </button>
      </div>
    </a>
  );
}

const NarrativeCardSkeleton = () => (
  <div className="flex flex-row gap-4 w-full max-w-5xl">
    <div className="flex flex-col gap-4 w-[33%]">
      <div className="skeleton h-4 w-full"></div>
      <div className="skeleton h-2 w-full"></div>
      <div className="skeleton h-2 w-full"></div>
      <div className="skeleton h-2 w-[33%]"></div>
      <div className="skeleton h-2 w-[33%]"></div>
      <div className="skeleton h-2 w-[33%]"></div>
    </div>
    <div className="flex flex-col gap-4 w-[66%]">
      <div className="skeleton h-12 w-full"></div>
      <div className="skeleton h-12 w-full"></div>

      <div className="flex flex-row ml-auto">
        <div className="skeleton h-12 w-12 mx-12"></div>
        <div className="skeleton h-12 w-24"></div>
      </div>
    </div>
  </div>
);
const NarrativeCardSmallSkeleton = () => (
  <div className="flex flex-row gap-4 w-full max-w-5xl">
    <div className="flex flex-col gap-4 w-[33%]">
      <div className="skeleton h-4 w-full"></div>
      <div className="skeleton h-2 w-full"></div>
      <div className="skeleton h-2 w-full"></div>
      <div className="skeleton h-2 w-[33%]"></div>
      <div className="skeleton h-2 w-[33%]"></div>
      <div className="skeleton h-2 w-[33%]"></div>
    </div>
  </div>
);
export {
  NarrativeCardSmall,
  NarrativeCardSmallSkeleton,
  NarrativeCardSkeleton,
};
