import { useEffect, useState } from "react";
import { useForm } from "@formspree/react";
import LoggedInHeader from "../components/LoggedInHeader";
import { Formik, Form, Field } from "formik";
import { Player } from "@lottiefiles/react-lottie-player";
import feedback from "../lottie/feedback";
import { backendURL } from "../constants/urls";
import useUser from "../utils/useUser";

const fixedInputClass =
  "rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 focus:z-10 sm:text-sm";

export default function Feedback() {
  const [state, handleSubmit, reset] = useForm(
    process.env.REACT_APP_FEEDBACK_FORMSPREE||"none"
  );
  const { user, isLoading, isError } = useUser()
  if (state.succeeded) {
    window.location.href = "/feedback/success";
  }
  if (!user) {
    return <LoggedInHeader />;
  }
  return (
    <>
      <LoggedInHeader />
      <p className="text-4xl text-center p-4">Leave us Feedback</p>
      <Player
        src={feedback}
        speed={0.5}
        style={{ height: "200px" }}
        autoplay
        loop
      />
      <div className="flex justify-center items-center">
        <p className="text-xl text-center p-8 w-1/2">
          We love to hear what you think about Stoozi, so please leave us
          feedback below on any aspect of your experience. Whether it’s a
          feature you’d love to see, your favorite parts about the app, any
          questions you might have, or even if something didn’t quite work right
          for you, we’re all ears. Your insights help us grow and improve,
          ensuring Stoozi continues to meet your needs and exceed your
          expectations. Don’t hold back - your honest feedback is invaluable to
          us. Let’s make Stoozi even better, together.
        </p>
      </div>
      <div className="flex justify-center items-center">
        <div className="rounded-lg shadow-lg max-w-2xl w-full bg-base-200 p-8">
          <Formik
            className="mt-8 space-y-6"
            initialValues={{ email: user.email, feedback: "" }}
            onSubmit={(values) => handleSubmit(values)}
          >
            <Form>
              <div className="-space-y-px">
                <div className="my-5">
                <label
                    htmlFor="feedback"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your Email*
                  </label>
                  <Field
                    type="text"
                    name="email"
                    disabled={true}
                    value={user.email}
                    className={fixedInputClass}
                  />
                </div>
              </div>
              <div className="-space-y-px">
                <div className="my-5">
                  <label
                    htmlFor="feedback"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Feedback*
                  </label>
                  <Field name="feedback">
                    {({ field, form, meta }) => {
                      return (
                        <textarea
                          id="feedback"
                          onChange={field.onChange}
                          value={field.value}
                          rows="4"
                          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        ></textarea>
                      );
                    }}
                  </Field>
                </div>
              </div>
              <button
                type="submit"
                disabled={state.submitting}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mt-10"
              >
                Submit Feedback
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}
