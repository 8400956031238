import React, { useState, useEffect, useRef } from "react";
import {
  useAsync,
  useLocalStorage,
  useMeasure,
  useWindowSize,
  useIntersection,
} from "react-use";
import LoggedInHeader from "../components/LoggedInHeader";
import ChatMessage from "../components/ChatMessage";
import useAudioPlayer from "../components/AudioPlayer";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { wsBackendURL, backendURL } from "../constants/urls";
import { useParams } from "react-router-dom";

import VoiceRecorder from "../components/VoiceRecorder";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import {
  getSpeaker,
  default_avatar,
  SpeakersList,
} from "../components/Speakers";
import useNarrativeData from "../utils/useNarrativeData";
import { ProcessEncoding } from "../utils/learningLanguageHelpers";
import { utcToLocalDate } from "../utils/datetimeHelpers";
import Heart from "react-animated-heart";
import useUser from "../utils/useUser";
import CommentSection from "../components/DialogueComments";
import Comments from "../components/Comments";
import ShareDialogue from "../components/ShareDialogue";

function isCommentsAboveScreen() {
  const element = document.getElementById("comments");

  if (!element) {
    return false; // Element not found
  }

  const rect = element.getBoundingClientRect();
  const bottomOfScreen = window.innerHeight;

  return rect.bottom < bottomOfScreen;
}

function insertMessage(base, message) {
  message["time"] = message.created_at
    ? utcToLocalDate(message.created_at)
    : new Date();
  const baseObject = new Map(base);
  if ("chunk_number" in message) {
    // It's a message chunk
    const { chunk_number, gen_id } = message;
    if (baseObject.get(gen_id) instanceof Map) {
      baseObject.set(
        gen_id,
        new Map([...baseObject.get(gen_id), [chunk_number, message]])
      );
    } else {
      baseObject.set(gen_id, new Map([[chunk_number, message]]));
    }
  } else if ("gen_id" in message) {
    // It's a whole message
    const { gen_id } = message;
    if (message.message_kind === "stoozi_question") {
      baseObject.set(gen_id, new Map([[-1, message]]));
    } else {
      baseObject.set(gen_id, message);
    }
  }
  return baseObject;
}

const compareMsgTime = (a, b) => {
  let timeA = null;
  let timeB = null;
  if (a instanceof Map) {
    timeA = [...a][a.size - 1][1].time;
  } else {
    timeA = a.time;
  }
  if (b instanceof Map) {
    timeB = [...b][b.size - 1][1].time;
  } else {
    timeB = b.time;
  }
  return timeA.getTime() - timeB.getTime();
};

function validatePlayIndex({
  index: currentIndex,
  messages,
  kind: currentKind,
  playTranslation,
  playUser,
}) {
  if (messages.size === 0) {
    return {};
  }
  const index = currentIndex || 0;
  const gen_allowed_kinds = audio_kinds
    .map((e, idx) => {
      if (e === "user_audio_id") {
        return [playUser, idx];
      }
      if (e === "translation_audio_id") {
        return [playTranslation, idx];
      }
      return [true, idx];
    })
    .filter(([v, _]) => v)
    .map((v) => v[1]);
  const kind = currentKind || gen_allowed_kinds[0];

  const checked = [...messages].map(([genId, m], idx) => {
    const msgPlayable = !(m instanceof Map);
    return {
      msgPlayable,
      kinds: msgPlayable
        ? audio_kinds
            .map((e, idx) => {
              if (e === "user_audio_id") {
                return [m.direct_student_message === true && playUser, idx];
              }
              if (e === "translation_audio_id") {
                return [playTranslation, idx];
              }
              return [true, idx];
            })
            .filter(([v, _]) => v)
            .map((v) => v[1])
        : [],
    };
  });
  const before = checked[index - 1];
  const current = checked[index];
  const after = checked[index + 1];
  const currentPlayable = current && current.msgPlayable;
  const currentNextKind =
    current && current.kinds.includes(kind + 1) && kind + 1;
  const currentPrevKind =
    current && current.kinds.includes(kind - 1) && kind - 1;

  const prevIndex = before && before.msgPlayable && index - 1;
  const prevKind =
    before && before.msgPlayable && before.kinds[before.kinds.length - 1];
  const prevPlayable = prevIndex || prevKind;
  const nextIndex = index + 1;
  const nextKind = (after && after.kinds[0]) || gen_allowed_kinds[0];
  const nextPlayable = after && after.msgPlayable;

  const prev = currentPrevKind
    ? { index, kind: currentPrevKind, playable: currentPlayable }
    : { index: prevIndex, kind: prevKind, playable: prevPlayable };
  const next = currentNextKind
    ? { index, kind: currentNextKind, playable: currentPlayable }
    : { index: nextIndex, kind: nextKind, playable: nextPlayable };

  const ret = {
    playable: current && current.kinds.includes(kind),
    prev,
    next,
  };
  return ret;
}

const audio_kinds = ["user_audio_id", "audio_id", "translation_audio_id"];
const audio_url = {
  user_audio_id: (genID) => `${backendURL}/audio/${genID}/user_audio_id`,
  audio_id: (genID) => `${backendURL}/audio/${genID}/audio_id`,
  translation_audio_id: (genID) =>
    `${backendURL}/audio/${genID}/translation_audio_id`,
};

const kind_playing = (genId, url) =>
  Object.keys(audio_url).filter((k) => audio_url[k](genId) === url)[0];

export default function ChatPage() {
  //(narrative_id, dialogue_id) {
  // const narrativeId = 1;
  // const dialogueId = 3;
  const { narrativeId, dialogueId } = useParams(); //get the ids to facilitate the chat from the route params
  //get the narrative to show the user
  // and to know if the user speaks first
  const { user, userIsLoading, userIsError } = useUser();
  const {
    speakerColors,
    narrativeValue,
    narrativeLoading,
    narrativeError,
    getAvatar,
  } = useNarrativeData(narrativeId);
  // this will store the actual messages to display
  // see insertMessage
  // an object with id keys and values of array for the streaming message or a message object for completed messages
  const [messages, setMessages] = useState(new Map());
  const [footer_ref, { height: footer_height }] = useMeasure();
  const [header_ref, { height: header_height }] = useMeasure();
  const { height: windowHeight } = useWindowSize();
  const [messagesBelow, setMessagesBelow] = useState(false);
  const lastMessageRef = useRef(null);

  const [likeCount, setLikeCount] = useState();
  const [liked, setLiked] = useState(false);
  const [isPublished, setIsPublished] = useState(false);

  useEffect(() => {
    fetch(`${backendURL}/dialogues/${dialogueId}/liked`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setLikeCount(data.likes_cnt);
        if (data.liked == true) {
          setLiked(true);
        } else {
          setLiked(false);
        }
      })
      .catch((err) => console.log(err));
    fetch(`${backendURL}/dialogues/${dialogueId}/published`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setIsPublished(data.published);
      })
      .catch((err) => console.log(err));
  }, []);

  const likeDialogue = () => {
    if (liked) {
      fetch(`${backendURL}/dialogues/${dialogueId}/unlike`, {
        credentials: "include",
        method: "POST",
      })
        .then((response) => response.json())
        .then((data) => setLikeCount(data.likes_cnt))
        .catch((err) => console.log(err));
      setLiked(false);
    } else {
      fetch(`${backendURL}/dialogues/${dialogueId}/like`, {
        credentials: "include",
        method: "POST",
      })
        .then((response) => response.json())
        .then((data) => setLikeCount(data.likes_cnt))
        .catch((err) => console.log(err));
      setLiked(true);
    }
  };

  const publishDialogue = () => {
    if (isPublished) {
      fetch(`${backendURL}/dialogues/${dialogueId}/unpublish`, {
        credentials: "include",
        method: "POST",
      }).catch((err) => console.log(err));
      setIsPublished(false);
    } else {
      fetch(`${backendURL}/dialogues/${dialogueId}/publish`, {
        credentials: "include",
        method: "POST",
      }).catch((err) => console.log(err));
      setIsPublished(true);
    }
  };

  const userOwned =
    narrativeValue != null &&
    user != null &&
    narrativeValue.user_id === user.id;
  const publishButton = isPublished ? (
    <button
      onClick={publishDialogue}
      className="btn btn-sm font-bold text-sm"
      disabled={!userOwned}
    >
      {!userOwned ? "Reading" : "Unpublish"}
    </button>
  ) : (
    <button
      onClick={publishDialogue}
      className="hover:bg-green-300 btn btn-sm font-bold text-sm"
      disabled={!userOwned}
    >
      Publish
    </button>
  );

  const gotoCommentsButton = (
    <button
      onClick={() => {
        var element = document.getElementById("comments");

        if (element) {
          var rect = element.getBoundingClientRect();

          if (
            !(
              rect.top >= 0 &&
              rect.left >= 0 &&
              rect.bottom <=
                (window.innerHeight || document.documentElement.clientHeight) &&
              rect.right <=
                (window.innerWidth || document.documentElement.clientWidth)
            )
          ) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }
      }}
      className="btn btn-sm font-bold text-sm"
      disabled={!isPublished}
    >
      <span className="hidden md:inline">Comments</span>{" "}
      <i class="bi bi-chat-square-text-fill"></i>
    </button>
  );

  const showCommentsButton = (
    <>
      {!isPublished && (
        <span className="text-xs text-error mx-auto p-2">
          Publish This Dialogue To Enable Comments
        </span>
      )}
      <button
        onClick={() => setShowComments((s) => !s)}
        className="btn-primary btn font-bold text-lg mx-auto"
        disabled={!isPublished}
      >
        Load Comments
      </button>
    </>
  );

  const lastMessageIntersection = useIntersection(lastMessageRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  });

  useEffect(() => {
    setMessagesBelow(
      lastMessageIntersection == null
        ? false
        : isCommentsAboveScreen()
        ? false
        : !lastMessageIntersection.isIntersecting
    );
  }, [
    lastMessageIntersection == null
      ? false
      : !lastMessageIntersection.isIntersecting,
  ]);

  const [recommendations, setRecommendations] = useState(new Map());
  const [stooziMessages, setStooziMessages] = useState(new Map());

  const [streamActive, setStreamActive] = useState(null);
  const [dialogueEnded, setDialogueEnded] = useState(false);
  const [dialogueError, setDialogueError] = useState(null);
  const [narrativeSpeakersShow, setNarrativeSpeakersShow] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [showMessageText, setShowMessageText] = useLocalStorage(
    "stoozi-chat-message-text",
    true
  );
  const [showSuggestions, setShowSuggestions] = useLocalStorage(
    "stoozi-chat-message-suggestions",
    true
  );
  const [messageBuilder, setmessageBuilder] = useLocalStorage(
    "stoozi-chat-message-builder",
    false
  );
  const [playUser, setPlayUser] = useLocalStorage(
    "stoozi-chat-play-user",
    true
  );
  const [playTranslation, setPlayTranslation] = useLocalStorage(
    "stoozi-chat-play-translation",
    true
  );
  const [volume, setVolume] = useLocalStorage(
    "stoozi-chat-playback-volume",
    100
  );
  const [pace, setPace] = useLocalStorage("stoozi-chat-playback-pace", 100);
  const [pauseTime, setPauseTime] = useLocalStorage(
    "stoozi-chat-playback-pause-time",
    5
  );
  const [masterPlayArgs, setMasterPlayArgs] = useState({
    single: false,
    play: false,
    index: 0,
    kind: 1,
    playable: false,
    next: {},
    prev: {},
  });

  const handleMasterPrevNext = (dir) => {
    setMasterPlayArgs((a) => {
      if (dir < 0) {
        return {
          ...a,
          ...a.prev,
        };
      }
      return {
        ...a,
        ...a.next,
      };
    });
  };

  const {
    controlAudio,
    volumePace,
    playArgs,
    currentURL,
    waiting,
  } = useAudioPlayer();

  useEffect(() => {
    if (masterPlayArgs.play) {
      if (!masterPlayArgs.single) {
        if (masterPlayArgs.playable) {
          const genID = [...messages][masterPlayArgs.index][0];
          const audioKind = audio_kinds[masterPlayArgs.kind];
          controlAudio({
            play: true,
            url: audio_url[audioKind](genID),
            volume: volume / 100,
            pace: pace / 100,
            onPlaybackDone: (b) => handleMasterPrevNext(1),
          });
        }
      }
    } else {
      controlAudio({ play: false, url: "" });
    }
  }, [
    masterPlayArgs.play,
    masterPlayArgs.kind,
    masterPlayArgs.index,
    masterPlayArgs.playable,
  ]);

  useEffect(() => {
    volumePace(volume / 100, pace / 100);
  }, [volume, pace]);

  useEffect(() => {
    const validatedState = validatePlayIndex({
      index: masterPlayArgs.index,
      messages,
      kind: masterPlayArgs.kind,
      playTranslation,
      playUser,
    });
    setMasterPlayArgs((a) => ({
      ...a,
      ...validatedState,
    }));
  }, [
    messages,
    masterPlayArgs.index,
    masterPlayArgs.kind,
    playTranslation,
    playUser,
  ]);

  // used to track user generated audio text to see if they change their recording or just send it
  // if changed we don't store the audio to the message
  // if unchanged we do
  const [audioMsg, setAudioMsg] = useState(null);

  const [newMessage, setNewMessage] = useState(""); //the actual value the user creates
  const [messageToSend, setMessageToSend] = useState(null); //a value set when the websocket is down and will be reestablished to send it
  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `${wsBackendURL}/narratives/${narrativeId}/dialogues/${dialogueId}/`,
    {
      onOpen: (e) => {
        if (messageToSend) {
          sendMessage(messageToSend);
          setMessageToSend(null);
        }
      },
      onReconnectStop: (e) => {
        setDialogueError({
          code: -1,
          error:
            "There was a problem connecting to this Dialoge, but don't worry! Try refreshing or try again later.",
        });
        setStreamActive(false);
      },
      shouldReconnect: (e) => (dialogueEnded ? false : true),
      reconnectAttempts: 10,
      reconnectInterval: (
        attemptNumber //exponential backoff 1, 2, 8, 16, 30,...30 seconds
      ) => Math.min(Math.pow(2, attemptNumber) * 1000, 30000),
    }
  );

  let connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  let connectionBadgeClass = {
    [ReadyState.CONNECTING]: "rounded-sm badge badge-outline badge-info",
    [ReadyState.OPEN]: "rounded-sm badge badge-outline badge-success opacity-0",
    [ReadyState.CLOSING]:
      "rounded-sm badge badge-outline badge-warning opacity-0",
    [ReadyState.CLOSED]: "rounded-sm badge badge-outline badge-error opacity-0",
    [ReadyState.UNINSTANTIATED]:
      "rounded-sm badge badge-outline badge-error opacity-0",
  }[readyState];

  let loading = false;
  if (userIsLoading || narrativeLoading || speakerColors == null) {
    loading = true;
  }

  useEffect(() => {
    if (lastMessage !== null) {
      if (
        lastMessage.data === "Stream Over" ||
        lastMessage.data === "Open Response"
      ) {
        setStreamActive(false);
      } else if (
        lastMessage.data === "Starting Stream" ||
        lastMessage.data === "Continuing"
      ) {
        setStreamActive(true);
      } else {
        const data = JSON.parse(lastMessage.data);
        if (data.hasOwnProperty("retract")) {
          // oopsie remove latest messages
          setStreamActive(false);
          setMessages(
            (msgs) =>
              new Map([...msgs].filter(([key, _]) => key === data.retract))
          );
        } else if (data.hasOwnProperty("error")) {
          setDialogueError(data);
          setStreamActive(false);
        } else if (data.message_kind == "end") {
          setStreamActive(false);
          setDialogueEnded(true);
        } else if (data.message_kind == "student_recommendation") {
          setRecommendations((msgs) => insertMessage(msgs, data));
        } else if (data.message_kind.includes("stoozi")) {
          setStooziMessages((msgs) => insertMessage(msgs, data));
        } else {
          setMessages((msgs) => insertMessage(msgs, data));
        }
      }
      // setStreamActive(v => v == null ? true : v);
    }
  }, [lastMessage]);

  // This fixes the race condition of the narrative data loading and the component loading
  if (loading) {
    return <span className="loading loading-spinner loading-lg"></span>;
  }

  const remStooziMessages = Math.round(10 - stooziMessages.size);
  const handleSendMessage = async (msg) => {
    if (streamActive) {
      return;
    }
    if (remStooziMessages <= 0) {
      return;
    }

    let useMsg = "";

    let useAudioId = null;
    if (typeof msg == "string" && msg.trim() !== "") {
      useMsg = msg.trim();
      if (audioMsg != null && useMsg === audioMsg[0].trim()) {
        useAudioId = audioMsg[1];
      }
    } else if (newMessage.trim() !== "") {
      useMsg = newMessage.trim();
      if (audioMsg != null && useMsg === audioMsg[0].trim()) {
        useAudioId = audioMsg[1];
      }
    }
    const msgToSend = JSON.stringify({ message: useMsg, audio_id: useAudioId });
    if (connectionStatus === "Open") {
      sendMessage(msgToSend);
    } else {
      setMessageToSend(msgToSend);
    }
    setNewMessage("");
    if (!/@stoozi/i.test(useMsg)) {
      setRecommendations(new Map());
    }
    setAudioMsg(null);
  };

  const handleInputChange = (event) => {
    setNewMessage(event.target.value);
  };

  const dialogue_controls = (
    <button
      className="btn btn-square btn-outline border-transparent drop-shadow-2xl mx-2 rounded-lg my-auto"
      onClick={() => document.getElementById("dialogue_modal").showModal()}
    >
      <i className={"bi bi-music-note-list text-center text-2xl text-info"}></i>
    </button>
  );

  const disableInputs = readyState !== ReadyState.OPEN || streamActive;

  return (
    <>
      {/* <AudioPlayer
        {...currentPlayArgs}
        volume={volume / 100}
        pace={
          (currentPlayArgs.pace != null
            ? Math.max(currentPlayArgs.pace, pace)
            : pace) / 100
        }
        onPlaybackDone={async (played) => {
          setCurrentSinglePlayMessage(null);
          setCurrentPlayArgs({});
          if (masterPlaying) {
            await handleMasterPlaybackChange(1, played ? pauseTime : 1);
          } else {
            handleSinglePlaybackKindIncrement();
          }
        }}
      /> */}
      <div className="fixed flex flex-col h-screen w-screen overflow-hidden">
        <LoggedInHeader headerRef={header_ref} />
        <div
          className="w-full shadow-inner-top-bottom flex flex-col"
          style={{
            height: windowHeight - footer_height - header_height,
            position: "fixed",
            top: `${header_height}px`,
          }}
        >
          {connectionStatus === "Connecting" && (
            <div className="absolute top-0 left-0 h-full w-full bg-black bg-opacity-[.25] flex justify-center items-center pointer-events-none z-[10000]">
              <span className="loading loading-spinner loading-lg"></span>
            </div>
          )}
          <div className="flex flex-col mx-auto scroll-auto overscroll-contain overflow-y-auto w-full max-w-5xl p-4">
            {[...messages.entries(), ...stooziMessages.entries()]
              .toSorted(([idA, a], [idB, b]) => compareMsgTime(a, b))
              .map((entry, index) => {
                let [genId, msg] = entry;
                const is_last_message =
                  recommendations.size === 0 && index === messages.size - 1;
                const playing_kind = kind_playing(genId, currentURL);
                const playing = masterPlayArgs.play && playing_kind;

                return (
                  <ChatMessage
                    index={index}
                    msg={msg}
                    key={genId}
                    getAvatar={getAvatar}
                    isStudent={(name) =>
                      name === narrativeValue.student_speaker
                    }
                    getSpeaker={(name) =>
                      getSpeaker(narrativeValue.speakers, name)
                    }
                    getColor={(name) => speakerColors.get(name)}
                    playing={playing}
                    audioControls={{
                      playUser: () => {
                        controlAudio({
                          url: audio_url.user_audio_id(genId),
                          volume: volume / 100,
                          pace: pace / 100,
                          play: true,
                        });
                        setMasterPlayArgs((a) => ({
                          ...a,
                          play: true,
                          single: true,
                        }));
                      },
                      playAudio: () => {
                        controlAudio({
                          url: audio_url.audio_id(genId),
                          volume: volume / 100,
                          pace: pace / 100,
                          play: true,
                        });
                        setMasterPlayArgs((a) => ({
                          ...a,
                          play: true,
                          single: true,
                        }));
                      },
                      playTranslation: () => {
                        controlAudio({
                          url: audio_url.translation_audio_id(genId),
                          volume: volume / 100,
                          pace: pace / 100,
                          play: true,
                        });
                        setMasterPlayArgs((a) => ({
                          ...a,
                          play: true,
                          single: true,
                        }));
                      },
                      pause: () =>
                        setMasterPlayArgs((a) => ({ ...a, play: false })),
                    }}
                    lastMessageRef={is_last_message ? lastMessageRef : null}
                    showMessageText={showMessageText}
                  />
                );
              })}

            {recommendations.size > 0 && (
              <div className="ml-auto flex flex-col justify-center border-b-2 border-secondary my-6 w-[20rem]">
                <div className="flex">
                  <p>
                    {recommendations.size === 1
                      ? "Potential Reply Option"
                      : "Potential Reply Options"}
                  </p>
                  <button
                    className="ml-auto"
                    onClick={() => setShowSuggestions(!showSuggestions)}
                  >
                    {showSuggestions ? (
                      <i className="bi bi-eye-fill text-2xl"></i>
                    ) : (
                      <i className="bi bi-eye-slash-fill text-2xl"></i>
                    )}
                  </button>
                </div>
                {showSuggestions && (
                  <p className="text-xs opacity-50">
                    Click A Message to Edit or its Send Button to Send it
                    <br></br>
                    Or, Play an Option and Record your Rendition
                  </p>
                )}

                {showSuggestions && (
                  <label className="label cursor-pointer">
                    <div
                      className="tooltip"
                      data-tip="Message Builder Allows You To Test Your Skills Assembling Your Replies."
                    >
                      <i className="bi bi-info-circle-fill text-xl mx-2"></i>
                    </div>
                    <span className="label-text mx-2">Message Builder</span>
                    <input
                      type="checkbox"
                      checked={messageBuilder}
                      className="checkbox"
                      onChange={() => setmessageBuilder(!messageBuilder)}
                    />
                  </label>
                )}
              </div>
            )}
            {showSuggestions &&
              [...recommendations.entries()].map((entry) => {
                let [genId, msg] = entry;
                const is_last_message = recommendations.size > 0;
                const playing_kind = kind_playing(genId, currentURL);
                const playing = masterPlayArgs.play && playing_kind;
                return (
                  <ChatMessage
                    msg={msg}
                    key={genId}
                    msgOnClick={(txt, append) =>
                      setNewMessage((t) => (append ? t + txt : txt))
                    }
                    getAvatar={(text) => (
                      <button
                        className="btn btn-outline btn-circle chat-image avatar"
                        onClick={() => handleSendMessage(text)}
                        disabled={streamActive || msg instanceof Map}
                      >
                        <i
                          className={
                            "bi bi-send text-center text-3xl" +
                            (!streamActive ? " text-primary" : "")
                          }
                        ></i>
                      </button>
                    )}
                    isStudent={(name) =>
                      name === narrativeValue.student_speaker
                    }
                    getSpeaker={(name) =>
                      getSpeaker(narrativeValue.speakers, name)
                    }
                    getColor={(name) => speakerColors.get(name)}
                    playing={playing}
                    audioControls={{
                      playAudio: () => {
                        controlAudio({
                          url: audio_url.audio_id(genId),
                          volume: volume / 100,
                          pace: pace / 100,
                          play: true,
                        });
                        setMasterPlayArgs((a) => ({
                          ...a,
                          play: true,
                          single: true,
                        }));
                      },
                      pause: () =>
                        setMasterPlayArgs((a) => ({ ...a, play: false })),
                    }}
                    lastMessageRef={is_last_message ? lastMessageRef : null}
                    messageBuilder={messageBuilder}
                    showMessageText={showMessageText}
                  />
                );
              })}
            <div className="divider" id="comments">
              Comments
            </div>
            {showComments ? (
              <Comments dialogueId={dialogueId} />
            ) : (
              showCommentsButton
            )}
          </div>
        </div>

        <div className="fixed bottom-0 flex flex-col w-full" ref={footer_ref}>
          {messagesBelow ? (
            <div className="absolute top-0 w-full flex justify-center ">
              <button
                className="badge badge-info animate-bounce shadow-2xl"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  lastMessageRef.current.scrollIntoView();
                }}
              >
                ↓ More Below ↓
              </button>
            </div>
          ) : null}
          <>
            <div className="w-full h-full flex flex-row justify-center items-center pt-4">
              {dialogueEnded && dialogue_controls}
              {dialogueEnded ? (
                <div
                  role="alert"
                  className="relative alert h-[4rem] text-center flex flex-row justify-around items-center shadow-lg m-4 max-w-5xl"
                >
                  <span className="text-xs absolute top-0 left-50 -translate-y-[100%] -translate-x-[23%] text-success pt-1">
                    This Dialogue is complete.
                  </span>

                  {gotoCommentsButton}

                  {publishButton}
                  <ShareDialogue
                    narrativeId={narrativeId}
                    dialogueId={dialogueId}
                  />
                  <div className="flex justify-center items-center">
                    <div className="-ml-8 -mr-4 overflow-hidden flex justify-center items-center">
                      <Heart isClick={liked} onClick={likeDialogue} />
                    </div>
                    <span className="font-bold text-xs">
                      {likeCount} like{likeCount > 1 ? "s" : ""}
                    </span>
                  </div>
                </div>
              ) : dialogueError != null ? (
                <div
                  role="alert"
                  className="alert alert-error h-[4rem] text-center flex flex-row justify-center items-center shadow-lg m-4 max-w-5xl"
                >
                  {dialogueError.code != 100 ? (
                    <i className="bi bi-emoji-dizzy text-4xl"></i>
                  ) : (
                    <i className="bi bi-piggy-bank rotate-180 text-4xl"></i>
                  )}
                  <span>{dialogueError.error}</span>
                </div>
              ) : (
                <div className="w-full flex flex-col pt-8">
                  <div className="w-full flex flex-row justify-center items-center">
                    {dialogue_controls}
                    <div className="w-full max-w-5xl flex flex-row justify-center items-center drop-shadow-2xl rounded-lg bg-white">
                      <button
                        className="relative btn btn-square rounded-none rounded-l-lg btn-outline border-transparent hover:text-info z-[99]"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setNewMessage((m) => (m + " @Stoozi").trim());
                        }}
                        disabled={
                          disableInputs ||
                          remStooziMessages <= 0 ||
                          /@stoozi/i.test(newMessage)
                        }
                      >
                        <div className="absolute bottom-0 left-0 badge badge-xs badge-info -translate-x-1/2 translate-y-1/2">
                          {remStooziMessages}
                        </div>
                        <i className="bi bi-at text-3xl"></i>
                      </button>
                      <textarea
                        className="input rounded-none focus:outline-none focus:border-none w-full focus:shadow-inner"
                        value={newMessage}
                        onChange={handleInputChange}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            handleSendMessage(event);
                          }
                        }}
                        maxLength="150"
                        disabled={disableInputs}
                        placeholder={
                          recommendations.size > 0
                            ? "Type your own message or send one of the above options..."
                            : "Write your next message..."
                        }
                        wrap="soft"
                        rows="4"
                        style={{
                          resize: "none",
                        }}
                      />
                      {newMessage.length < 1 ? (
                        <VoiceRecorder
                          style={{
                            className: "relative rounded-none rounded-r-lg",
                          }}
                          disabled={disableInputs}
                          callback={(msg, audioId) => {
                            setNewMessage(msg);
                            setAudioMsg([msg, audioId]);
                          }}
                        />
                      ) : (
                        <button
                          className="btn btn-square btn-outline border-transparent rounded-none rounded-r-lg hover:text-error"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setNewMessage("");
                          }}
                        >
                          <i className="bi bi-x-lg text-3xl"></i>
                        </button>
                      )}
                    </div>
                    <button
                      className="btn btn-square drop-shadow-2xl btn-outline border-transparent rounded-lg  mx-2 "
                      onClick={handleSendMessage}
                      disabled={streamActive || newMessage.trim().length < 1}
                    >
                      <i
                        className={
                          "bi bi-send text-center text-3xl" +
                          (streamActive || newMessage.trim().length < 1
                            ? " btn-disabled"
                            : " text-primary")
                        }
                      ></i>
                    </button>
                  </div>
                  <div className="pt-2 px-4 w-full flex flex-row justify-center mb-2">
                    <div className=" w-full max-w-5xl flex flex-row justify-between">
                      <div className={connectionBadgeClass}>
                        {connectionStatus}
                      </div>
                      {connectionStatus === "Open" && streamActive != false && (
                        <progress className="progress my-2 ml-8 w-20 progress-secondary"></progress>
                      )}
                      <span className="label-text-alt">
                        {connectionStatus === "Open"
                          ? streamActive
                            ? "Waiting for other characters."
                            : newMessage.length < 1
                            ? "Your turn to respond."
                            : `(${newMessage.length}/150 characters remaining)`
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        </div>
      </div>
      {/* below is control modal */}
      <dialog
        id="dialogue_modal"
        className="modal modal-bottom sm:modal-middle"
      >
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
        <div className="modal-box flex flex-col relative !w-full !max-w-5xl overflow-hidden bg-neutral text-neutral-content rounded-lg">
          <form method="dialog">
            <button className="btn btn-sm btn-square btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          <div className="flex w-full items-center">
            <h3 className="font-bold text-xl">Details and Playback Controls</h3>
            <div
              className="tooltip tooltip-bottom"
              data-tip="Click Message Avatars To Hear Messages Individually. Use The Controls Below To Play the Conversation."
            >
              <i className="bi bi-info-circle m-4"></i>
            </div>
          </div>

          {narrativeError == null && narrativeValue != null && (
            <div className="card w-full bg-base-100 text-base-content m-4 mx-auto mb-8 flex-grow overflow-y-auto ">
              <div className="card-body ">
                <div className="flex flex-row flex-wrap">
                  <h2 className="card-title mr-auto">Narrative Details</h2>
                  <div className="badge badge-primary mr-4">
                    Difficulty: {narrativeValue.difficulty}
                  </div>
                  <div className="badge badge-secondary">
                    Language: {narrativeValue.learning_language}
                  </div>
                </div>
                <h4 className="font-bold text-lg">
                  <ProcessEncoding {...narrativeValue.title} />
                  {}
                </h4>
                <p className="text-sm">
                  {narrativeValue.title.native_language_content}
                </p>
              </div>
              <div
                onClick={() => setNarrativeSpeakersShow((s) => !s)}
                className="flex flex-row justify-between items-center border border-base-300 bg-base-200 "
              >
                <p className="text-md m-2">
                  {narrativeValue.speakers.length} Speakers
                </p>
                <ChevronDownIcon
                  className={
                    "h-6 w-6 m-2" + (narrativeSpeakersShow ? " rotate-180" : "")
                  }
                />
              </div>
              <div className={narrativeSpeakersShow ? "" : "hidden"}>
                <SpeakersList
                  speakers={narrativeValue.speakers}
                  getAvatar={getAvatar}
                />
              </div>
            </div>
          )}
          <div className=" w-full">
            <div className="form-control flex flex-column md:flex-row justify-around mx-auto mb-4 bg-base-100 text-base-content rounded-xl pb-1">
              <label className="label cursor-pointer">
                <span className="label-text mx-2 text-xs">
                  Show Message Text
                </span>
                <input
                  type="checkbox"
                  checked={showMessageText}
                  className="checkbox"
                  onChange={() => setShowMessageText(!showMessageText)}
                />
              </label>
              <label className="label cursor-pointer">
                <span className="label-text mx-2 text-xs">
                  Play Translations
                </span>
                <input
                  type="checkbox"
                  checked={playTranslation}
                  className="checkbox"
                  onChange={() => setPlayTranslation(!playTranslation)}
                />
              </label>
              <label className="label cursor-pointer">
                <span className="label-text mx-2 text-xs">
                  Play My Recordings
                </span>
                <input
                  type="checkbox"
                  checked={playUser}
                  className="checkbox"
                  onChange={() => setPlayUser(!playUser)}
                />
              </label>
              {/* <label className="form-control">
                <div className="label">
                  <span className="label-text-alt text-xs">
                    Pause Between Messages
                  </span>
                </div>
                <select
                  className="select select-bordered"
                  onChange={(event) => {
                    setPauseTime(parseInt(event.target.value));
                  }}
                >
                  <option value={1}>No Pause</option>
                  <option value={2}>2 Second</option>
                  <option value={5}>5 Seconds</option>
                  <option value={10}>10 Seconds</option>
                  <option value={20}>20 Seconds</option>
                  <option value={30}>30 Seconds</option>
                </select>
              </label> */}
            </div>
            <div className="flex flex-row items-center w-full mx-auto mb-4">
              <div className="flex flex-col items-center w-full mr-4 mx-auto mb-4">
                <input
                  type="range"
                  min={10}
                  step="10"
                  max="100"
                  value={volume}
                  onChange={(e) => setVolume(e.target.value)}
                  className="range range-warning"
                />
                <div className="relative w-full flex items-center justify-between text-xs px-2">
                  <i className="bi bi-volume-down-fill text-xl absolute left-0" />
                  <span className="text-transparent">|</span>
                  <span>|</span> <span>|</span> <span>|</span> <span>|</span>{" "}
                  <span>|</span> <span>|</span> <span>|</span>
                  <span>|</span>
                  <span className="text-transparent">|</span>
                  <i className="bi bi-volume-up-fill text-xl absolute right-0" />
                </div>

                <i className=" text-2xl m-2 my-auto"></i>
              </div>
              <div className="flex flex-col items-center w-full ml-4 mx-auto mb-4">
                <input
                  type="range"
                  min={50}
                  step={25}
                  max={150}
                  value={pace}
                  onChange={(e) => setPace(e.target.value)}
                  className="range range-success"
                />
                <div className="relative w-full flex justify-between text-xs px-2">
                  <p className="text-xs absolute left-0">Slow</p>
                  <span className="text-transparent">|</span>
                  <span>|</span> <span>|</span>
                  <span>|</span>
                  <span className="text-transparent">|</span>
                  <p className="text-xs absolute right-0">Fast</p>
                </div>
              </div>
            </div>
            <div className=" flex w-full h-20 max-h-[10vh] m-auto justify-between">
              <button
                className="btn w-[33%] h-full hover:glass"
                onClick={() => handleMasterPrevNext(-1)}
                disabled={!masterPlayArgs.prev.playable}
              >
                <div className="flex flex-col">
                  <i className="bi bi-skip-backward-fill text-4xl"></i>
                  <p className="text-xs">Previous Message</p>
                </div>
              </button>
              <button
                className="btn w-[33%] h-full hover:glass"
                onClick={() => {
                  setMasterPlayArgs((a) => ({
                    ...a,
                    single: false,
                    play: !masterPlayArgs.play,
                  }));
                }}
                disabled={!masterPlayArgs.play && !masterPlayArgs.playable}
              >
                <div className="flex flex-col">
                  {!playArgs.play ? (
                    <>
                      <i className="bi bi-play-fill text-4xl"></i>
                    </>
                  ) : (
                    <div className="flex flex-col justify-center items-center">
                      <i
                        className={
                          "bi bi-pause-fill " +
                          (waiting ? "text-2xl" : "text-4xl")
                        }
                      ></i>
                      {waiting && (
                        <span className="loading loading-dots loading-xs"></span>
                      )}
                    </div>
                  )}
                  <p className="text-xs">{waiting ? "waiting" : "AutoPlay"}</p>
                </div>
              </button>
              <button
                className="btn w-[33%] h-full hover:glass"
                onClick={() => handleMasterPrevNext(1)}
                disabled={!masterPlayArgs.next.playable}
              >
                <div className="flex flex-col">
                  <i className="bi bi-skip-forward-fill text-4xl"></i>
                  <p className="text-xs">Next Message</p>
                </div>
              </button>
            </div>
          </div>
        </div>
      </dialog>
      <dialog
        id="comments_modal"
        className="modal modal-bottom sm:modal-middle"
      >
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
        <div className="modal-box flex flex-col relative !w-full !max-w-5xl overflow-hidden bg-neutral text-neutral-content rounded-lg">
          <form method="dialog">
            <button className="btn btn-sm btn-square btn-ghost absolute right-2 top-2">
              ✕
            </button>
            <div className="flex w-full items-center">
              <h3 className="font-bold text-xl">Dialogue Comments</h3>
              {showComments && <CommentSection dialogueId={dialogueId} />}
            </div>
          </form>
        </div>{" "}
      </dialog>
    </>
  );
}
