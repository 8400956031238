import React from "react";
import LoggedInHeader from "../components/LoggedInHeader";
import { useState, useEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import twoSpeechBubbles from "../lottie/twoSpeechBubbles.json";
import { backendURL } from "../constants/urls";
import DialogueCard, { DialogueCardSkeleton } from "../components/DialogueCard";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import NarrativeHeader from "../components/NarrativeHeader";
import useNarrativeData from "../utils/useNarrativeData";

export default function NarrativePage() {
  const { narrativeId } = useParams();
    const {
    speakerColors,
    speakerAvatars,
    narrativeValue,
    narrativeLoading,
    narrativeError,
    getAvatar,
  } = useNarrativeData(narrativeId);


  const [isLoading, setIsLoading] = useState(false);
  const [dialogues, setDialogues] = useState();
  const [error, setError] = useState();
  const dialoguesUrl = `${backendURL}/narratives/${narrativeId}/dialogues/`;
  const narrativeHeader = <NarrativeHeader narrative={narrativeValue} getAvatar={getAvatar}/>
  const skeletons = (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 gap-4">{narrativeHeader}
        <div className="divider p-6">Dialogues</div>
        {[1,2,3].map(i=><DialogueCardSkeleton key={i}/>)}
      </div>
    </div>
  );
  const noDialogues = (
    <div className="container mx-auto p-4">{narrativeHeader}
      <div className="divider p-6">Dialogues</div>
      <div className="grid grid-cols-1 gap-4">
        <h1 className="mt-6 text-center text-3xl font-extrabold">
          This narrative doesn't have any dialogues yet.
        </h1>
        <Player
          src={twoSpeechBubbles}
          style={{ height: "500px" }}
          autoplay
          loop
        />
      </div>
    </div>
  );
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      const fetchDialogues = async () => {
        await fetch(dialoguesUrl, {
          credentials: "include",
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.json().message);
            }
            return response.json();
          })
          .then((data) => setDialogues(data))
          .catch((err) => setError(err));
      };
      fetchDialogues();
      setIsLoading(false);
    }, 500);
  }, []);
  return (
    <>
      <LoggedInHeader />
      {isLoading ? (
        skeletons
      ) : dialogues ? (
        dialogues.length ? (
          <div className="container mx-auto p-4">{narrativeHeader}
            <div className="divider p-6">Dialogues</div>
            <div className="grid grid-cols-1 gap-4">
              {dialogues.map((n) => (
                <DialogueCard key = {n.id} id={n.id} speakers={narrativeValue.speakers} getAvatar={getAvatar} contextNarrativeId={null} />
              ))}
            </div>
          </div>
        ) : (
          noDialogues
        )
      ) : (
        skeletons
      )}
    </>
  );
}
