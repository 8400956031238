import { backendURL } from "../constants/urls";
import { ProcessEncoding } from "../utils/learningLanguageHelpers";

export default function StandaloneAudioMessage({
  message,
}) {

  return (
    <div className="flex flex-col p-4 w-full h-60 rounded-3xl bg-primary text-primary-content justify-around items-center">
      
      <div
          tabIndex={0}
          className="collapse collapse-plus"
        >
          
          <div className="collapse-title text-sm font-medium flex flex-row justify-around items-center">
            <ProcessEncoding {...message.content}/>
          </div>
          <div className="collapse-content text-sm text-center">
          <ProcessEncoding {...message.content} learning={false}/>
      
          </div>
        </div>
        
      <audio
        id={message.audio_id}
        controls
        style={{ width: "90%" }}
        controlsList="nodownload"
      >
        <source src={`${backendURL}/audio/${message.audio_id}`} type="audio/mpeg" />
      </audio>
    </div>
  );
}
