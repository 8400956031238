import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { backendURL } from "../constants/urls";
import { Formik, Form } from "formik";
import { Player } from "@lottiefiles/react-lottie-player";
import planeAndGlobe from "../lottie/planeAndGlobe.json";
import { StepButton } from "./forms/StepButton";
import { FormProgressBar } from "./forms/FormProgressBar";
import CreateNarrativePart1 from "./forms/CreateNarrativePart1";
import CreateNarrativePart2 from "./forms/CreateNarrativePart2";
import CreateNarrativePart3 from "./forms/CreateNarrativePart3";
import CreateNarrativePart4 from "./forms/CreateNarrativePart4";
import CreateNarrativePart5 from "./forms/CreateNarrativePart5";
import useSettings from "../utils/useSettings";
import useLevel from "../utils/useLevel";

function useInitialNarrativeValues(levelProgress) {

  const { settings: data, error, isLoading } = useSettings();
  if (levelProgress==null){
    return null
  }
  if (data) {
    let speakers = [];
    if (!!data.first_name && !!data.bio) {
      speakers = [{ name: data.first_name, bio: data.bio }];
    }
    return {
      difficulty: levelProgress.narratives.difficulty.at(-1),
      length: levelProgress.narratives.length.at(-1),
      vocab_phrases_and_topics: [],
      total_speakers: 2,
      speakers: speakers,
      learning_language: data.default_learning_language,
      me: "0",
    };
  }
}

export default function CreateNarrativeMultistepForm() {
  const navigate = useNavigate();
  const [isCreatingNarrative, setIsCreatingNarrative] = useState(false);
  const {level: levelProgress} = useLevel();

  const initialValues = useInitialNarrativeValues(levelProgress);

  const [, setError] = useState("");
  const [step, setStep] = useState(1);
  if (levelProgress==null){
    return <span className="loading loading-spinner loading-lg"></span>
  }

  const createNarrativeURL = `${backendURL}/narrative/`;
  const handleNarrativeCreate = async (data) => {
    setIsCreatingNarrative(true);
    await fetch(createNarrativeURL.toString(), {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((newNarrative) => navigate(`/narratives/${newNarrative.id}`))
      .catch((error) => {
        setError(error ? JSON.stringify(error) : "");
      });
    setIsCreatingNarrative(false);
  };

  if (!initialValues) {
    return <span className="loading loading-spinner loading-lg"></span>;
  }
  const renderFormStep = (step, values, errors, setValues, touched) => {
    switch (step) {
      case 1:
        return (
          <CreateNarrativePart1
            values={values}
            errors={errors}
            setValues={setValues}
            touched={touched}
          />
        );
      case 2:
        return (
          <CreateNarrativePart2
            values={values}
            errors={errors}
            setValues={setValues}
            touched={touched}
            numSpeakers={levelProgress.narratives.n_speakers}
          />
        );
      case 3:
        return (
          <CreateNarrativePart3
            values={values}
            errors={errors}
            setValues={setValues}
            touched={touched}
          />
        );
      case 4:
        return (
          <CreateNarrativePart4
            values={values}
            errors={errors}
            setValues={setValues}
            touched={touched}
            difficulties={levelProgress.narratives.difficulty}
          />
        );
      case 5:
        return (
          <CreateNarrativePart5
            values={values}
            errors={errors}
            setValues={setValues}
            lengths={levelProgress.narratives.length}
          />
        );
      default:
        return (
          <CreateNarrativePart1
            values={values}
            errors={errors}
            setValues={setValues}
            touched={touched}
          />
        );
    }
  };
  const handleStep = () => setStep((step) => step + 1);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        let num_ai_speakers;
        switch (values.ai_speakers) {
          case "One":
            num_ai_speakers = 1;
            break;
          case "Two":
            num_ai_speakers = 2;
            break;
          case "Three":
            num_ai_speakers = 3;
            break;
        }
        const total_num_speakers = num_ai_speakers + values.speakers.length;
        const data = {
          difficulties: [values.difficulty],
          lengths: [values.length],
          vocab_phrases_and_topics: values.vocab_phrases_and_topics.map(
            (data) => data.value
          ),
          speakers: values.speakers.map((s, i) => ({
            name: s.name,
            bio: s.bio,
            is_student: i === parseInt(values.me) ? true : false,
          })),
          learning_language: values.learning_language,
          num_speakers: total_num_speakers >= 2 ? total_num_speakers : 2,
        };
        if (step == 5) {
          handleNarrativeCreate(data);
        } else {
          handleStep(data);
          setSubmitting(false);
        }
      }}
    >
      {({ values, errors, setValues, touched, isSubmitting }) =>
        isCreatingNarrative ? (
          <>
            <h1 className="mt-6 text-center text-5xl font-extrabold">
              Creating Narrative
            </h1>
            <h2 className="py-6 mt-6 text-center text-3xl font-extrabold">
              Creating your perfect learning narrative!
            </h2>

            <Player
              src={planeAndGlobe}
              style={{ height: "300px" }}
              autoplay
              loop
            />
          </>
        ) : isSubmitting ? (
          <></>
        ) : (
          <div className="flex justify-center items-center py-8">
            <div className="rounded-lg shadow-lg max-w-2xl w-full bg-base-200">
              <p className="text-4xl text-center py-6">
                Create a New Narrative
              </p>
              <div className="text-center">
                <FormProgressBar step={step} />
              </div>
              <Form className="p-4">
                {renderFormStep(step, values, errors, setValues, touched)}
                <StepButton step={step} setStep={setStep} />
              </Form>
            </div>
          </div>
        )
      }
    </Formik>
  );
}
