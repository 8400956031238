import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../App.css';

export default function NotFoundPage() {
  return (
    <>
      {"404 not found..."}
    </>
  );
}
