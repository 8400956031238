import React, { useState } from "react";
import { useMeasure } from "react-use";
import useLevel from "../utils/useLevel";

const REWARD_NAMES = {
  n_speakers: (v) => `Create narratives with ${v} speakers.`,
  difficulty: (v) => `Create ${v} level narratives.`,
  length: (v) => `${v} narratives.`,
};

const ProgressBar = () => {
  const [dropOpen, setDropOpen] = useState(true);

  const [ref, { width }] = useMeasure();
  const { level: levelProgress } = useLevel();
  if (levelProgress == null) {
    return <span className="loading loading-spinner loading-lg"></span>;
  }
  const {
    level,
    overtime,
    xp,
    usage,
    remaining_xp_goal,
    level_start_xp,
    xp_to_next_level,
    rewards,
  } = levelProgress;
  const xp_into_level = xp - level_start_xp;
  let end = null;
  if (remaining_xp_goal > xp_to_next_level) {
    end = xp + remaining_xp_goal;
  } else {
    end = xp + xp_to_next_level;
  }

  let base_goal_rem = remaining_xp_goal - overtime;
  const overtime_rem = Math.max(
    0,
    base_goal_rem < 0 ? overtime + base_goal_rem : overtime
  );
  base_goal_rem = Math.max(0, base_goal_rem);
  const total_xp = end - level_start_xp;

  const levelup_xp = xp - level_start_xp + xp_to_next_level;

  const level_end = levelup_xp >= total_xp;

  const xp_width = (xp_into_level / total_xp) * width;
  const goal_width = (base_goal_rem / total_xp) * width;
  const overtime_width = (overtime_rem / total_xp) * width;
  const next_level_width = (levelup_xp / total_xp) * width;

  const nextRewards = Object.keys(rewards)
    .toSorted((a, b) => a - b)
    .map((k) => (
      <div className="stat" key={k}>
        <div className="stat-title">Unlock At</div>
        <div className="stat-value">Level {k}</div>
        <div className="stat-desc">
          <ul>
            {Object.keys(rewards[k]).map((rk) => (
              <li>
                {REWARD_NAMES[rk](rewards[k][rk].map((v) => `${v}`).join(", "))}
              </li>
            ))}
          </ul>
        </div>
      </div>
    ));

  return (
    <>
      <div
        className="flex flex-col w-full min-w-[40vw] max-w-5xl justify-center items-center hover:cursor-pointer"
        // onClick={() => setDropOpen((o) => !o)}
      >
        <div className=" w-full flex flex-row justify-center items-center">
          <div className="flex flex-col h-full justify-center items-center">
            <h3 className="text-xl mx-2 bg-slate-300 p-1 rounded-full w-10 text-center h-10">
              {level}
            </h3>
          </div>
          <div
            className="relative w-full h-8 bg-gray-200 rounded-lg "
            ref={ref}
          >
            {!level_end && (
              <h3
                className="absolute top-[50%] -translate-y-[50%] my-auto z-50  text-xs badge badge-sm bg-yellow-300"
                style={{ left: `${next_level_width}px` }}
              >
                {" "}
                <div
                  className="tooltip tooltip-bottom"
                  data-tip={`Level-Up: ${xp_to_next_level}XP`}
                >
                  {level + 1}
                </div>
              </h3>
            )}
            <div className="absolute top-0 left-0 w-full h-full flex flex-row rounded-lg overflow-hidden">
              <div
                className="text-center text-xs h-full bg-primary glass"
                style={{ width: `${xp_width}px` }}
              ></div>

              <div
                className="text-center text-xs h-full bg-secondary glass"
                style={{ width: `${goal_width}px` }}
              ></div>

              <div
                className="text-center text-wrap text-xs h-full bg-accent glass"
                style={{ width: `${overtime_width}px` }}
              ></div>
            </div>
            <div className="w-full h-full">
              <div className="absolute top-0 left-0  h-full flex flex-row">
                {xp_width > 0 ? (
                  <div
                    className="tooltip tooltip-bottom"
                    data-tip={`XP: ${xp_into_level}XP`}
                    style={{ width: `${xp_width}px` }}
                  ></div>
                ) : null}
                {goal_width > 0 ? (
                  <div
                    className="tooltip tooltip-bottom"
                    data-tip={`Month Goal: ${base_goal_rem}XP`}
                    style={{ width: `${goal_width}px` }}
                  ></div>
                ) : null}
                {overtime_width > 0 ? (
                  <div
                    className="tooltip tooltip-bottom"
                    data-tip={`Overtime: ${overtime_rem}XP`}
                    style={{ width: `${overtime_width}px` }}
                  ></div>
                ) : null}
              </div>
            </div>
          </div>
          {level_end ? (
            <h3 className="text-xl mx-2 bg-yellow-300 p-1 rounded-lg">
              Level {level + 1}
            </h3>
          ) : (
            <span className="text-xl mx-2  p-1 rounded-full w-10 text-center h-10">
              <i className="bi bi-award-fill text-center "></i>
            </span>
          )}
        </div>
      </div>
      {dropOpen && (
        <div className="stats stats-vertical lg:stats-horizontal  shadow m-4">
          <div className="stat">
            <div className="stat-figure text-primary">
              <i className="text-3xl bi bi-trophy"></i>
            </div>
            <div className="stat-title">Level</div>
            <div className="stat-value">{level}</div>
            <div className="stat-desc">Total XP: {xp}</div>
          </div>
          <div className="stat">
            <div className="stat-figure text-yellow-300">
              <i className="text-3xl bi bi-arrow-up-circle"></i>
            </div>
            <div className="stat-title">Level-Up</div>
            <div className="stat-value">{level + 1}</div>
            <div className="stat-desc">Gain {xp_to_next_level}XP</div>
          </div>
          <div className="stat">
            <div className="stat-figure text-secondary">
              <i className="text-3xl bi bi-bullseye"></i>
            </div>
            <div className="stat-title">Goal Remaining</div>
            <div className="stat-value">{remaining_xp_goal}</div>
            <div className="stat-desc">{usage}XP This Month</div>
          </div>
          <div className="stat">
            <div className="stat-figure text-accent">
              <i className="text-3xl bi bi-fire"></i>
            </div>
            <div className="stat-title">Overtime</div>
            <div className="stat-value">{overtime_rem}</div>
            <div className="stat-actions">
              <a className="btn btn-xs text-xs btn-outline hover:btn-accent" href="/subscribe">
                Add Overtime
              </a>
            </div>
          </div>
        </div>
      )}
      {nextRewards && nextRewards.length > 0 && (
        <>
          <div className="stats stats-vertical lg:stats-horizontal ">
            {nextRewards[0]} <div className="stat"></div>
            <button
              className="btn btn-sm m-auto"
              onClick={() =>
                document.getElementById("rewards-modal").showModal()
              }
            >
              See More
            </button>
            <dialog id="rewards-modal" className="modal">
              <div className="modal-box">
                {" "}
                <form method="dialog">
                  {/* if there is a button in form, it will close the modal */}
                  <button className="btn btn-lg btn-circle btn-ghost absolute right-2 top-2 text-3xl">
                    ✕
                  </button>
                </form>
                <div className="stats stats-vertical m-auto">{nextRewards}</div>{" "}
              </div>
              <form method="dialog" className="modal-backdrop">
                <button>close</button>
              </form>
            </dialog>
          </div>
        </>
      )}
    </>
  );
};

export default ProgressBar;
