import React from "react";

export const StepButton = ({ step, setStep }) => {
  switch (step) {
    case 1:
      return (
        <div className="card-actions justify-end mt-4">
          <button type="button" className="btn btn-secondary hide">
            Back
          </button>
          <button type="submit" className="btn btn-primary">
            Continue
          </button>
        </div>
      );
    case 2:
    case 3:
    case 4:
      return (
        <div className="card-actions flex justify-between items-center mt-4">
          <button
            type="button"
            onClick={() => setStep(step - 1)}
            className="btn btn-secondary"
          >
            Back
          </button>
          <button type="submit" className="btn btn-primary">
            Continue
          </button>
        </div>
      );
    case 5:
      return (
        <div className="card-actions flex justify-between items-center mt-4">
          <button
            type="button"
            onClick={() => setStep(step - 1)}
            className="btn btn-secondary"
          >
            Back
          </button>
          <button type="submit" className="btn btn-accent">
            Submit
          </button>
        </div>
      );
    default:
      return <></>;
  }
};
