import { useSpring, animated, to } from "@react-spring/web";
import { createUseGesture, dragAction, pinchAction } from "@use-gesture/react";
import { useState, useEffect, useCallback, useRef } from "react";
import SwipeIcon from "./SwipeIcon";
import { useKeyPressEvent, useLongPress } from "react-use";
import { sleep } from "../utils/datetimeHelpers";
const clamp = (val, min, max) => Math.min(Math.max(val, min), max);

const side = {
  backfaceVisibility: "hidden",
  position: "absolute",
  top: 0,
  height: "100%",
  width: "100%",
  overflow: "hidden",
};

const transFront = (x, r, s) =>
  `perspective(1500rem) rotateY(${r}deg) rotateZ(${x / 10}deg) scale(${s})`;
const transBack = (x, r, s) =>
  `perspective(1500rem) rotateY(${r}deg) rotateY(180deg) rotateZ(${
    x / 10
  }deg) scale(${s})`;

//props:
//handleGone: what to do when the card is dispatched off screen
// height, width
//front: front content
//back: back content
const RotatableCard = (props) => {
  let [flipped, setFlipped] = useState(false);
  let [deleting, setDeleting] = useState(false);
  let [gone, setGone] = useState(null);
  const [decision, setDecision] = useState(null);

  const handleMouseDown = useCallback(() => {
    setDeleting(true);
    timer.current = setTimeout(() => {
      console.log(deleting, gone, decision);
      handleDispatch(-100, "ignore");
    }, 2000); // Adjust the duration (in milliseconds) for the long press
  }, []);
  const timer = useRef(null);
  const handleMouseUp = useCallback(() => {
    clearTimeout(timer.current);
    setDeleting(false);
    handleDispatch(-100, "skip");
  }, []);
  const handleMouseLeave = useCallback(() => {
    clearTimeout(timer.current);
    setDeleting(false);
  }, []);
  useKeyPressEvent("ArrowLeft", () => props.is_top && handleDispatch(-1));
  useKeyPressEvent("ArrowRight", () => props.is_top && handleDispatch(1));
  useKeyPressEvent(" ", () => {
    props.is_top && FlipTouch();
  });

  const useGesture = createUseGesture([dragAction, pinchAction]);

  function handleDispatch(dir, dec) {
    const x = Math.sign(dir) * (10 + window.innerWidth);
    setGone(dir);
    setDecision(dec != null ? dec : dir < 0 ? "repeat" : "good");
    api.start(() => ({
      x,
      // immediate: dir===0,
      // rot,
      // scale,
      config: { friction: 50, tension: 500, duration: 550 },
    }));
  }

  useEffect(() => {
    // console.log(gone)
    if (decision && props.handleGone) {
      props.handleGone(decision);
    }
  }, [gone]);

  //   useEffect(() => {
  //     console.log(props.count)
  //     api.start(() => ({
  //         x:0,
  //         rot:0,
  //         scale:1,
  //         // config: { friction: 50, tension: 500 },
  //         immediate: true,
  //       }));
  //   }, [props.count]);

  const [{ x, y, scale, rot }, api] = useSpring(() => ({
    from: {
      x: 0,
      y: 0,
      scale: 1,
      rot: 0,
    },
  }));

  const bindGesture = useGesture({
    onDrag: ({ down, movement: [xDelta], velocity, event }) => {
      if (props.canHover) {
        return;
      }
      const target = event.target;
      //get width of card. swiping the width of the card should translate to 180deg
      const width = target.clientWidth;

      const delta = Math.abs(xDelta);
      //direction is either left or right
      const dir = Math.sign(xDelta);

      const rot_start = flipped * 180;
      let rot = rot_start;
      // defaults
      //xGone is where the card moves when deleted (in this case 500 pixels offscreen in the direction of motion)
      const xGone = (50 + window.innerWidth) * dir;
      //x will be at position default of 0 if not gone
      let x = gone ? xGone : 0;
      let scale = down ? 1.1 : 1;

      if (dir) {
        if (
          props.canHover ? flipped && down : flipped && down && dir === flipped
        ) {
          // ) {
          //if the card is flipped and the user is interacting in the same direction as the flip we will be working towards a delete
          const triggerDelete = velocity > 5;

          x = xDelta;

          if (triggerDelete || Math.abs(x) > window.innerWidth * 0.25) {
            x = xGone;
            gone = true;
            setGone(x);
          }
        } else {
          rot = rot_start + (xDelta / width) * 180;
          rot = clamp(rot, -180, 180);
          const triggerFlip = Math.abs(rot - rot_start) > 126 || velocity > 1;
          if (!down && triggerFlip) {
            if (flipped) {
              flipped = false;
              setFlipped(false);
            } else {
              flipped = dir;
              setFlipped(dir);
            }
            rot = flipped * 180;
          } else if (!down) {
            rot = rot_start;
          }
        }
      }
      if (!gone) {
        api.start(() => ({
          x,
          rot,
          scale,
          config: { friction: 50, tension: 500 },
          //   immediate: true,
        }));
      }
    },
  });

  const FlipTouch = (e) => {
    // if (e != null) {
    //   e.preventDefault();
    //   e.stopPropagation();
    // }
    // api.start(() => ({
    //     rot: flipped * 180,
    //     immediate: true,
    //   }));
    api.start(() => ({
      rot: flipped || false ? 0 : 180,
      config: { friction: 50, tension: 500 },
    }));
    setFlipped((flip) => {
      return !flip;
    });
  };

  function onHover(v) {
    return () => {
      // props.onMouseOver();
      api.start({ scale: v ? 1.1 : 1 });
    };
  }

  return (
    <div className="flex flex-col items-center justify-around">
      <animated.div
        className="card"
        {...bindGesture()}
        onClick={(e) => {
          return props.canHover ? FlipTouch(e) : null;
        }}
        onMouseOver={() => onHover(true)}
        onMouseOut={() => onHover(false)}
        style={{
          touchAction: "none",
          // position: 'relative',
          height: props.height || "30rem",
          width: props.width || "45rem",
          zIndex: to([x], (x) => (Math.abs(x) ? 100 : 1)),
          transform: to([x], (x) => `translateX(${x}px)`),
          display: to([x], (x) =>
            window ? (Math.abs(x) > window.innerWidth ? "none" : "") : ""
          ),
        }}
      >
        <animated.div
          className="card bg-base-100 cursor-pointer shadow-lg"
          style={{
            ...side,
            transform: to([x, rot, scale], transFront),
          }}
        >
          {props.front}
        </animated.div>
        <animated.div
          className="card bg-base-300 cursor-pointer shadow-lg"
          style={{
            ...side,
            transform: to([x, rot, scale], transBack),
          }}
        >
          {props.back}
        </animated.div>
      </animated.div>
      {props.is_top && (
        <div className="flex flex-col w-full items-center">
          <div className="flex flex-row mx-auto justify-around mt-14">
            <div className="flex flex-col w-full items-center">
              <button
                className="btn btn-warning btn-circle w-36 m-4"
                onClick={() => handleDispatch(-1)}
              >
                <i className="bi bi-x-lg text-3xl"></i>
              </button>
              {props.canHover ? (
                <kbd className="kbd kbd-lg rounded-md drop-shadow-md">◀︎</kbd>
              ) : (
                <div className="w-10" style={{ transform: "rotateY(180deg)" }}>
                  <SwipeIcon />
                </div>
              )}
              <p className="text-xs">Wrong</p>
            </div>
            <div className="flex flex-col w-full items-center">
              <button
                className={
                  "relative overflow-hidden btn btn-circle btn-info btn-xlg w-16 h-16 m-4 border-none"
                }
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
              >
                <div
                  className={
                    "bg-error absolute transform z-5 transition linear " +
                    (deleting ? "translate-y-0" : "translate-y-full") +
                    " w-full h-full transition duration-[2s]"
                  }
                ></div>
                <i
                  className={
                    "bi text-3xl z-10 " +
                    (deleting ? "bi-trash" : "bi-eye-slash")
                  }
                ></i>
              </button>
              <p className="text-xs w-[4rem] text-center">
                {deleting ? "Ignoring Forever" : "Skip (or Hold)"}
              </p>
            </div>
            <div className="flex flex-col w-full items-center">
              <button
                className="btn btn-success btn-circle btn-xlg w-36 m-4"
                onClick={() => handleDispatch(1)}
              >
                <i className="bi bi-check-lg text-3xl"></i>
              </button>
              {props.canHover ? (
                <kbd className="kbd kbd-lg rounded-md drop-shadow-md">▶︎</kbd>
              ) : (
                <div className="w-10">
                  <SwipeIcon />
                </div>
              )}
              <p className="text-xs">Right</p>
            </div>
          </div>

          <div className="flex flex-col items-center w-full justify-center m-2">
            {props.canHover ? (
              <>
                <kbd className="kbd kbd-lg rounded-md drop-shadow-md">
                  Spacebar
                </kbd>
                <p className="divider w-20 mx-auto text-xs m-2">or</p>
                <p className="text-sm">Click Card To Flip</p>
              </>
            ) : (
              <div
                className="tooltip"
                data-tip="Swipe Card to Flip. Swipe in the same direction to make a decision or use the buttons. Swipe Back to Flip Back."
              >
                Swipe Card to Flip<i className="bi bi-info-circle-fill text-xl mx-2"></i>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RotatableCard;
