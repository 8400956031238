import React from "react";
import LoggedInHeader from "./LoggedInHeader";
import { Formik, Field, FieldArray, Form } from "formik";
import { Player } from "@lottiefiles/react-lottie-player";
import { languageNames } from "../constants/formFields";
import { backendURL } from "../constants/urls";
import { useNavigate } from "react-router-dom";

export default function NewUserSettingsForm({ user }) {
  const navigate = useNavigate();
  const handleSubmit = async (data) => {
    await fetch(`${backendURL}/settings/`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      credentials: "include",
    })
      .then((response) => response.json())
      .then(() => {
        window.location.reload();
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      <LoggedInHeader />
      <Formik
        initialValues={{
          bio: user.bio,
          default_learning_language: user.default_learning_language,
          native_language: user.native_language,
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
        }}
      >
        {({ values, errors, isSubmitting }) => (
          <div className="flex justify-center items-center min-h-screen ">
            <div className="rounded-lg shadow-lg max-w-2xl w-full bg-base-200">
              <p className="text-4xl text-center py-6">
                Let's Get Started With Stoozi
              </p>
              <Form className="p-4">
                <div className="mb-6">
                  <label className="label">
                    <span className="label-text text-2xl">Bio</span>
                  </label>
                  <Field
                    required={true}
                    name="bio"
                    placeholder="e.g. I'm a dog-walker in the Sahara desert"
                    className="p-2 mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                  <span className="label-text text-sm italic">
                    Describe yourself in a short sentence. This will be used for
                    your character in narratives.
                  </span>
                </div>
                <div className="form-control">
                  <div className="mb-6">
                    <label className="label">
                      <span className="label-text text-2xl">
                        Native Language
                      </span>
                    </label>
                    <Field
                      as="select"
                      name="native_language"
                      className="w-full input input-bordered"
                    >
                      {languageNames.map((l) => (
                        <option>{l}</option>
                      ))}
                    </Field>
                    <span className="label-text text-sm italic">
                      This will be used as the language for translations, tips,
                      and corrections.
                    </span>
                  </div>
                </div>
                <div className="form-control">
                  <div className="mb-6">
                    <label className="label">
                      <span className="label-text text-2xl">
                        Default Learning Language
                      </span>
                    </label>
                    <Field
                      as="select"
                      name="default_learning_language"
                      className="w-full input input-bordered"
                    >
                      {languageNames.map((l) => (
                        <option>{l}</option>
                      ))}
                    </Field>
                    <div className="label-text text-sm italic">
                      This will be used as the language for narratives and
                      dialogues.
                    </div>
                    <div className="label-text text-sm italic">
                      (Don't worry, you can easily change this later)
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary mt-4">
                  Save
                </button>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}
