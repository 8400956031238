function getUTCDate() {
    const now = new Date();
    return new Date(now.getTime() + now.getTimezoneOffset() * 60000);
}
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
function utcToLocalDate(utcDateString) {
  // Create a new Date object from the UTC date string
  const utcDate = new Date(utcDateString);

  // Get the user's timezone offset in minutes
  const timezoneOffset = utcDate.getTimezoneOffset();

  // Convert the UTC date to local time by adding the timezone offset
  const localDate = new Date(utcDate.getTime() - timezoneOffset * 60000);

  return localDate;
}

const DateTimeConverter = ({ utcDateTime }) => {
    const timeDifference = getTimeDifference(utcDateTime);

    return <time>{timeDifference}</time>;
  };

  const getTimeDifference = (utcDateTime) => {
    const currentTime = getUTCDate();
    const convertedDateTime = new Date(utcDateTime);
    const timeDifferenceInMilliseconds = currentTime - convertedDateTime;
    const timeDifferenceInMinutes = Math.floor(
      timeDifferenceInMilliseconds / (1000 * 60)
    );

    if (timeDifferenceInMinutes < 1) {
      return "<1 minute";
    } else if (timeDifferenceInMinutes < 60) {
      return `${timeDifferenceInMinutes} minute${
        timeDifferenceInMinutes > 1 ? "s" : ""
      } ago`;
    } else if (timeDifferenceInMinutes < 1440) {
      const timeDifferenceInHours = Math.floor(timeDifferenceInMinutes / 60);
      return `${timeDifferenceInHours} hour${
        timeDifferenceInHours > 1 ? "s" : ""
      } ago`;
    } else {
      const timeDifferenceInDays = Math.floor(timeDifferenceInMinutes / 1440);
      return `${timeDifferenceInDays} day${
        timeDifferenceInDays > 1 ? "s" : ""
      } ago`;
    }
  };

  export {getUTCDate, DateTimeConverter, getTimeDifference, sleep, utcToLocalDate}
