import React, { useState, useEffect } from 'react';
import { backendURL } from '../constants/urls';

const CommentSection = ({ dialogueId }) => {
  const [page, setPage] = useState(1);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchComments = async () => {
    const url = `${backendURL}/dialogues/${dialogueId}/comments/${page}`;
    const response  = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
    const newComments = await response.json();
    console.log("new", newComments)
    setComments([...comments,...newComments]);
    setHasMore(newComments.length === 10);
  };

  useEffect(() => {
    fetchComments();
  }, [page]);

  const handleLoadMore = () => {
    setLoading(true);
    setPage(page + 1);
  };

  return (
    <div className="flex flex-col gap-4">
      <CommentBox dialogueId={dialogueId} />
      <div className="flex flex-col gap-2">
        {comments.map((comment) => (
          <Comment
            key={comment.id}
            comment={comment}
            dialogueId={dialogueId}
            parentCommentId={comment.id}
          />
        ))}
      </div>
      {hasMore && (
        <button
          className="btn"
          onClick={handleLoadMore}
          disabled={loading}
        >
          {loading? (
            <span className="loading loading-dots loading-xs"></span>
          ) : (
            'Load more'
          )}
        </button>
      )}
    </div>
  );
};

const CommentBox = ({ dialogueId }) => {
  const [text, setText] = useState('');

  const handleComment = async (e) => {
    e.preventDefault();
    const response = await fetch(`${backendURL}/dialogues/comments/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ dialogueId, comment: text }),
    });
    const newComment = await response.json();
    // Add new comment to the top of the list
    // setComments((prevComments) => [newComment,...prevComments]);
    setText('');
  };

  return (
    <form onSubmit={handleComment}>
      <textarea
        className="w-full p-2 pl-4 pr-4 rounded-md"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <button type="submit" className="btn">
        Comment
      </button>
    </form>
  );
};

const Comment = ({ comment, dialogueId, parentCommentId }) => {
  const [replies, setReplies] = useState([]);
  const [loadingReplies, setLoadingReplies] = useState(false);
  const [hasMoreReplies, setHasMoreReplies] = useState(true);

  const fetchReplies = async () => {
    const url = `${backendURL}/dialogues/${dialogueId}/comments/?parent_id=${parentCommentId}`;
    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
    const newReplies = await response.json();
    setReplies(newReplies);
    setHasMoreReplies(newReplies.length === 10);
  };

  useEffect(() => {
    fetchReplies();
  }, []);

  const handleLoadMoreReplies = () => {
    setLoadingReplies(true);
    // pagination for replies
  };

  return (
    <div className="flex flex-col gap-2 ml-4">
      <div className="flex justify-between">
        <span className="text-sm">{`${comment.first_name} ${comment.last_name}`}</span>
        <span className="text-sm">{comment.created_at}</span>
      </div>
      <p>{comment.comment}</p>
      {replies.length > 0 && (
        <div className="flex flex-col gap-2 ml-4">
          {replies.map((reply) => (
            <Comment
              key={reply.id}
              comment={reply}
              dialogueId={dialogueId}
              parentCommentId={reply.id}
            />
          ))}
          {hasMoreReplies && (
            <button
              className="btn"
              onClick={handleLoadMoreReplies}
              disabled={loadingReplies}
            >
              {loadingReplies? (
                <span className="loading loading-dots loading-sm"></span>
              ) : (
                'Load more replies'
              )}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default CommentSection;