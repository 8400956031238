import { backendURL } from "../constants/urls";
import useSWR from "swr";

const fetcher = (...args) =>
fetch(`${backendURL}/level/`, { credentials: "include" }).then((response) =>
    response.json()
  );

export default function useLevel(id) {
  const { data, error, isLoading } = useSWR(`${backendURL}/level`, fetcher);
    
  return {
    level: data,
    isLoading,
    isError: error,
  };
}

