import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../App.css';

import LoggedInHeader from '../components/LoggedInHeader';
import Footer from '../components/Footer';

export default function ScratchPage() {
  return (
    <>
      <LoggedInHeader />
      Scratch stuff here...
      <Footer />
    </>
  );
}
