import { useState, useEffect } from 'react';
import { useAsync } from 'react-use';
import Avatar from "avataaars";
import {  backendURL } from "../constants/urls";
import { default_avatar } from '../components/Speakers';

const useNarrativeData = (narrativeId) => {
  const [speakerColors, setSpeakerColors] = useState(null);
  const [speakerAvatars, setSpeakerAvatars] = useState(null);
  const [narrativeValue, setNarrativeValue] = useState(null);
  const { loading: narrativeLoading, value: narrativeValueAsync, error: narrativeError } = useAsync(async () => {
    const response = await fetch(`${backendURL}/narratives/${narrativeId}`, {
      credentials: "include",
    }).catch(err => console.log(err));
    return response.json();
  }, [backendURL, narrativeId]);

  useEffect(() => {
    if (!narrativeError && narrativeValueAsync !== null) {
      setNarrativeValue(narrativeValueAsync);
    }
  }, [narrativeValueAsync, narrativeError]);

  useEffect(() => {
    if (narrativeValue) {
      const speakerColorsMap = new Map([["event", "event"]]);
      const speakerAvatarsMap = new Map([]);

      let idx = 1;
      narrativeValue.speakers.forEach((speaker) => {
        if (speaker.name.learning_language_content === 'event') {
          return;
        }

        const speakerName = speaker.name.learning_language_content;
        const isStudent = speaker.is_student;
        const avatar =  isStudent?speaker.avatar:JSON.parse(speaker.avatar);

        speakerColorsMap.set(speakerName, isStudent ? "student" : `character${idx}`);
        speakerAvatarsMap.set(speakerName, avatar);

        if (!isStudent) {
          idx++;
        }
      });

      setSpeakerColors(speakerColorsMap);
      setSpeakerAvatars(speakerAvatarsMap);
    }
  }, [narrativeValue]);
  const getAvatar = (name) => {
    if (speakerAvatars instanceof Map && speakerAvatars.has(name)) {
      const value = speakerAvatars.get(name);
      if (typeof value === "string") {
        return <img src={`${backendURL}/image`} alt="User Avatar" />;
      }
      return (
        <Avatar
          style={{ width: "100%", height: "100%" }}
          avatarStyle="Circle"
          {...value}
        />
      );
    }
    return null;
  };

  return {
    speakerColors,
    narrativeValue,
    narrativeLoading,
    narrativeError,
    getAvatar,
  };
};

const getSpeakerAvatars = (speakers) =>{
  let speakerAvatarsMap = new Map();
  speakers.forEach((speaker) => {
    if (speaker.name.learning_language_content === 'event') {
      return;
    }

    const speakerName = speaker.name.learning_language_content;
    const isStudent = speaker.is_student;
    const avatar =  isStudent?speaker.avatar:JSON.parse(speaker.avatar);

    speakerAvatarsMap.set(speakerName, avatar);
  });
  return speakerAvatarsMap
}

const getAvatarFromSpeakers = (name, speakers) => {
  const speakerAvatars = getSpeakerAvatars(speakers)

  if (speakerAvatars instanceof Map && speakerAvatars.has(name)) {
    const value = speakerAvatars.get(name);
    if (typeof value === "string") {
      return <img src={`${backendURL}/image`} alt="User Avatar" />;
    }
    return (
      <Avatar
        style={{ width: "100%", height: "100%" }}
        avatarStyle="Circle"
        {...value}
      />
    );
  }
  return null;
};

export default useNarrativeData;
export {getAvatarFromSpeakers}