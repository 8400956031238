import React, { useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./pages/Login";
import SignupPage from "./pages/Signup";
import HomePage from "./pages/Home";
import ScratchPage from "./pages/Scratch";
import ChatPage from "./pages/ChatPage";
import SettingsPage from "./pages/Settings";
import FlashcardsPage from "./pages/Flashcards";
import NarrativesPage from "./pages/Narratives";
import NarrativePage from "./pages/Narrative";
import NotFoundPage from "./pages/NotFoundPage";
import CreateNarrative from "./pages/CreateNarrative";
import CheckoutSuccessPage from "./pages/CheckoutSuccess";
import CheckoutCancelledPage from "./pages/CheckoutCancelled";
import SubscribePage from "./pages/SubscribePage";
import Feedback from "./pages/Feedback";
import FeedbackSubmissionSuccess from "./pages/FeedbackSubmissionSuccess";
import useUser from "./utils/useUser";
import NewUserSettingsForm from "./components/NewUserSettingsForm";
import LibrariesPage from "./pages/Libraries";

function unauthorizedFetchListener() {
  const originalFetch = window.fetch;

  window.fetch = async (...args) => {
    const response = await originalFetch(...args);

    if (response.status === 401) {
      window.location.href = "/login";
    }

    return response;
  };
}

function App() {
  const { user, isLoading: userLoading, isError: userError } = useUser();
  
  useEffect(() => {
    unauthorizedFetchListener();
  }, []);

  if (userLoading) {
    return <></>;
  }
  if (
    user.email &&
    (!user.default_learning_language ||
      !user.native_language ||
      !user.bio ||
      user.bio.trim().length === 0)
  ) {
    return (
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<NewUserSettingsForm user={user} />} />
          </Routes>
        </BrowserRouter>
    );
  }

  return (
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/library" element={<LibrariesPage />} />
            <Route path="/chat" element={<ChatPage />} />
            <Route path="/narratives" element={<NarrativesPage />} />
            <Route path="/narratives/create" element={<CreateNarrative />} />
            <Route
              path="/narratives/:narrativeId"
              element={<NarrativePage />}
            />
            <Route
              path="/narratives/:narrativeId/dialogues/:dialogueId"
              element={<ChatPage />}
            />
            <Route
              path="/checkout/success/:stripeCheckoutID"
              element={<CheckoutSuccessPage />}
            />
            <Route
              path="/checkout/cancelled"
              element={<CheckoutCancelledPage />}
            />
            <Route path="/flashcards" element={<FlashcardsPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/scratch" element={<ScratchPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/subscribe" element={<SubscribePage />} />
            <Route path="/purchase/basic/monthly" element={<SubscribePage />} />
            <Route path="/purchase/basic/annually" element={<SubscribePage />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route
              path="/feedback/success"
              element={<FeedbackSubmissionSuccess />}
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
      </BrowserRouter>
  );
}

export default App;
