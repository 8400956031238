import { backendURL } from "../constants/urls";
import useSWR from "swr";

const fetcher = (...args) =>
  fetch(`${backendURL}/whoami/`, { credentials: "include" }).then((response) =>
    response.json()
  );

export default function useUser(id) {
  const { data, error, isLoading } = useSWR(`${backendURL}/whoami/`, fetcher);

  return {
    user: data,
    isLoading,
    isError: error,
  };
}

