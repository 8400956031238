import Reactl, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { backendURL } from "../constants/urls";
import {
  skeletonBackgroundOpacity,
  skeletonForegroundOpacity,
} from "../constants/theme";
import { DateTimeConverter } from "../utils/datetimeHelpers";
import { ProcessEncoding } from "../utils/learningLanguageHelpers";
export default function DialogueCard({
  id,
  speakers,
  getAvatar,
  contextNarrativeId,
}) {
  const { narrativeId } = useParams();
  const [lastMessage, setLastMessage] = useState();
  const [translationOpen, setTranslationOpen] = useState(false);
  const [error, setError] = useState();
  const lastMessageURL = `${backendURL}/dialogues/${id}/last`;
  useEffect(() => {
    setTimeout(() => {
      const fetchLastMessage = async () => {
        await fetch(lastMessageURL, {
          credentials: "include",
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.json().message);
            }
            return response.json();
          })
          .then((data) => setLastMessage(data))
          .catch((err) => setError(err));
      };
      fetchLastMessage();
    }, 500);
  }, []);
  if (!lastMessage) {
    return <DialogueCardSkeleton />;
  }
  if (Object.keys(lastMessage).length === 0) {
    return (
      <div className="card card-bordered bg-base-100 shadow-xl">
        <div className="card-body rounded-2xl bg-secondary text-secondary-content">
          {/* <h2 className="card-title">{lastMessage.speaker_name}</h2> */}
          <p className="text-sm">This dialogue has not yet begun.</p>

          <div className="flex flex-col gap-4"></div>
          <div className="card-actions justify-end mt-4">
            <a
              href={`/narratives/${
                narrativeId ? narrativeId : contextNarrativeId
              }/dialogues/${id}/`}
            >
              <button className="btn btn-primary">Start Dialogue</button>
            </a>
          </div>
        </div>
      </div>
    );
  }

  const [prevMsg, finMsg] = lastMessage;

  let message = null;
  let msgDisp = null;
  let dialogueEnded = false;
  if (finMsg != null) {
    if (finMsg.message_kind === "end") {
      dialogueEnded = true;
      message = prevMsg;
    } else {
      message = finMsg;
    }
  } else {
    message = prevMsg;
  }
  const speaker = speakers
    ? speakers.filter(
        (s) => s.name.learning_language_content === message.speaker_name
      )[0]
    : null;
  if (message.message_kind !== "event") {
    msgDisp = (
      <div
        className="flex flex-col"
        onClick={() => setTranslationOpen((o) => !o)}
      >
        <div className="chat chat-start my-2">
          <div className="chat-header">
            <div
              className="tooltip"
              data-tip={speaker ? speaker.name.native_language_content : ""}
            >
              {speaker ? speaker.name.learning_language_content : ""}
            </div>
          </div>
          {
            <div className="chat-image avatar">
              <div className="w-20 rounded-full">
                {(speaker &&
                  getAvatar &&
                  getAvatar(speaker.name.learning_language_content)) || (
                  <div className="w-full h-full flex justify-center items-center">
                    <i className="bi bi-person-circle text-[5rem]"></i>{" "}
                  </div>
                )}
              </div>
            </div>
          }
          <div className="chat-bubble bg-accent text-accent-content">
            <div className="flex mb-2">
              <ProcessEncoding {...message.content} />
              <button className="btn text-center">
                <i
                  className={
                    "bi bi-caret-down-fill text-3xl " +
                    (translationOpen ? "rotate-180" : "")
                  }
                ></i>
              </button>
            </div>
            {translationOpen && (
              <div className="bg-base-100 rounded-full p-2">
                <ProcessEncoding {...message.content} learning={false} />
              </div>
            )}
          </div>

          <div className="chat-footer ">
            <DateTimeConverter
              utcDateTime={message.created_at}
            ></DateTimeConverter>
          </div>
        </div>
      </div>
    );
  } else {
    msgDisp = (
      <div
        role="alert"
        onClick={() => setTranslationOpen((o) => !o)}
        className=" alert bg-base-100 flex flex-col items-left text-md border-none"
      >
        <div className="mr-auto text-md">
          {"Event: "}
          <DateTimeConverter
            utcDateTime={message.created_at}
          ></DateTimeConverter>
        </div>
        <div className="flex flex-row justify-around items-center">
          <ProcessEncoding {...message.content} />{" "}
          <button className="btn text-center">
            <i
              className={
                "bi bi-caret-down-fill text-3xl " +
                (translationOpen ? "rotate-180" : "")
              }
            ></i>
          </button>
        </div>
        {translationOpen && (<div className="rounded-lg">
          <ProcessEncoding {...message.content} learning={false} />
        </div>)}
      </div>
    );
  }
  return (
    <div className="card card-compact shadow-xl">
      <div className="card-body rounded-2xl bg-base-300 text-content">
        <h2 className="card-title text-lg">Last Message</h2>
        {msgDisp}

        <div className="card-actions justify-end mt-4">
          <a
            href={`/narratives/${
              narrativeId ? narrativeId : contextNarrativeId
            }/dialogues/${id}/`}
          >
            <button className="btn btn-primary">
              {dialogueEnded ? "View Completed Dialogue" : "Continue Dialogue"}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

const DialogueCardSkeleton = () => (
  <div className="card card-bordered bg-base-100 shadow-xl">
    <div className="card-body rounded-2xl">
      <ContentLoader
        backgroundOpacity={skeletonBackgroundOpacity}
        foregroundOpacity={skeletonForegroundOpacity}
        viewBox="0 0 380 70"
      >
        <rect x="0" y="0" rx="5" ry="5" width="70" height="10" />
        <rect x="0" y="14" rx="5" ry="5" width="150" height="8" />
        <rect x="0" y="26" rx="5" ry="5" width="70" height="8" />
        <rect x="0" y="38" rx="5" ry="5" width="150" height="8" />
        <rect x="0" y="50" rx="5" ry="5" width="70" height="8" />
        <rect x="0" y="62" rx="5" ry="5" width="150" height="8" />
        <rect x="300" y="58" rx="1" ry="1" width="70" height="13" />
      </ContentLoader>
    </div>
  </div>
);
export { DialogueCardSkeleton };
