import { backendURL } from "../constants/urls";
import useSWR from "swr";

const fetcher = (...args) =>
  fetch(`${backendURL}/active`, { credentials: "include" }).then((response) =>
    response.json()
  );

export default function useSubscription(id) {
  const { data, error, isLoading } = useSWR(`${backendURL}/active`, fetcher);
    
  return {
    subscription: data,
    isLoading,
    isError: error,
  };
}

