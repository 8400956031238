import React, { useEffect, useState } from "react";
import Logo from "../components/Logo";
import { backendURL } from "../constants/urls";
import ContentLoader from "react-content-loader";
import {
  skeletonBackgroundOpacity,
  skeletonForegroundOpacity,
} from "../constants/theme";
import { handleLogout } from "../utils/logout";
import {
  encodingsAtom,
  EncodingOptions,
} from "../utils/learningLanguageHelpers";
import { useAtom } from "jotai";
import useUser from "../utils/useUser";
import useSettings from "../utils/useSettings";
import useSubscription from "../utils/useSubscription";

const LoggedInHeaderSkeleton = () => (
  <header className="bg-base-100 py-2 mb-12 sticky top-0 z-50">
    <div className="navbar px-0 flex flex-row items-center justify-around">
      <ContentLoader
        backgroundOpacity={skeletonBackgroundOpacity}
        foregroundOpacity={skeletonForegroundOpacity}
        viewBox="50 0 50 70"
      >
        <rect x="0" y="0" rx="5" ry="5" width="70" height="50" />
      </ContentLoader>
    </div>
  </header>
);

const Settings = ({ user }) => {
  if (user == null) {
    return <span className="loading loading-spinner loading-md"></span>;
  }

  return (
    <>
      <div className="dropdown dropdown-end">
        <div tabIndex={0} role="button" className="btn btn-ghost w-full avatar">
          <div className="w-8 rounded-full">
            <img
              className="object-cover object-center h-8 rounded-full"
              src={`${backendURL}/image`}
              alt="Users profile image"
            />
          </div>
        </div>
        <ul
          tabIndex={0}
          className="menu relative menu-sm dropdown-content gap-2 mt-3 z-[1] shadow bg-base-300 rounded-box w-52"
        >
          <li>
            <a href="/settings">Settings</a>
          </li>

          <li>
            <a
              type="button"
              onClick={handleLogout}
              className="bg-error text-error-content"
            >
              Logout
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default function LoggedInHeader({ headerRef }) {
  const [usedEncodings, setUsedEncodings] = useAtom(encodingsAtom);
  const { subscription: subscriptionStatus } = useSubscription();
  const [showModal, setShowModal] = useState(false);
  const { user, isLoading, isError } = useUser();
  const [isThemeSet, setIsThemeSet] = useState();
  const { settings } = useSettings();

  useEffect(() => {
    if (settings) {
      document.documentElement.setAttribute("data-theme", settings.theme);
      setIsThemeSet(true);
    }
  }, [settings]);
  if (!isThemeSet) {
    return <LoggedInHeaderSkeleton />;
  }
  
  return (
    <header
      className="bg-base-100 py-2 mb-12 sticky top-0 z-50 shadow-xl"
      ref={headerRef}
    >
      <div className="navbar px-0 flex flex-row items-center justify-around">
        <div className="md:hidden">
          <div className="dropdown">
            <label tabIndex={0} className="btn btn-circle btn-primary mr-1">
              <i className="bi bi-list text-2xl"></i>
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content mt-1 w-52 menu menu-compact p-2 bg-base-200 shadow rounded-box"
            >
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/narratives">Narratives</a>
              </li>
              <li>
                <a href="/flashcards">Review</a>
              </li>
              <li>
                <a href="/library">Library</a>
              </li>
              {Object.keys(usedEncodings).length > 0 && (
                <li>
                  <a type="button" onClick={() => setShowModal(true)}>
                    <i class="bi bi-eyeglasses text-xl"></i> Readings
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>

        <a href="/" className="w-40 p-3 ">
          <Logo />
        </a>
        <div className="hidden md:flex">
          <ul className="menu menu-horizontal text-xl">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/narratives">Narratives</a>
            </li>
            <li>
              <a href="/flashcards">Review</a>
            </li>
            <li>
              <a href="/library">Library</a>
            </li>{" "}
            {Object.keys(usedEncodings).length > 0 && (
              <li>
                <a type="button" onClick={() => setShowModal(true)}>
                  <i class="bi bi-eyeglasses text-3xl"></i>
                </a>
              </li>
            )}
          </ul>
          <Settings user={user} />
          {subscriptionStatus ? (
            !subscriptionStatus.active ? (
              <li>
                <a
                  href="/subscribe"
                  className="py-2 px-4 bg-accent text-accent-content shadow-md no-underline rounded-full text-sm  mr-2"
                >{`Subscribe Now`}</a>
              </li>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>

        <div className="md:hidden">
          <Settings user={user} />
        </div>
      </div>
      {showModal && (
        <div
          className="fixed top-0 left-0 w-[100vw] h-[100vh] bg-slate-800 bg-opacity-75"
          onClick={() => setShowModal((s) => !s)}
        >
          <div
            className="modal-box z-[999] absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%]"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="btn btn-sm btn-square btn-ghost absolute right-2 top-2"
              onClick={() => setShowModal(false)}
            >
              ✕
            </button>
            <h3 className="font-bold text-lg">
              Change Language Reading Settings
            </h3>
            {Object.keys(usedEncodings).map((encoding) => {
              return (
                <EncodingOptions
                  key={encoding}
                  encoding={encoding}
                  choice={usedEncodings[encoding]}
                  onChange={(choice) =>
                    setUsedEncodings((u) => {
                      const temp = { ...u };
                      temp[encoding] = choice;
                      return temp;
                    })
                  }
                />
              );
            })}
          </div>
        </div>
      )}
    </header>
  );
}
