import { ProcessEncoding } from "../utils/learningLanguageHelpers";

function getSpeaker(speakers, name) {
  let matchingObject = null;
  try {
    matchingObject = speakers.filter(
      (obj) => obj.name.learning_language_content.trim() === name.trim()
    )[0];
  } catch (err) {}
  // Return the matching object, or null if no match was found
  return (
    matchingObject || {
      name: { learning_language_content: null, native_language_content: null },
    }
  );
}

const default_avatar = {
  avatarStyle: "Circle",
  topType: "Hat",
  accessoriesType: "Sunglasses",
  facialHairType: "Blank",
  clotheType: "ShirtCrewNeck",
  clotheColor: "Heather",
  eyeType: "Default",
  eyebrowType: "DefaultNatural",
  mouthType: "Default",
  skinColor: "Yellow",
};

const SPEAKER_BG_COLORS = {
  oops: "bubble-error",
  student: "bg-green-300",
  studentOption: "bg-emerald-800",
  character1: "bg-red-950",
  character2: "bg-lime-950",
  character3: "bg-sky-950",
  character4: "bg-indigo-950",
  character5: "bg-amber-950",
  character6: "bg-rose-950",
  character7: "bg-stone-900",
};
const SPEAKER_TEXT_COLORS = {
  oops: "text-white",
  student: "text-black",
  studentOption: "text-white",
  character1: "text-white",
  character2: "text-white",
  character3: "text-white",
  character4: "text-white",
  character5: "text-white",
  character6: "text-white",
  character7: "text-white",
};


const SpeakersList = ({speakers, getAvatar}) => {

    return (
      <>
        {speakers.map((s) => {
          return (
            <div className="chat chat-start my-2" key={s.name.learning_language_content}>
              <div className="chat-header">
                <div
                  className="tooltip"
                  data-tip={s.name.native_language_content}
                >
                  {s.name.learning_language_content}
                </div>
              </div>
              {getAvatar!=null&&
              <div className="chat-image avatar">
                <div className="w-20 rounded-full">
                  {getAvatar(s.name.learning_language_content)}
                </div>
              </div>}
              <div className="chat-bubble bg-accent text-accent-content ">
                <div
                  tabIndex={0}
                  className="collapse collapse-plus border-none !overflow-visible"
                >
                  <div className="collapse-title text-xl font-medium">
                    <ProcessEncoding {...s.bio}/>
                  </div>
                  <div className="collapse-content">
                  <ProcessEncoding {...s.bio} learning={false}/>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );

};

export {
  SpeakersList,
  default_avatar,
  getSpeaker,
  SPEAKER_BG_COLORS,
  SPEAKER_TEXT_COLORS,
};
