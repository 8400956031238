import React, { useState, useEffect } from "react";
import { backendURL } from "../constants/urls";
import SingleComment from "./SingleComment";
import useUser from "../utils/useUser";

export default function Comments({ dialogueId }) {
  const [page, setPage] = useState(1);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState(""); // State to hold the comment text
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { user, isLoading, isError } = useUser();

  const fetchComments = async () => {
    const url = `${backendURL}/dialogues/${dialogueId}/comments/${page}`;
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const newComments = await response.json();
    setComments(newComments);
    setHasMore(newComments.length === 10);
  };

  useEffect(() => {
    fetchComments();
  }, [page]);

  const handleLoadMore = () => {
    setLoading(true);
    setPage(page + 1);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value); // Update state when the text changes
  };

  const handleCommentSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    try {
      const response = await fetch(
        `${backendURL}/dialogues/${dialogueId}/comments/`,
        {
          credentials: "include",
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ dialogueId, comment }),
        }
      );

      const data = await response.json(); // Handle the response data as needed
      const currentDate = new Date().toLocaleDateString();
      setComments([
        {
          id: data.id,
          dialogue_id: dialogueId,
          user_id: user.id,
          parent_id: data.parent_id,
          created_at: currentDate,
          comment: comment,
          deleted_at: data.deleted_at,
          first_name: user.first_name,
          last_name: user.last_name,
        },
        ...comments,
      ]);
      setComment("");
    } catch (error) {
      console.error("Failed to post comment:", error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      // Check if Enter key is pressed without Shift key
      event.preventDefault(); // Prevent the newline
      handleCommentSubmit(event); // Submit the form
    }
  };

  return (
    <div>
      <section className="py-8 lg:py-16 antialiased">
        <div className="max-w-2xl mx-auto px-4">
          <div className="flex justify-between items-center mb-6">
            <h2 className="lg:font-bold ">{`Discussion (${comments.length})`}</h2>
          </div>
          <form className="mb-6" onSubmit={handleCommentSubmit}>
            <div className="py-2 px-4 mb-4 rounded-lg rounded-t-lg border border-gray-200 ">
              <label for="comment" className="sr-only">
                Your comment
              </label>
              <textarea
                id="comment"
                rows="6"
                className="px-0 w-full border-0 focus:ring-0 focus:outline-none "
                placeholder="Write a comment..."
                value={comment}
                onChange={handleCommentChange}
                onKeyDown={handleKeyDown}
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="btn inline-flex items-center py-2.5 px-4 font-medium rounded-lg focus:ring-4 focus:ring-primary-200 hover:btn-primary"
            >
              Post comment
            </button>
          </form>
          {comments.map((c) => (
            <SingleComment
              key={c.id}
              name={`${c.first_name} ${c.last_name}`}
              date={c.created_at}
              deleted_at={c.deleted_at}
              comment={c.comment}
              commentId={c.id}
              userId={c.user_id}
              dialogueId={dialogueId}
              numReplies={c.num_replies}
            />
          ))}
        </div>
      </section>
      {hasMore && (
        <button className="btn" onClick={handleLoadMore} disabled={loading}>
          {loading ? (
            <span className="loading loading-dots loading-xs"></span>
          ) : (
            "Load more"
          )}
        </button>
      )}
    </div>
  );
}
