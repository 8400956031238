import React, { useState, useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../App.css";

import LoggedInHeader from "../components/LoggedInHeader";
import FlashcardAttempts from "../charts/FlashcardAttempts";
import DialogueCard, { DialogueCardSkeleton } from "../components/DialogueCard";
import { backendURL } from "../constants/urls";
import useSWR from "swr";
import NarrativeCounter from "../components/NarrativeCounter";
import DialogueCounter from "../components/DialogueCounter";
import FlashcardCounter from "../components/FlashcardCounter";
import StandaloneAudioMessage from "../components/StandaloneAudioMessage";
import LevelProgressBar from "../components/LevelProgressBar";
import ActivityBubbleChart from "../charts/ActivityBubbleChart";
import VocabCard from "../components/VocabCard";
import useLevel from "../utils/useLevel";
import Vocab from "../components/Vocab";
import ProgressBar from "../components/progress";

const fetcher = (url) => (...args) =>
  fetch(url, { credentials: "include" }).then((response) => response.json());

export default function HomePage() {
  const {
    data: randomMessagesWithAudio,
    error: randomMessagesError,
    isLoading: randomMessagesLoading,
  } = useSWR(
    `${backendURL}/audio/messages`,
    fetcher(`${backendURL}/audio/messages`)
  );
  const {
    data: dialogues,
    error: dialoguesError,
    isLoading: dialoguesLoading,
  } = useSWR(`${backendURL}/dialogues/`, fetcher(`${backendURL}/dialogues/`));
  const {
    data: recentVocab,
    error: recentVocabError,
    isLoading: recentVocabLoading,
  } = useSWR(
    `${backendURL}/vocab/recent`,
    fetcher(`${backendURL}/vocab/recent`)
  );

  return (
    <>
      <LoggedInHeader />
      <div className="flex flex-row flex-wrap w-[100vw] my-16">
        <div className="flex flex-col bg-base-100 m-4 shadow-xl items-center max-w-5xl mx-auto rounded-xl p-4 gap-2">
          <h2 className="text-2xl w-full text-center m-4 font-bold">Progress</h2>

          <ProgressBar />
        </div>
        <div className="flex flex-col bg-base-100 m-4 shadow-xl items-center max-w-5xl mx-auto rounded-xl p-4 gap-2">
          <p className="text-2xl w-full text-center m-4 font-bold">Recent Dialogues</p>
          {dialogues ? (
            dialogues.length > 0 ? (
              dialogues.map((n) => (
                <div className="p-6">
                  <DialogueCard
                    id={n.id}
                    speakers={null}
                    getAvatar={null}
                    contextNarrativeId={n.narrative_id}
                  />
                </div>
              ))
            ) : (
              <a href="/narratives" className="btn m-auto">
                Explore to Create Dialogues
              </a>
            )
          ) : (
            Array(3).fill(<DialogueCardSkeleton />)
          )}
        </div>
        <div className="flex flex-col bg-base-100 m-4 shadow-xl w-[40rem] max-w-[100vw] mx-auto rounded-xl p-4 m-4">
          <h2 className="text-2xl w-full text-center m-4 font-bold">Activity</h2>
          <ActivityBubbleChart />
        </div>
        <div className="flex flex-col bg-base-100 m-4 shadow-xl max-w-2xl mx-auto rounded-xl p-4 m-4 gap-2 items-center">
          <h2 className="text-2xl w-full text-center m-4 font-bold">Recent Vocab</h2>
          {recentVocab ? (
            recentVocab.length > 0 ? (
              recentVocab.map((v, i) => (
                <Vocab
                  key={v.canonical_form}
                  vocab={{
                    ...v,
                    learning_language_content: v.canonical_form,
                    encoded_learning_language_content: v.encoded_canonical_form,
                    native_language_content: v.definition,
                  }}
                />
              ))
            ) : (
              <a href="/narratives" className="btn m-auto">
                Explore to Create Review
              </a>
            )
          ) : (
            <DialogueCardSkeleton />
          )}
        </div>
        <div className="flex flex-col bg-base-100 m-4 shadow-xl w-[50rem] mx-auto rounded-xl p-4 m-4 gap-2 items-center justify-center">
          <h2 className="text-2xl w-full text-center m-4 font-bold">Recent Audio</h2>

          {randomMessagesWithAudio ? (
            randomMessagesWithAudio.length > 0 ? (
              randomMessagesWithAudio.map((m) => (
                <StandaloneAudioMessage message={m} />
              ))
            ) : (
              <a href="/narratives" className="btn m-auto">
                Explore to Create Audio
              </a>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
/* 
          <div className="stats shadow my-auto">
            <LevelProgressBar />

            {levelProgress ? (
              <NarrativeCounter
                count={levelProgress.usage.narratives}
                goal={
                  levelProgress.level_requirements[levelProgress.level]
                    .narratives
                }
              />
            ) : (
              <span className="loading loading-spinner loading-xs"></span>
            )}
            {levelProgress ? (
              <DialogueCounter
                count={levelProgress.usage.dialogues}
                goal={
                  levelProgress.level_requirements[levelProgress.level]
                    .dialogues
                }
              />
            ) : (
              <span className="loading loading-spinner loading-xs"></span>
            )}
            {levelProgress ? (
              <FlashcardCounter
                count={levelProgress.usage.flashcards}
                goal={
                  levelProgress.level_requirements[levelProgress.level]
                    .flashcards
                }
              />
            ) : (
              <span className="loading loading-spinner loading-xs"></span>
            )}
          </div>
          <ActivityBubbleChart />
        </div>
        <div>
          <FlashcardAttempts />

          <div>
            {randomMessagesWithAudio ? (
              randomMessagesWithAudio.map((m) => (
                <StandaloneAudioMessage
                  message={m.content[0]}
                  translation={m.content[1]}
                  audio_id={m.audio_id}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="p-4">
          <p className="text-xl font-bold">Recent Dialogues</p>
          {dialogues
            ? dialogues.map((n) => (
                <div className="p-6">
                  <DialogueCard
                    id={n.id}
                    speakers={null}
                    getAvatar={null}
                    contextNarrativeId={n.narrative_id}
                  />
                </div>
              ))
            : Array(3).fill(<DialogueCardSkeleton />)}
        </div>
        <div className="p-4">
          
        </div> */
