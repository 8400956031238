import { backendURL } from "../constants/urls";

const clearCookies = async () => {
  const logoutUrl = `${backendURL}/logout/`;
  await fetch(logoutUrl, {
    credentials: "include",
    method: "POST",
  });
};

const handleLogout = async () => {
    await clearCookies()
    window.location.href = "/login";
  };

export {handleLogout, clearCookies}
