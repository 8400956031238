import { ProcessEncoding } from "../utils/learningLanguageHelpers";
const TYPE_BADGE_COLOR = {
  word: "badge badge-accent",
  grammar: "badge badge-info",
  phrase: "badge badge-secondary",
  question: "badge badge-warning",
};
const VOCAB_COLOR = {
  word: "accent",
  grammar: "info",
  phrase: "secondary",
  question: "warning",
};
export default function Vocab({ vocab }) {
  const content = vocab.learning_language_content || vocab.canonical_form;
  const content_encoded =
    vocab.encoded_learning_language_content || vocab.encoded_canonical_form;

  if (content) {
    return (
      <div
        tabIndex={0}
        className="collapse collapse-plus border border-base-300 rounded-box !overflow-visible bg-base-200"
      >
        
        <div className="collapse-title text-sm font-medium">
        {VOCAB_COLOR[vocab.type]&&<><span className={"badge "+("bg-"+VOCAB_COLOR[vocab.type]+" text-"+VOCAB_COLOR[vocab.type]+"-content")}>{vocab.type}</span> {" "}</>}
          <ProcessEncoding
            {...vocab}
            content={content}
            content_encoded={content_encoded}
          />
        </div>
        <div className="collapse-content text-xs">
          {vocab.learning_language_content ? (
            <ProcessEncoding {...vocab} learning={false} />
          ) : (
            <p>{vocab.definition}</p>
          )}
          {vocab.explanation.trim() && <p>{vocab.explanation}</p>}
        </div>
      </div>
    );
  }
}

export { TYPE_BADGE_COLOR };
