import React, { useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../App.css";
import CheckIcon from "@heroicons/react/24/outline/CheckIcon";
import LoggedInHeader from "../components/LoggedInHeader";
import { backendURL } from "../constants/urls";
import useSubscription from "../utils/useSubscription";
import { useSearchParams } from "react-router-dom";

const professionalPointers = [
  "Create narratives in 10+ Languages",
  "Engage in AI-powered dialogues",
  "Practice pronunciation by interactively listening to dialogues",
  "Live corrections across all of your messages",
  "Podcast-like review for audio-based studying",
  "5+ types of anytime review material generated uniquely for you as you chat",
  "...and much, much more!!",
];

export default function SubscribePage() {
  const [params, setParams] = useSearchParams();
  const [toggleActive, setToggleActive] = useState(
    params.get("cadence") == "yearly"
  );
  const [discountCode, setDiscountCode] = useState();
  const { subscription: subscriptionStatus } = useSubscription();

  const handleCheckout = () => {
    if (toggleActive) {
      fetch(`${backendURL}/purchase/basic/annually`, {
        method: "POST",
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          window.location.href = data.url;
        })
        .catch(function (err) {
          console.info(err);
        });
    } else {
      fetch(`${backendURL}/purchase/basic/monthly?discount_code=${discountCode}`, {
        method: "POST",
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          window.location.href = data.url;
        })
        .catch(function (err) {
          console.info(err);
        });
    }
  };
  const handleChange = (event) => {
    setDiscountCode(event.target.value);
  };
  return (
    <>
      <LoggedInHeader />
      <div
        className="text-center cursor-pointer text-xl pt-20 mt- text-black"
        onClick={() => setToggleActive(!toggleActive)}
      >
        <span className="align-top mr-1">Monthly</span>
        <input
          type="checkbox"
          className="toggle toggle-primary"
          checked={toggleActive}
        />
        <span className="align-top ml-2">Yearly</span>
      </div>
      <div className="grid md:grid-cols-3 grid-cols-1 mt-2 w-full gap-8">
        <div></div>
        <div className="card w-full mt-6  bg-gray-300 shadow-xl hover:shadow-2xl hover:shadow-gray-300 ">
          <div className="card-body pt-12 pb-16 glass items-center text-center rounded-xl ">
            <h2 className="card-title text-xl text-center">Basic</h2>
            <h2 className="font-bold mt-4 text-4xl text-center inline-block">
              {toggleActive ? `$4.99` : `$5.99`}{" "}
              {toggleActive && (
                <span className="text-xl line-through text-secondary align-middle mr-2">
                  $5.99
                </span>
              )}
              <span className="text-xs align-middle font-light text-slate-500">
                /monthly
              </span>
            </h2>
            {subscriptionStatus ? (
              subscriptionStatus.active ? (
                "Current Subscription"
              ) : (
                <>
                  <button
                    onClick={() => handleCheckout()}
                    className="btn mt-12 btn-primary inline-block px-12 normal-case"
                  >
                    Subscribe
                  </button>
                  <input
                    id="input-field"
                    type="text"
                    className="input input-bordered"
                    placeholder="Discount Code"
                    value={discountCode}
                    onChange={handleChange}
                  />
                </>
              )
            ) : (
              "Current Subscription"
            )}
            <ul className="text-left mt-4">
              {professionalPointers.map((p, k) => {
                return (
                  <li key={k} className="mt-2">
                    <CheckIcon className="w-4 h-4 text-green-500 inline-block" />{" "}
                    {p}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}
