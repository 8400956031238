import { useSpring, animated, to } from "@react-spring/web";
import { createUseGesture, dragAction, pinchAction } from "@use-gesture/react";
import { useState, useEffect, useCallback, useRef } from "react";
import SwipeIcon from "./SwipeIcon";
import { useKeyPressEvent, useLongPress } from "react-use";

//props:
//handleGone: what to do when the card is dispatched off screen
// height, width
//front: front content
//back: back content
const DecisionCard = (props) => {
  let [deleting, setDeleting] = useState(false);
  let [gone, setGone] = useState(null);
  const [decision, setDecision] = useState(null);

  const timer = useRef(null);

  const handleMouseDown = useCallback(() => {
    if (!props.ignorable) {
      return;
    }
    setDeleting(true);
    timer.current = setTimeout(() => {
      console.log(deleting, gone, decision);
      handleDispatch(-100, "ignore");
    }, 2000); // Adjust the duration (in milliseconds) for the long press
  }, []);

  const handleMouseUp = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    setDeleting(false);
    handleDispatch(-100, "skip");
  }, []);
  const handleMouseLeave = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    setDeleting(false);
  }, []);

  // : { onClick: async (e) => handleDispatch(-100, "skip") };

  useKeyPressEvent("ArrowLeft", () => props.is_top && handleDispatch(-1));
  useKeyPressEvent("ArrowRight", () => props.is_top && handleDispatch(1));
  useKeyPressEvent(" ", () => {
    if (props.check) {
      props.is_top && props.check();
    }
  });

  function handleDispatch(dir, dec) {
    const x = Math.sign(dir) * (10 + window.innerWidth);
    setGone(dir);
    setDecision(dec != null ? dec : dir < 0 ? "repeat" : "good");
    api.start(() => ({
      x,
      // immediate: dir===0,
      // rot,
      // scale,
      config: { friction: 50, tension: 500, duration: 550 },
    }));
  }

  useEffect(() => {
    // console.log(gone)
    if (decision && props.handleGone) {
      props.handleGone(decision);
    }
  }, [gone]);

  //   useEffect(() => {
  //     console.log(props.count)
  //     api.start(() => ({
  //         x:0,
  //         rot:0,
  //         scale:1,
  //         // config: { friction: 50, tension: 500 },
  //         immediate: true,
  //       }));
  //   }, [props.count]);

  const [{ x, y, scale, rot }, api] = useSpring(() => ({
    from: {
      x: 0,
      y: 0,
      scale: 1,
      rot: 0,
    },
  }));

  // const bindGesture = useGesture({});
  let spinRate = decision != "ignore" ? 1 : 10;
  return (
    <div className="flex flex-col items-center justify-around">
      <animated.div
        className="card"
        style={{
          touchAction: "none",
          // position: 'relative',
          height: props.height || "30rem",
          width: props.width || "45rem",
          zIndex: to([x], (x) => (Math.abs(x) ? 100 : 1)),
          transform: to(
            [x],
            (x) => `translateX(${x}px) rotateZ(${x / spinRate}deg)`
          ),
          display: to([x], (x) =>
            window ? (Math.abs(x) > window.innerWidth ? "none" : "") : ""
          ),
        }}
      >
        {props.children}
      </animated.div>
      {props.is_top && (
        <div className="flex flex-col w-full items-center">
          <div className="flex flex-row mx-auto justify-around mt-14">
            <div
              className={
                "flex flex-col w-full items-center " +
                (props.left ? "opacity-1" : "opacity-0")
              }
            >
              <button
                className="btn btn-warning btn-circle w-36 m-4"
                onClick={() => props.left && handleDispatch(-1)}
              >
                <i className="bi bi-x-lg text-3xl"></i>
              </button>
              {props.canHover ? (
                <kbd className="kbd kbd-lg rounded-md drop-shadow-md">◀︎</kbd>
              ) : null}
              <p className="text-xs">Again</p>
            </div>

            <div className="flex flex-col w-full items-center">
              <div className="flex flex-col items-center w-full justify-center">
                {props.check && (
                  <>
                    <p className="text-xs">Check Answer</p>
                    <button
                      className="btn btn-primary overflow-hidden btn-circle btn-xlg w-36 m-2"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.is_top && props.check(e);
                      }}
                    >
                      <i className="bi bi-question-circle text-3xl"></i>
                    </button>
                    {props.canHover && (
                      <>
                        <div className="divider w-20 mx-auto">or</div>
                        <kbd className="kbd kbd-lg rounded-md drop-shadow-md">
                          Spacebar
                        </kbd>
                      </>
                    )}
                    <div className="divider w-20 mx-auto"></div>
                  </>
                )}
              </div>
              <button
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
                className={
                  "relative overflow-hidden btn btn-circle btn-info btn-xlg w-16 h-16 m-4 border-none"
                }
              >
                <div
                  className={
                    "bg-error absolute transform z-5 transition linear " +
                    (deleting ? "translate-y-0" : "translate-y-full") +
                    " w-full h-full transition duration-[2s]"
                  }
                ></div>
                <i
                  className={
                    "bi text-3xl z-10 " +
                    (deleting ? "bi-trash" : "bi-eye-slash")
                  }
                ></i>
              </button>
              <div className="text-xs w-[4rem] text-center flex flex-col justify-center items-center">
                {deleting ? (
                  <p>Ignoring Forever</p>
                ) : (
                  <>
                    {" "}
                    <p>Skip</p>
                    {props.ignorable && <p>{"(Or Hold to Ignore)"}</p>}
                  </>
                )}
              </div>
            </div>

            <div
              className={
                "flex flex-col w-full items-center " +
                (props.right ? "opacity-1" : "opacity-0")
              }
            >
              <button
                className="btn btn-success btn-circle btn-xlg w-36 m-4"
                onClick={() => props.right && handleDispatch(1)}
              >
                <i className="bi bi-check-lg text-3xl"></i>
              </button>
              {props.canHover ? (
                <kbd className="kbd kbd-lg rounded-md drop-shadow-md">▶︎</kbd>
              ) : null}
              <p className="text-xs">Good</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DecisionCard;
