import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoggedInHeader from "../components/LoggedInHeader";
import { Player } from "@lottiefiles/react-lottie-player";
import redirectingAnimation from "../lottie/redirectingAnimation";
import { backendURL } from "../constants/urls";

export default function CheckoutCancelledPage() {
  const navigate = useNavigate();
  useEffect(() => {
    fetch(`${backendURL}/purchase/cancelled`, {
      credentials: "include",
      method: "POST",
    }).then((response) => response.json()).catch(err => console.log(err));
    setTimeout(() => {
      navigate("/");
    }, 1500);
  }, []);
  return (
    <>
      <LoggedInHeader />
      <div className="flex justify-center items-center">
        <div>
          <h1 className="bold text-3xl">Redirecting...</h1>
        </div>
        <Player
          src={redirectingAnimation}
          style={{ height: "50px" }}
          autoplay
          loop
        />
      </div>
    </>
  );
}
