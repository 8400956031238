import React, { useState, useEffect } from "react";
import { backendURL } from "../constants/urls";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const renderTooltip = (props) => {
  const { active, payload } = props;

  if (active && payload && payload.length) {
    const data = payload[0] && payload[0].payload;

    return (
      <div
        style={{
          backgroundColor: "#fff",
          border: "1px solid #999",
          margin: 0,
          padding: 10,
        }}
      >
        <p>{data.weeks_ago}</p>
        <p>
          <span>Flashcard Created: </span>
          {data.num_flashcards}
        </p>
        <p>
          <span>Narratives Created: </span>
          {data.num_narratives}
        </p>
        <p>
          <span>Dialogues Created </span>
          {data.num_dialogues}
        </p>
      </div>
    );
  }

  return null;
};

const defaultWeeklyData = (day_of_week) => ({
  day_of_week: day_of_week,
  num_narratives: 0,
  num_dialogues: 0,
  num_flashcards: 0,
  value: 0,
  index: 1,
});

const upsertedUsage = (usage) => {
  const mergedUsage = [];
  if (usage.some((u) => u.day_of_week === "Sunday")) {
    mergedUsage.push(usage.filter((u) => u.day_of_week === "Sunday")[0]);
  } else {
    mergedUsage.push(defaultWeeklyData("Su"));
  }

  if (usage.some((u) => u.day_of_week === "Monday")) {
    mergedUsage.push(usage.filter((u) => u.day_of_week === "Monday")[0]);
  } else {
    mergedUsage.push(defaultWeeklyData("M"));
  }

  if (usage.some((u) => u.day_of_week === "Tuesday")) {
    mergedUsage.push(usage.filter((u) => u.day_of_week === "Tuesday")[0]);
  } else {
    mergedUsage.push(defaultWeeklyData("T"));
  }

  if (usage.some((u) => u.day_of_week === "Wednesday")) {
    mergedUsage.push(usage.filter((u) => u.day_of_week === "Wednesday")[0]);
  } else {
    mergedUsage.push(defaultWeeklyData("W"));
  }

  if (usage.some((u) => u.day_of_week === "Thursday")) {
    mergedUsage.push(usage.filter((u) => u.day_of_week === "Thursday")[0]);
  } else {
    mergedUsage.push(defaultWeeklyData("T"));
  }

  if (usage.some((u) => u.day_of_week === "Friday")) {
    mergedUsage.push(usage.filter((u) => u.day_of_week === "Friday")[0]);
  } else {
    mergedUsage.push(defaultWeeklyData("F"));
  }

  if (usage.some((u) => u.day_of_week === "Saturday")) {
    mergedUsage.push(usage.filter((u) => u.day_of_week === "Saturday")[0]);
  } else {
    mergedUsage.push(defaultWeeklyData("Sa"));
  }
  return mergedUsage;
};
export default function ActivityBubbleChart() {
  const [domain, setDomain] = useState();
  const range = [0, 255];
  const [thisWeeksUsage, setThisWeeksUsage] = useState();
  const [lastWeeksUsage, setLastWeeksUsage] = useState();
  const [twoWeeksAgoUsage, setTwoWeeksAgoUsage] = useState();
  const [threeWeeksAgoUsage, setThreeWeeksAgoUsage] = useState();
  const [fourWeeksAgoUsage, setFourWeeksAgoUsage] = useState();
  useEffect(() => {
    fetch(`${backendURL}/usage/weekly/0`, { credentials: "include" })
      .then((response) => response.json())
      .then((data) => setThisWeeksUsage(upsertedUsage(data)))
      .catch((err) => console.log(err));
    fetch(`${backendURL}/usage/weekly/1`, { credentials: "include" })
      .then((response) => response.json())
      .then((data) => setLastWeeksUsage(upsertedUsage(data)))
      .catch((err) => console.log(err));
    fetch(`${backendURL}/usage/weekly/2`, { credentials: "include" })
      .then((response) => response.json())
      .then((data) => setTwoWeeksAgoUsage(upsertedUsage(data)))
      .catch((err) => console.log(err));
    fetch(`${backendURL}/usage/weekly/3`, { credentials: "include" })
      .then((response) => response.json())
      .then((data) => setThreeWeeksAgoUsage(upsertedUsage(data)))
      .catch((err) => console.log(err));
    fetch(`${backendURL}/usage/weekly/4`, { credentials: "include" })
      .then((response) => response.json())
      .then((data) => setFourWeeksAgoUsage(upsertedUsage(data)))
      .catch((err) => console.log(err));
  }, []);

  if (
    !thisWeeksUsage ||
    !lastWeeksUsage ||
    !twoWeeksAgoUsage ||
    !threeWeeksAgoUsage ||
    !fourWeeksAgoUsage
  ) {
    return <></>;
  }
  return (
    <div className="card-body">
      {/* <p className="text-xl font-bold">Activity</p> */}
      <div className="p-4">
        <ResponsiveContainer width="100%" height={60}>
          <ScatterChart
            width={800}
            height={60}
            margin={{
              top: 10,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <XAxis
              type="category"
              dataKey="day_of_week"
              name="day_of_week"
              interval={0}
              tick={{ fontSize: 0 }}
              tickLine={{ transform: "translate(0, -6)" }}
            />
            <YAxis
              type="number"
              dataKey="index"
              height={10}
              width={80}
              tick={false}
              tickLine={false}
              axisLine={false}
              label={{ value: "4 Weeks", position: "insideRight" }}
            />
            <ZAxis
              type="number"
              dataKey="value"
              domain={domain}
              range={range}
            />
            <Tooltip
              cursor={{ strokeDasharray: "3 3" }}
              wrapperStyle={{ zIndex: 100 }}
              content={renderTooltip}
            />
            <Scatter data={fourWeeksAgoUsage} fill="#8884d8" />
          </ScatterChart>
        </ResponsiveContainer>

        <ResponsiveContainer width="100%" height={60}>
          <ScatterChart
            width={800}
            height={60}
            margin={{
              top: 10,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <XAxis
              type="category"
              dataKey="day_of_week"
              name="day_of_week"
              interval={0}
              tick={{ fontSize: 0 }}
              tickLine={{ transform: "translate(0, -6)" }}
            />
            <YAxis
              type="number"
              dataKey="index"
              height={10}
              width={80}
              tick={false}
              tickLine={false}
              axisLine={false}
              label={{ value: "3 Weeks", position: "insideRight" }}
            />
            <ZAxis
              type="number"
              dataKey="value"
              domain={domain}
              range={range}
            />
            <Tooltip
              cursor={{ strokeDasharray: "3 3" }}
              wrapperStyle={{ zIndex: 100 }}
              content={renderTooltip}
            />
            <Scatter data={threeWeeksAgoUsage} fill="#8884d8" />
          </ScatterChart>
        </ResponsiveContainer>
        <ResponsiveContainer width="100%" height={60}>
          <ScatterChart
            width={800}
            height={60}
            margin={{
              top: 10,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <XAxis
              type="category"
              dataKey="day_of_week"
              name="day_of_week"
              interval={0}
              tick={{ fontSize: 0 }}
              tickLine={{ transform: "translate(0, -6)" }}
            />
            <YAxis
              type="number"
              dataKey="index"
              height={10}
              width={80}
              tick={false}
              tickLine={false}
              axisLine={false}
              label={{ value: "2 Weeks", position: "insideRight" }}
            />
            <ZAxis
              type="number"
              dataKey="value"
              domain={domain}
              range={range}
            />
            <Tooltip
              cursor={{ strokeDasharray: "3 3" }}
              wrapperStyle={{ zIndex: 100 }}
              content={renderTooltip}
            />
            <Scatter data={twoWeeksAgoUsage} fill="#8884d8" />
          </ScatterChart>
        </ResponsiveContainer>

        <ResponsiveContainer width="100%" height={60}>
          <ScatterChart
            width={800}
            height={60}
            margin={{
              top: 10,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <XAxis
              type="category"
              dataKey="day_of_week"
              name="day_of_week"
              interval={0}
              tick={{ fontSize: 0 }}
              tickLine={{ transform: "translate(0, -6)" }}
            />
            <YAxis
              type="number"
              dataKey="index"
              height={10}
              width={80}
              tick={false}
              tickLine={false}
              axisLine={false}
              label={{ value: "Last Week", position: "insideRight" }}
            />
            <ZAxis
              type="number"
              dataKey="value"
              domain={domain}
              range={range}
            />
            <Tooltip
              cursor={{ strokeDasharray: "3 3" }}
              wrapperStyle={{ zIndex: 100 }}
              content={renderTooltip}
            />
            <Scatter data={lastWeeksUsage} fill="#8884d8" />
          </ScatterChart>
        </ResponsiveContainer>

        <ResponsiveContainer width="100%" height={60}>
          <ScatterChart
            margin={{
              top: 10,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <XAxis
              type="category"
              dataKey="day_of_week"
              name="day_of_week"
              interval={0}
              tickLine={{ transform: "translate(0, -6)" }}
            />
            <YAxis
              type="number"
              dataKey="index"
              height={10}
              width={80}
              tick={false}
              tickLine={false}
              axisLine={false}
              label={{ value: "This Week", position: "insideRight" }}
            />
            <ZAxis
              type="number"
              dataKey="value"
              domain={domain}
              range={range}
            />
            <Tooltip
              cursor={{ strokeDasharray: "3 3" }}
              wrapperStyle={{ zIndex: 100 }}
              content={renderTooltip}
            />
            <Scatter data={thisWeeksUsage} fill="#8884d8" />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
