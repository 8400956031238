import React, { useState } from 'react';

export default function ShareDialogue({narrativeId, dialogueId}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const shareUrl = `https://app.stoozi.com/login?narrativeId=${narrativeId}&dialogueId=${dialogueId}`;
  const toggleModal = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      setIsCopied(false); // Reset copied status when closing the modal
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1500); // Reset button text after 1.5 seconds
    }).catch(err => {
      alert('Failed to copy: ', err);
    });
  };

  return (
    <div className="p-4">
      <button onClick={toggleModal} className="btn btn-primary">
        Share
      </button>

      {isOpen && (
        <div className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg">Share this Stoozi Dialogue</h3>
            <p className="py-4 break-all">{shareUrl}</p>
            <div className="modal-action">
              <button onClick={copyToClipboard} className={`btn ${isCopied ? 'btn-success' : 'btn-primary'}`}>
                {isCopied ? 'Copied!' : 'Copy Link'}
              </button>
              <button onClick={toggleModal} className="btn">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
