import React, { useState, useEffect } from "react";
import RotatableCard from "../components/rotatable_card";
import LoggedInHeader from "../components/LoggedInHeader";
import { Deck } from "../components/FlashcardDeck";
import { backendURL } from "../constants/urls";
import { useWindowSize, useAsync } from "react-use";
import ContentLoader from "react-content-loader";
import {
  skeletonBackgroundOpacity,
  skeletonForegroundOpacity,
} from "../constants/theme";
import { Player } from "@lottiefiles/react-lottie-player";
import emptyCardsAnimation from "../lottie/emptyCardsAnimation";
import { utcToLocalDate, sleep } from "../utils/datetimeHelpers";
import mobileCheck from "../utils/mobileCheck";
import { ProcessEncoding } from "../utils/learningLanguageHelpers";
import { randomShuffle } from "../utils/shuffle";
import shuffleArray from "../utils/shuffle";
import DecisionCard from "../components/decision_card";
import { TYPE_BADGE_COLOR } from "../components/Vocab";

function groupVocab(arr) {
  let result = [];
  let wordArr = [];
  let item = null;
  for (let i = 0; i < arr.length; i++) {
    item = arr[i][1][0];
    if (item.type === "word" && item.split != true) {
      if (wordArr.length < 5) {
        wordArr.push(item);
      } else {
        result.push(wordArr);
        wordArr = [item];
      }
    } else {
      result.push(item);
    }
  }

  if (wordArr.length > 2) {
    result.push(wordArr);
  } else {
    wordArr.forEach((w) => result.push(w));
  }
  return result;
}

const CountdownTimer = ({ targetTime }) => {
  const calculateTimeLeft = () => {
    const difference = Math.abs(utcToLocalDate(targetTime) - new Date());
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="flex gap-5 mt-4">
      <div>
        <span className="countdown font-mono text-lg md:text-4xl">
          <span style={{ "--value": timeLeft.days }}></span>
        </span>
        days
      </div>
      <div>
        <span className="countdown font-mono text-lg md:text-4xl">
          <span style={{ "--value": timeLeft.hours }}></span>
        </span>
        hours
      </div>
      <div>
        <span className="countdown font-mono text-lg md:text-4xl">
          <span style={{ "--value": timeLeft.minutes }}></span>
        </span>
        min
      </div>
      <div>
        <span className="countdown font-mono text-lg md:text-4xl">
          <span style={{ "--value": timeLeft.seconds }}></span>
        </span>
        sec
      </div>
    </div>
  );
};

function convertPixelsToRem(pixels) {
  const fontSize = parseFloat(
    getComputedStyle(document.documentElement).fontSize
  );
  return pixels / fontSize;
}



function UsageModal({ vocab_id, btnInner, btnClassName }) {
  const id = `vocab_${vocab_id}_modal`;
  const [getUsage, setGetUsage] = useState(false);
  const [showDef, setShowDef] = useState(false);
  const [idx, setIdx] = useState(0);
  const state = useAsync(async () => {
    if (!getUsage) {
      return;
    }
    const response = await fetch(`${backendURL}/vocab/usage/${vocab_id}`, {
      credentials: "include",
    }).catch((err) => console.log(err));
    const data = await response.json();
    return data;
  }, [getUsage]);
  let button = (
    <button
      className={btnClassName ? btnClassName : "btn text-xs md:text-md"}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setGetUsage(true);
        document.getElementById(id).showModal();
      }}
    >
      {btnInner ? btnInner : "As Seen In"}
    </button>
  );
  let curr = null;
  if (state.value != null && state.value.length > 0) {
    let prev = idx === 0 ? state.value.length - 1 : idx - 1;
    let next = idx === state.value.length - 1 ? 0 : idx + 1;
    let usage = state.value[idx];
    curr = (
      <div className="w-full">
        <div id={`${id}${idx}`} className="w-full flex flex-col" key={usage.id}>
          <div className="m-auto">
            <div className="card bg-slate-300 text-slate-900">
              <div className="card-body ">
                <div className="chat chat-start">
                  <div className="chat-image avatar">
                    <i className="bi bi-person-circle text-4xl"></i>
                  </div>
                  <div className="chat-bubble bg-info text-info-content">
                    <ProcessEncoding {...usage.content} />
                  </div>
                </div>
                <div className="flex justify-center items-center mx-auto w-full gap-2">
                  <button onClick={() => setShowDef(!showDef)}>
                    {showDef ? (
                      <i className="bi bi-eye-fill text-2xl"></i>
                    ) : (
                      <i className="bi bi-eye-slash-fill text-2xl"></i>
                    )}
                  </button>

                  <p className={showDef ? "opacity-1" : "opacity-0"}>
                    {usage.content.native_language_content}
                  </p>
                </div>
                <div className="flex justify-around">
                  <a
                    href={`#${id}${prev}`}
                    disabled={state.value.length === 1}
                    className="btn btn-circle"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIdx(prev);
                    }}
                  >
                    ❮
                  </a>
                  <button
                    className="btn bg-accent text-accent-content w-20 mx-auto"
                    onClick={() => {
                      window.location.href = `/narratives/${usage.narrative_id}/dialogues/${usage.narrative_id}/#${usage.gen_id}`;
                    }}
                  >
                    See In Context
                  </button>{" "}
                  <a
                    href={`#${id}${next}`}
                    disabled={state.value.length === 1}
                    className="btn btn-circle"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIdx(next);
                    }}
                  >
                    ❯
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {button}
      <dialog
        id={id}
        className="modal modal-middle cursor-default"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="modal-box w-full max-w-5xl">
          <button
            className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-base-content"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              document.getElementById(id).close();
            }}
          >
            ✕
          </button>
          {state.loading ? (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="loading loading-spinner loading-md"></span>
              </div>
            </div>
          ) : (
            <>
              <h3 className="font-bold text-lg text-base-content text-center">
                Recently Seen
              </h3>

              {curr}
            </>
          )}
        </div>
      </dialog>
    </>
  );
}

const MatchCard = ({ handleDecision, cardHeight, cardWidth, card, isTop }) => {
  const [correct, setCorrect] = useState([]);
  const [wrong, setWrong] = useState([]);
  const [red, setRed] = useState([]);
  const [selected, setSelected] = useState(null);
  useEffect(() => {
    if (correct.length === card.length) {
      let decisions = [];
      correct
        .filter((id) => !wrong.includes(id))
        .forEach((id) => decisions.push([id, "good"]));
      wrong.forEach((id) => decisions.push([id, "repeat"]));
      handleDecision(decisions);
    }
  }, [correct]);

  let left = card.map((cardData) => {
    return (
      <div className="flex gap-2" key={cardData.id}>
        <div className="tooltip tooltip-right" data-tip="See In Context">
          <UsageModal
            vocab_id={cardData.id}
            btnInner={<i className="bi bi-info-circle-fill text-lg"></i>}
            btnClassName={"btn btn-ghost"}
          />
        </div>
        <button
          key={cardData.canonical_form}
          disabled={correct.includes(cardData.id)}
          className={
            selected == cardData.id
              ? "btn btn-accent"
              : correct.includes(cardData.id)
              ? "btn btn-success"
              : red[0] == cardData.id
              ? "btn btn-outline btn-error"
              : "btn btn-outline btn-info"
          }
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSelected(cardData.id);
          }}
        >
          <ProcessEncoding
            {...cardData}
            content={cardData.canonical_form}
            content_encoded={cardData.encoded_canonical_form}
          />
        </button>
      </div>
    );
  });
  const rightIds = shuffleArray(
    card.map((c) => c.id),
    card.length
  );
  let right = rightIds
    .map((id) => card.filter((c) => c.id == id)[0])
    .map((cardData) => {
      return (
        <button
          key={cardData.canonical_form}
          disabled={correct.includes(cardData.id)}
          className={
            correct.includes(cardData.id)
              ? "btn btn-success"
              : red[1] == cardData.id
              ? "btn btn-outline btn-error"
              : "btn btn-outline btn-secondary"
          }
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (selected) {
              if (selected == cardData.id) {
                setCorrect([...correct, cardData.id]);
              } else {
                setRed([selected, cardData.id]);
                setWrong([...wrong, selected, cardData.id]);
              }
              setSelected(null);
            }
          }}
        >
          {cardData.definition}
        </button>
      );
    });
  return (
    <DecisionCard
      handleGone={(decision) =>
        decision == "skip"
          ? handleDecision(card.map((cardData) => [cardData.id, "split"]))
          : null
      }
      height={`${1.5 * cardHeight}rem`}
      width={`${cardWidth}rem`}
      canHover={!mobileCheck()}
      is_top={isTop}
    >
      <div className="flex flex-col items-center p-4 bg-base-300 text-base-content rounded-2xl h-full w-full">
        <h3 className="text-lg">Create Matching Pairs</h3>
        <div className=" flex justify-around w-full h-full">
          <div className="flex flex-col justify-around items-left pl-4 w-1/3 h-full">
            {left}
          </div>
          <div className="flex flex-col justify-around items-center w-1/2 h-full">
            {right}
          </div>
        </div>
      </div>
    </DecisionCard>
  );
};

const GrammarCard = ({
  handleDecision,
  cardHeight,
  cardWidth,
  cardData,
  isTop,
}) => {
  return (
    <DecisionCard
      handleGone={(decision) => handleDecision([[cardData.id, decision]])}
      height={`${cardHeight}rem`}
      width={`${cardWidth}rem`}
      canHover={!mobileCheck()}
      is_top={isTop}
      ignorable
      left
      right
    >
      <div className="flex flex-col justify-around w-full max-w-5xl h-full items-center bg-neutral text-neutral-content rounded-xl">
        <div className="card-body">
          <h2 className="text-lg md:text-xl lg:text-2xl">
            <ProcessEncoding
              {...cardData}
              content={cardData.canonical_form}
              content_encoded={cardData.encoded_canonical_form}
            />
          </h2>
          <h3 className="text-xs md:text-lg">{cardData.definition}</h3>
          <p>{cardData.explanation}</p>
          <UsageModal vocab_id={cardData.id} />
        </div>
      </div>
    </DecisionCard>
  );
};

const QuestionCard = ({
  handleDecision,
  cardHeight,
  cardWidth,
  cardData,
  isTop,
}) => {
  const [selected, setSelected] = useState([]);
  const [correct, setCorrect] = useState(null);
  const choices = JSON.parse(cardData.definition);
  return (
    <DecisionCard
      handleGone={(decision) => handleDecision([[cardData.id, decision]])}
      height={`${cardHeight}rem`}
      width={`${cardWidth}rem`}
      canHover={!mobileCheck()}
      is_top={isTop}
      ignorable
      check={
        correct == null
          ? () => {
              let all_good = true;
              choices.forEach((c, index) => {
                if (c.is_answer && !selected.includes(index)) {
                  all_good = false;
                }
                if (!c.is_answer && selected.includes(index)) {
                  all_good = false;
                }
              });
              setCorrect(all_good);
            }
          : null
      }
      right={correct === true}
      left={correct != null}
    >
      <div className="flex flex-col items-center p-4 bg-base-300 text-base-content rounded-2xl w-full h-full">
        <h3 className="text-lg">Select All That Apply</h3>

        <div className=" flex flex-col justify-around items-center w-full h-full">
          <div className="flex gap-2 justify-center w-full items-center">
            <h3 className="my-8 text-md">{cardData.canonical_form}</h3>

            <UsageModal
              vocab_id={cardData.id}
              btnInner={
                <div
                  className="tooltip tooltip-bottom"
                  data-tip="See what motivated this question."
                >
                  <i className="bi bi-journal-richtext text-xl"></i>
                </div>
              }
              btnClassName={"btn btn-square btn-outline"}
            />
          </div>

          <div className="flex justify-around items-center w-full h-full flex-wrap gap-2">
            {choices.map((choice, index) => {
              let right =
                (selected.includes(index) && choices[index].is_answer) ||
                (!selected.includes(index) && !choices[index].is_answer);
              return (
                <div
                  className="flex flex-col justify-center items-center"
                  key={choice.learning_language}
                >
                  <button
                    className={
                      selected.includes(index)
                        ? "btn btn-primary"
                        : "btn btn-outline btn-info"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (selected.includes(index)) {
                        setSelected(selected.filter((s) => s != index));
                      } else {
                        setSelected([...selected, index]);
                      }
                    }}
                  >
                    {choice.learning_language}
                  </button>
                  {correct && (
                    <div
                      className="tooltip tooltip-bottom"
                      data-tip={`About this choice: ${choice.explanation}`}
                    >
                      {right ? (
                        <i className="bi bi-check-circle-fill text-success"></i>
                      ) : (
                        <i className="bi bi-x-circle-fill text-error"></i>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {correct && (
          <p className="text-xs">
            {mobileCheck() ? "Click " : "Hover "}
            <i className="bi bi-check-circle-fill text-success"></i>
            {" and "}
            <i className="bi bi-x-circle-fill text-error"></i>
            {" to see explanations."}
          </p>
        )}
      </div>
    </DecisionCard>
  );
};

const BuilderCard = ({
  handleDecision,
  cardHeight,
  cardWidth,
  cardData,
  isTop,
}) => {
  const [selected, setSelected] = useState([]);
  const [checked, setChecked] = useState(null);
  const [showDef, setShowDef] = useState(false);
  const [base, __] = useState(
    ProcessEncoding({
      ...cardData,
      content: cardData.canonical_form,
      content_encoded: cardData.encoded_canonical_form,
      as_function: true,
    })
  );
  const [tokensShuffled, _] = useState(randomShuffle(base.map((_, i) => i)));

  const buttons = tokensShuffled.map((i, idx) => {
    let show = base[i];
    show = show[show.length - 1];
    return (
      <button
        className={
          "btn btn-xs md:btn-sm m-[2px] " +
          (selected.includes(i)
            ? " btn-ghost no-animation"
            : "btn-outline btn-accent")
        }
        key={`${i}${idx}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (checked != null) {
            return;
          }
          if (!selected.includes(i)) {
            setSelected((s) => [...s, i]);
          }
        }}
      >
        {show}
      </button>
    );
  });

  const built = selected.map((id, idx) => {
    let show = base[id];
    show = show[show.length - 1];
    const answer = base[idx][0];
    const thisChoice = base[id][0];
    return (
      <button
        className={
          "btn btn-xs md:btn-sm m-[2px] " +
          (checked != null
            ? answer.trim() == thisChoice.trim()
              ? "btn-success"
              : "btn-error"
            : "btn-active btn-outline")
        }
        key={`${id}${idx}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (checked != null) {
            return;
          }
          setSelected((s) => s.filter((i) => i != id));
        }}
      >
        {show}
      </button>
    );
  });
  const doCheck = async () => {
    if (checked == null) {
      const check =
        selected.length == base.length &&
        selected.filter((i, idx) => {
          const answer = base[idx][0];
          const thisChoice = base[i][0];
          return answer.trim() != thisChoice.trim();
        }).length == 0;
      setChecked(check);
    }
  };
  return (
    <DecisionCard
      handleGone={(decision) => handleDecision([[cardData.id, decision]])}
      height={`${(mobileCheck() ? 1.33 : 1) * cardHeight}rem`}
      width={`${cardWidth}rem`}
      canHover={!mobileCheck()}
      is_top={isTop}
      ignorable
      left={checked != null}
      right={checked == true}
      check={checked == null ? doCheck : null}
    >
      <div className="bg-neutral text-neutral-content rounded-2xl flex flex-col justify-top items-center p-4 w-full h-full">
        <h3 className="text-lg">Build the Message</h3>
        <div className="flex w-full justify-center items-center gap-2">
          <h4 className="text-md">{cardData.definition}</h4>

          <UsageModal
            vocab_id={cardData.id}
            btnInner={
              <div
                className="tooltip tooltip-bottom"
                data-tip="See what motivated this question."
              >
                <i className="bi bi-journal-richtext text-xl text-content-neutral"></i>
              </div>
            }
            btnClassName={"btn btn-square"}
          />
        </div>
        <div className="flex justify-left items-center my-2">
          <button onClick={() => checked == null && setShowDef(!showDef)}>
            {checked != null || showDef ? (
              <i className="bi bi-eye-fill text-2xl"></i>
            ) : (
              <i className="bi bi-eye-slash-fill text-2xl"></i>
            )}
          </button>
          <span className="text-sm mx-2">Answer:</span>
          <div
            className={checked != null || showDef ? "opacity-1" : "opacity-0"}
          >
            <ProcessEncoding
              {...cardData}
              content={cardData.canonical_form}
              content_encoded={cardData.encoded_canonical_form}
            />
          </div>
        </div>
        <div
          className="flex flex-wrap outline-double p-2 rounded-xl"
          style={{
            width: `calc(${cardWidth}rem-2rem)`,
          }}
        >
          {buttons}
        </div>
        <div
          className="flex flex-wrap mt-8 w-full"
          style={{
            width: `calc(${cardWidth}rem-2rem)`,
          }}
        >
          {built}
        </div>
        {checked != null && (
          <h3
            className={
              "p-2 mx-auto text-center text-lg mt-auto rounded-xl " +
              (checked ? "bg-success" : "bg-error")
            }
          >
            {checked
              ? randomShuffle(["Awesome!", "Nice!", "Correct!"])[0]
              : randomShuffle(["Sorry!", "Try Again!", "Not Quite."])[0]}
          </h3>
        )}
      </div>
    </DecisionCard>
  );
};

const StudyCard = ({ handleDecision, cardHeight, cardWidth, isTop, card }) => {
  if (!isTop) {
    return (
      <div
        className="bg-neutral rounded-2xl "
        style={{
          height: `${cardHeight}rem`,
          width: `${cardWidth}rem`,
        }}
      ></div>
    );
  }
  if (Array.isArray(card)) {
    return (
      <MatchCard
        handleDecision={handleDecision}
        cardHeight={cardHeight}
        cardWidth={cardWidth}
        card={card}
        isTop={isTop}
      />
    );
  }
  let cardData = card;
  if (cardData.type == "builder") {
    return (
      <BuilderCard
        handleDecision={handleDecision}
        cardHeight={cardHeight}
        cardWidth={cardWidth}
        cardData={cardData}
        isTop={isTop}
      />
    );
  }
  if (cardData.type == "grammar") {
    return (
      <GrammarCard
        handleDecision={handleDecision}
        cardHeight={cardHeight}
        cardWidth={cardWidth}
        cardData={cardData}
        isTop={isTop}
      />
    );
  }
  if (cardData.type == "question") {
    return (
      <QuestionCard
        handleDecision={handleDecision}
        cardHeight={cardHeight}
        cardWidth={cardWidth}
        cardData={cardData}
        isTop={isTop}
      />
    );
  }

  return (
    <RotatableCard
      handleGone={(decision) => handleDecision([[cardData.id, decision]])}
      height={`${cardHeight}rem`}
      width={`${cardWidth}rem`}
      canHover={!mobileCheck()}
      is_top={isTop}
      front={
        <div className="flex flex-col justify-around w-full max-w-5xl h-full items-center bg-neutral text-neutral-content p-4">
          <div
            className={TYPE_BADGE_COLOR[cardData.type] || "badge badge-ghost"}
          >
            {cardData.type}
          </div>
          <h1 className="text-lg md:text-xl lg:text-2xl">
            <ProcessEncoding
              {...cardData}
              content={cardData.canonical_form}
              content_encoded={cardData.encoded_canonical_form}
            />
          </h1>
          <div className="card-actions justify-end">
            <UsageModal vocab_id={cardData.id} />
          </div>
        </div>
      }
      back={
        <div className="flex flex-col justify-around w-full max-w-5xl h-full items-center bg-neutral text-neutral-content">
          <div className="card-body">
            <h2 className="text-lg md:text-xl lg:text-2xl">
              <ProcessEncoding
                {...cardData}
                content={cardData.canonical_form}
                content_encoded={cardData.encoded_canonical_form}
              />
            </h2>
            <h3 className="text-xs md:text-lg">{cardData.definition}</h3>
            <p>{cardData.explanation}</p>
            <UsageModal vocab_id={cardData.id} />
          </div>
        </div>
      }
    />
  );
};

const CORRECT_TRIES = 2;

const soonest = (a) => a.toSorted((a, b) => new Date(a) - new Date(b))[0];

export default function FlashcardsPage() {
  const [selectedReview, setSelectedReview] = useState([]);
  const { width, height } = useWindowSize();
  const [numFlashcardsIndex, setNumFlashcardsIndex] = useState(null);
  const [round, setRound] = useState(0);
  const [flashcards, setFlashcards] = useState(null);
  const ready = useAsync(async () => {
    if (flashcards == null || flashcards.size === 0) {
      const response = await fetch(`${backendURL}/vocab/stats/`, {
        credentials: "include",
      }).catch((err) => console.log(err));

      let results = await response.json();
      const ready_count = results.reduce(
        (partialSum, a) => partialSum + a.ready,
        0
      );
      const tot = results.reduce((partialSum, a) => partialSum + a.tot, 0);
      setSelectedReview(
        results
          .filter((a) => (ready_count > 0 ? a.ready : a.tot))
          .map((a) => a.type)
      );
      return [results, tot, soonest(results)];
    }
  }, [flashcards]);

  const [ready_summary, tot_count, soonest_dt] = Array.isArray(ready.value)
    ? ready.value
    : [null, null, null];

  const ready_count = ready_summary
    ? ready_summary.reduce(
        (partialSum, a) =>
          partialSum + (selectedReview.includes(a.type) ? a.ready : 0),
        0
      )
    : 0;
  const tot_selected = ready_summary
    ? ready_summary.reduce(
        (partialSum, a) =>
          partialSum + (selectedReview.includes(a.type) ? a.tot : 0),
        0
      )
    : 0;

  const noVocab = tot_count == 0;
  const nextReview = soonest_dt && soonest_dt.soonest;

  let numCards = [2, 5, 8, 10, 12, 15, 18, 20, 22, 25];
  let addtlNum = ready_count ? ready_count : tot_selected ? tot_selected : null;
  if (addtlNum) {
    addtlNum = Math.max(0, Math.min(25, addtlNum));
    numCards = [...new Set([...numCards, addtlNum])].toSorted((a, b) => a - b);
    numCards = numCards.filter((i) => i <= addtlNum);
  }

  let useCardsIndex =
    numFlashcardsIndex != null
      ? numFlashcardsIndex
      : numCards.indexOf(addtlNum);
  useCardsIndex = Math.min(useCardsIndex, numCards.length - 1);
  const [flashcardsLength, setFlashcardsLength] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [points, setPoints] = useState(0);
  const [deckHead, setDeckHead] = useState([]);

  useEffect(() => {
    if (flashcards) {
      const grouped = groupVocab([...flashcards]).slice(-15);
      const curTop = [
        ...deckHead.slice(0, 3).map((c) => c.id),
        ...deckHead.slice(-3).map((c) => c.id),
      ];
      const back = grouped.filter((c) => curTop.includes(c.id));
      const front = grouped.filter((c) => !curTop.includes(c.id));
      setDeckHead([...front, ...back]);
    }
  }, [flashcards, round]);

  const handleCount = (v) => {
    setNumFlashcardsIndex(
      Math.min(Math.max(0, useCardsIndex + v), numCards.length - 1)
    );
  };

  const startReviewSession = async () => {
    let result = [];
    setLoading(true);
    const numFlashcards = numCards[useCardsIndex];
    const reviewQuery = selectedReview.map((t) => `${t}=true`).join("&");
    const response = await fetch(
      `${backendURL}/vocab/${numFlashcards}/?${reviewQuery}`,
      {
        credentials: "include",
      }
    ).catch((err) => console.log(err));

    result = await response.json();

    setFlashcards(new Map(result.map((c) => [c.id, [c, 0, 0]])));
    setFlashcardsLength(result.length);
    setPoints(0);
    setLoading(false);
  };

  async function handleCompletedCard([
    {
      id,
      attempts,
      correct,
      ignore,
      alpha,
      beta,
      half_life,
      created_at,
      next_review,
      last_review,
    },
    count,
  ]) {
    const body = JSON.stringify([
      {
        id,
        attempts,
        correct,
        ignore,
        alpha,
        beta,
        half_life,
        created_at,
        next_review,
        last_review,
        attempts: count,
        correct: 1,
      },
    ]);
    try {
      await fetch(`${backendURL}/vocab/update`, {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      });
    } catch (error) {}
  }

  const reinsertCard = (card, flashcards) => {
    const temp = removeCard(card, flashcards);
    const randomIndex = Math.floor(Math.random() * temp.size);

    return new Map([
      ...[...temp].slice(0, randomIndex),
      [card[0].id, card],
      ...[...temp].slice(randomIndex, temp.size),
    ]);
  };

  const removeCard = (currentCard, flashcards) => {
    return new Map(
      [...flashcards.entries()].filter((entry) => {
        let [id, [card, good, bad]] = entry;
        return id !== currentCard[0].id;
      })
    );
  };

  async function handleDecision(decisions) {
    let points = 0;
    let cardId = null;
    let decision = null;
    let currentCard = null;
    let refreshedCards = new Map([...flashcards]);
    await sleep(300);
    for (let i = 0; i < decisions.length; i++) {
      [cardId, decision] = decisions[i];
      currentCard = flashcards.get(cardId);
      currentCard[1] += 1;
      if (decision === "repeat") {
        //retry
        points -= currentCard[2];
        currentCard[2] = 0;
        refreshedCards = reinsertCard(currentCard, refreshedCards);
      }
      if (decision === "split") {
        //split up a match into the individual cards
        currentCard[0]["split"] = true;
        refreshedCards = reinsertCard(currentCard, refreshedCards);
      }
      if (decision === "good") {
        //passed
        currentCard[2] += 1; //increment successes
        points += 1;
        if (currentCard[2] >= CORRECT_TRIES) {
          //must pass at least twice
          refreshedCards = removeCard(currentCard, refreshedCards);
          await handleCompletedCard(currentCard);
        } else {
          refreshedCards = reinsertCard(currentCard, refreshedCards);
        }
      }
      if (decision === "skip") {
        //ignore for rest of session
        points += CORRECT_TRIES - currentCard[2];
        refreshedCards = removeCard(currentCard, refreshedCards);
        await handleCompletedCard(currentCard);
      }
      if (decision === "ignore") {
        //never show again
        points += CORRECT_TRIES - currentCard[2];
        refreshedCards = removeCard(currentCard, refreshedCards);
        currentCard[0].ignore = true;
        await handleCompletedCard(currentCard);
      }
    }
    setFlashcards(refreshedCards);
    setPoints((p) => p + points);
    setRound((r) => r + 1);
  }
  const cardWidth = Math.min(40, 0.9 * convertPixelsToRem(width));
  const cardHeight = Math.min(30, 0.3 * convertPixelsToRem(height));
  const progess = Math.min(
    100,
    Math.round((100 * points) / (CORRECT_TRIES * flashcardsLength))
  );
  const ReviewTypeSelect = ready_summary != null && (
    <div className="flex flex-col gap-2 justify-center items-center border-solid my-8 w-full">
      <h3 className="w-full text-center mx-auto">Choose What To Review</h3>
      <div className="flex gap-2 justify-center items-center flex-wrap">
        {ready_summary.map((t) =>
          (ready_count ? t.ready : t.tot) > 0 ? (
            <label className="label cursor-pointer" key={t.type}>
              <span className="label-text mx-2">{t.type}</span>
              <input
                type="checkbox"
                checked={selectedReview.includes(t.type)}
                className="checkbox"
                onChange={() => {
                  if (selectedReview.includes(t.type)) {
                    setSelectedReview((s) =>
                      s.length > 1 ? s.filter((k) => k !== t.type) : s
                    );
                  } else {
                    setSelectedReview((s) => [...s, t.type]);
                  }
                }}
              />
            </label>
          ) : null
        )}
      </div>
    </div>
  );

  return (
    <div className="flex flex-col h-screen w-screen  ">
      <LoggedInHeader />
      <div className="w-full h-full flex flex-col pt-20 overflow-auto items-center">
        {/* {flashcards && flashcards.size === 0 ? "All Done! Go Again?" : null} */}
        {ready.loading ? (
          <progress className="progress w-56 progress-secondary"></progress>
        ) : null}
        {noVocab && (
          <div className="flex flex-col justify-around items-center bg-secondary-focus rounded-xl max-w-[90vw] h-[30rem] max-h-[75vh] p-4">
            <h1 className="text-3xl m-auto text-center">
              Nothing to see here!
            </h1>
            <Player
              src={emptyCardsAnimation}
              speed={0.5}
              style={{ height: "400px" }}
              autoplay
              loop
            />
            <a
              className="btn btn-lg rounded-md mt-12 w-full"
              href="/narratives"
            >
              Explore to Create Flashcards
            </a>
          </div>
        )}
        {!ready.loading &&
        !noVocab &&
        (flashcards == null || flashcards.size < 1) ? (
          <div className="flex flex-col justify-around items-center bg-secondary-focus rounded-xl max-w-[90vw] h-[30rem] max-h-[75vh] ">
            <div className="stat-title text-center text-3xl font-bold">
              Review
            </div>
            <div className="flex justify-center items-center my-2 ">
              <p className="text-md mr-2">
                {ready_count == 1
                  ? `1 Selected Item Ready`
                  : ready_count > 25
                  ? `More Than 25 Selected Items Ready`
                  : `${ready_count} Selected Items Ready`}
              </p>
              <div
                className="tooltip tooltip-left"
                data-tip="Stoozi Uses Advanced Statistics To Determine An Optimal Time Of Review For Every Item."
              >
                <i className="bi bi-info-circle-fill"></i>
              </div>
            </div>
            <div className="divider"></div>

            {ready_count == 0 && nextReview != null ? (
              <>
                <p className="text-md mx-auto my-2">Next Items Ready In:</p>
                <CountdownTimer targetTime={nextReview} />

                <div className="divider stat-title text-center m-4 mt-8 font-bold text-xl">
                  Review Anyways
                </div>
              </>
            ) : null}
            <p className="text-sm md:text-md mx-auto mb-4">
              How Many Items Would You Like to Review?
            </p>

            <div className="flex flex-row justify-around items-center">
              <button
                className="btn w-10 md:w-20 rounded-md bg-content"
                onClick={() => handleCount(-1)}
                disabled={useCardsIndex == 0}
              >
                <i className="bi bi-dash-lg text-3xl"></i>
              </button>
              <div className="mx-4 text-lg md:text-4xl font-bold">
                {numCards[useCardsIndex]}
              </div>
              <button
                className="btn w-10 md:w-20 rounded-md bg-content"
                onClick={() => handleCount(1)}
                disabled={useCardsIndex == numCards.length - 1}
              >
                <i className="bi bi-plus-lg text-3xl"></i>
              </button>
            </div>

            {ReviewTypeSelect}
            <button
              className="btn btn-lg rounded-md w-full btn-primary"
              onClick={startReviewSession}
            >
              Start Review
            </button>
            <div className="flex flex-row justify-around items-center"></div>
          </div>
        ) : null}
        {loading ? (
          <progress className="progress w-56 progress-secondary"></progress>
        ) : null}
        {flashcards && flashcards.size > 0 ? (
          <div className="flex flex-col justify-center w-full h-full items-center">
            <h1 className="text-xl">Progress: {progess}%</h1>
            <progress
              className="progress progress-accent w-56 mb-4"
              value={progess}
              max="100"
            ></progress>
            <p className="text-xs">Round: {round}</p>
            <Deck
              height={cardHeight}
              width={cardWidth}
              ncards={deckHead.size}
              cards={deckHead.map((card, index) => {
                let id = Array.isArray(card) ? card[0].id : card.id;
                return (
                  <StudyCard
                    handleDecision={handleDecision}
                    cardHeight={cardHeight}
                    cardWidth={cardWidth}
                    key={`${id} ${index} ${round}`}
                    isTop={index === deckHead.length - 1}
                    card={card}
                  />
                );
              })}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}