import React, { useEffect, useRef, useState } from "react";

let audios = new Map();
const setAudios = (v) => (audios = v);

const useAudioPlayer = () => {
  const [currentAudio, setCurrentAudio] = useState(new Audio());
  const [playArgs, setPlayArgs] = useState(false);

  useEffect(() => {
    const temp = async () => {
      let audio = playArgs.url ? audios.get(playArgs.url) : currentAudio;
      if (audio == null) {
        audio = new Audio(playArgs.url);
        setAudios(new Map([...audios, [playArgs.url, audio]]));
      }
      if (playArgs.url != currentAudio.src) {
        currentAudio.currentTime = 0;
        await currentAudio.pause();
        setCurrentAudio(audio);
      }
      if (playArgs.play) {
        audio.addEventListener("ended", () => {
          setCurrentAudio(new Audio());
          playArgs.onPlaybackDone(true);
        });
        audio.addEventListener("error", () => {
          // setAudios(new Map([...audios, [url, true]]));
          setCurrentAudio(new Audio());
          console.log("audio error");
          playArgs.onPlaybackDone(false);
        });
        audio.playbackRate = playArgs.pace;
        audio.volume = playArgs.volume;
        try {
          await audio.play();
        } catch (exceptionVar) {
          console.log(exceptionVar);
          // setAudios(new Map([...audios, [currentUrl, false]]));
        }
      } else {
        try {
          await audio.pause();
        } catch (exceptionVar) {
          console.log(exceptionVar);
        }
      }
    };

    temp();
  }, [playArgs]);

  const controlAudio = ({ url, ...playArgs }) => {
    setPlayArgs({
      onPlaybackDone: (v) => {},
      volume: 1,
      pace: 1,
      ...playArgs,
      url,
    });
  };

  const volumePace = (v, p) => {
    setPlayArgs((a) => ({ ...a, volume: v, pace: p }));
  };

  return { controlAudio, volumePace, playArgs, currentURL: currentAudio.src, waiting: playArgs.play && currentAudio.paused};
};

export default useAudioPlayer;
